import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-cursos-index-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class CapacitacionesTiposIndexEditComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  private lstCapacitacionesGrupos : Array<any> = [];
  private lstTemarios             : Array<any> = [];
  private lstTemariosRequired     : Array<boolean> = [];

  public objData : any = null;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesTiposIndexEditComponent>, 
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'name'              : new FormControl(null, [Validators.required]),
      'capacitacionGrupo' : new FormControl(null, [Validators.required]),
      'duracion'          : new FormControl(null, [Validators.required]),
      'hasTemario'        : new FormControl(false)
    });

    this.formGroup.controls["name"].setValue(this.objData.name);
    this.formGroup.controls["capacitacionGrupo"].setValue(this.objData.idCapacitacionGrupo);
    this.formGroup.controls["duracion"].setValue(this.objData.duracion);
    this.formGroup.controls["hasTemario"].setValue(this.objData.hasTemario);
  }

  ngAfterViewInit() {
    this.edit();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnAgregarTemario_clickEvent() {
    this.lstTemarios.push({idCapacitacionTipoTemario: 0, name: ""});
    this.lstTemariosRequired.push(false);
  }

  btnEliminarTemario_clickEvent(index : number) {
    this.lstTemarios.splice(index, 1);
    this.lstTemariosRequired.splice(index, 1);
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    if(this.formGroup.controls["hasTemario"].value) {
      if(this.lstTemarios.length > 0) {
        if(!this.validTemario()) {
          this.toastr.warning('Datos incompletos en los temarios', 'Formulario', { timeOut: 2000 });
          return;
        }
      } else {
        this.toastr.warning('No ha agregado ningún temario', 'Formulario', { timeOut: 2000 });
        return;
      }
    }
    
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private validTemario() : boolean {
    let valid : boolean = true;

    this.lstTemarios.forEach((item, index) => {
      if(!Globals.validStringValue(item.name)) {
        valid = false;
        this.lstTemariosRequired[index] = true;
      } else {
        this.lstTemariosRequired[index] = false;
      }
    });

    return valid;
  }

  private edit() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    let objCapacitacionTipo : any =
    {
      idCapacitacionTipo : this.objData.idCapacitacionTipo
    }

    this.objCapacitacionesTiposService.edit(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;
          this.lstTemarios              = objResponse.result.capacitacionesTiposTemarios;

          this.lstTemarios.forEach(() => {
            this.lstTemariosRequired.push(false);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objCapacitacionesTiposUpdate : any =
    {
      idCapacitacionTipo  : this.objData.idCapacitacionTipo,
      name                : this.formGroup.controls["name"].value,
      idCapacitacionGrupo : this.formGroup.controls["capacitacionGrupo"].value,
      duracion            : this.formGroup.controls["duracion"].value,
      hasTemario          : this.formGroup.controls["hasTemario"].value,
      temarios            : this.lstTemarios
    };

    this.objCapacitacionesTiposService.update(objCapacitacionesTiposUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          this.edit();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
