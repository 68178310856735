import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-clientes-index-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class ClientesIndexEditComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  public objData : any = null;

  fileBase64 : string = "";
  hasLogo : boolean = false;

  get hasBase64() : boolean {
    return Globals.validStringValue(this.fileBase64);
  }

  constructor(private objDialogRef : MatDialogRef<ClientesIndexEditComponent>, 
              private objClientesService : ClientesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'name'                : new FormControl(null, [Validators.required]),
      'razonSocial'         : new FormControl(null, [Validators.required]),
      'rfc'                 : new FormControl(null, [Validators.required]),
      'telefono'            : new FormControl(null),
      'celular'             : new FormControl(null),
      'email'               : new FormControl(null),
      'direccion'           : new FormControl(null),
      'isParticular'        : new FormControl(false),
      'showFirmaLegal'      : new FormControl(false),
      'showFirmaTrabajador' : new FormControl(false)
    });

    this.formGroup.controls["name"].setValue(this.objData.name);
    this.formGroup.controls["razonSocial"].setValue(this.objData.razonSocial);
    this.formGroup.controls["rfc"].setValue(this.objData.rfc);
    this.formGroup.controls["telefono"].setValue(this.objData.telefono);
    this.formGroup.controls["celular"].setValue(this.objData.celular);
    this.formGroup.controls["email"].setValue(this.objData.email);
    this.formGroup.controls["direccion"].setValue(this.objData.direccion);
    this.formGroup.controls["isParticular"].setValue(this.objData.isParticular);
    this.formGroup.controls["showFirmaLegal"].setValue(this.objData.showFirmaLegal);
    this.formGroup.controls["showFirmaTrabajador"].setValue(this.objData.showFirmaTrabajador);

    if(this.objData.isParticular) {
      this.formGroup.controls["razonSocial"].disable();
      this.formGroup.controls["rfc"].disable();
    } else {
      this.formGroup.controls["razonSocial"].enable();
      this.formGroup.controls["rfc"].enable();
    }

    this.hasLogo = this.objData.hasLogo;
    this.fileBase64 = this.hasLogo ? "ciccis" : "";
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  chkParticular_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls["razonSocial"].disable();
      this.formGroup.controls["rfc"].disable();
    } else {
      this.formGroup.controls["razonSocial"].enable();
      this.formGroup.controls["rfc"].enable();
    }
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var mimeType = file.type;

      event.target.value = "";
      
      if(mimeType.match(/image\/*/) == null){ 
        this.toastr.info("Solo se admiten imagenes", "Mensaje", { timeOut: 2000 }); 
        return; 
      };

      Globals.fileToBase64(file).subscribe((result => {
        this.fileBase64 = result;
      }));

      this.hasLogo = false;
    }
  }

  btnDeleteFile() {
    this.hasLogo = false;
    this.fileBase64 = "";
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private update() : void {

    this.loading(true);

    let objClienteUpdate : any =
    {
      idCliente           : this.objData.idCliente,
      name                : this.formGroup.controls["name"].value,      
      razonSocial         : this.formGroup.controls["razonSocial"].value,
      rfc                 : this.formGroup.controls["rfc"].value,
      telefono            : this.formGroup.controls["telefono"].value,
      celular             : this.formGroup.controls["celular"].value,
      email               : this.formGroup.controls["email"].value,
      direccion           : this.formGroup.controls["direccion"].value,
      isParticular        : this.formGroup.controls["isParticular"].value,
      showFirmaLegal      : this.formGroup.controls["showFirmaLegal"].value,
      showFirmaTrabajador : this.formGroup.controls["showFirmaTrabajador"].value,
      fileBase64          : this.fileBase64,
      hasLogo             : this.hasLogo
    };

    this.objClientesService.update(objClienteUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
