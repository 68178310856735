import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-cursos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CapacitacionesTiposCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  private lstCapacitacionesGrupos : Array<any> = [];
  private lstTemarios             : Array<any> = [];
  private lstTemariosRequired     : Array<boolean> = [];

  constructor(private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'name'              : new FormControl(null, [Validators.required]),
      'capacitacionGrupo' : new FormControl(null, [Validators.required]),
      'duracion'          : new FormControl(null, [Validators.required]),
      'hasTemario'        : new FormControl(false)
    });

  }

  ngAfterViewInit() {
    this.create();
  }

  //EVENTOS
  btnAgregarTemario_clickEvent() {
    this.lstTemarios.push({name: ""});
    this.lstTemariosRequired.push(false);
  }

  btnEliminarTemario_clickEvent(index : number) {
    this.lstTemarios.splice(index, 1);
    this.lstTemariosRequired.splice(index, 1);
  }

  btnGuardar_clickEvent() {
    
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    if(this.form.controls["hasTemario"].value) {
      if(this.lstTemarios.length > 0) {
        if(!this.validTemario()) {
          this.toastr.warning('Datos incompletos en los temarios', 'Formulario', { timeOut: 2000 });
          return;
        }
      } else {
        this.toastr.warning('No ha agregado ningún temario', 'Formulario', { timeOut: 2000 });
        return;
      }
    }

    this.store();

  }

  // METODOS
  private validTemario() : boolean {
    let valid : boolean = true;

    this.lstTemarios.forEach((item, index) => {
      if(!Globals.validStringValue(item.name)) {
        valid = false;
        this.lstTemariosRequired[index] = true;
      } else {
        this.lstTemariosRequired[index] = false;
      }
    });

    return valid;
  }

  private create() : void {
    
    Wait.show(this.mainContainer, "Espere por favor...");

    this.objCapacitacionesTiposService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesGrupos = objResponse.result.capacitacionesGrupos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let objCapacitacionTipoStore : any =
    {
      name                : this.form.controls["name"].value,
      idCapacitacionGrupo : this.form.controls["capacitacionGrupo"].value,
      duracion            : this.form.controls["duracion"].value,
      hasTemario          : this.form.controls["hasTemario"].value,
      temarios            : this.lstTemarios
    };

    this.objCapacitacionesTiposService.store(objCapacitacionTipoStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "cursos/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
