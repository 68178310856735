import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  showConfiguracionGlobal() : Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Configuraciones/ShowConfiguracionGlobal", null, Sessions.header());
  }

  updateConfiguracionGlobal(objConfiguracion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Configuraciones/UpdateConfiguracionGlobal", objConfiguracion, Sessions.headerFormData());
  }

}
