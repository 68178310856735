import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

declare var $: any;
declare var jQuery: any;

@Injectable()
export class Globals {

    public static ApiPath = "api/";

    public pageTitle : string = "";
    
    public content : any;
    
    public toolbar : any;

    public titleBar : any;

    public defaultLayout() {
      
      // CONTENT
      this.content = {
        marginTop         : 'auto',
        position          : 'static',
      };


      // TOOLBAR
      this.toolbar = {
        left              : 'auto',
        position          : "static",
        right             : 'auto',
        top               : 'auto',
        width             : 'auto',
      };


      // TITLEBAR
      this.titleBar = {
        display           : 'block',
        html              : '',
      };

    }

    constructor() {
      this.defaultLayout();
    }

    // public static inicialization() {


    //     jQuery.extend(jQuery.validator.messages, {
    //         required: "El campo es requerido.",
    //         remote: "Please fix this field.",
    //         email: "Por favor ingrese un email válido.",
    //         url: "Please enter a valid URL.",
    //         date: "Please enter a valid date.",
    //         dateISO: "Please enter a valid date (ISO).",
    //         number: "Please enter a valid number.",
    //         digits: "Please enter only digits.",
    //         creditcard: "Please enter a valid credit card number.",
    //         equalTo: "Please enter the same value again.",
    //         accept: "Please enter a value with a valid extension.",
    //         maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    //         minlength: jQuery.validator.format("Please enter at least {0} characters."),
    //         rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    //         range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    //         max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    //         min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
    //     });

        
    //     //On focus event
    //     $('.form-control').focus(function () {
    //         $(this).parent().addClass('focused');
    //     });
    
    //     //On focusout event
    //     $('.form-control').focusout(function () {
    //         var $this = $(this);
    //         if ($this.parents('.form-group').hasClass('form-float')) {
    //             if ($this.val() == '') { $this.parents('.form-line').removeClass('focused'); }
    //         }
    //         else {
    //             $this.parents('.form-line').removeClass('focused');
    //         }
    //     });
    
    //     //On label click
    //     $('body').on('click', '.form-float .form-line .form-label', function () {
    //         $(this).parent().find('input').focus();
    //     });
    
    //     //Not blank form
    //     $('.form-control').each(function () {
    //         if ($(this).val() !== '') {
    //             $(this).parents('.form-line').addClass('focused');
    //         }
    //     });

    // }


    // public static initForms(objParent : any) {

    //     var $parentSelector = $(objParent);

    //     // Inputs
    //     //On focus event
    //     $parentSelector.find('.form-control').focus(function () {
    //         $(this).closest('.form-line').addClass('focused');
    //     });

    //     //On focusout event
    //     $parentSelector.find('.form-control').focusout(function () {
    //         var $this = $(this);
    //         if ($this.parents('.form-group').hasClass('form-float')) {
    //             if ($this.val() == '') { $this.parents('.form-line').removeClass('focused'); }
    //         }
    //         else {
    //             $this.parents('.form-line').removeClass('focused');
    //         }
    //     });

    //     //On label click
    //     $parentSelector.on('click', '.form-float .form-line .form-label', function () {
    //         $(this).parent().find('input').focus();
    //     });

    //     //Not blank form
    //     $parentSelector.find('.form-control').each(function () {
    //         if ($(this).val() !== '') {
    //             $(this).parents('.form-line').addClass('focused');
    //         }
    //     });


    //     // SELECT
    //     if ($.fn.selectpicker) { 
    //         $parentSelector.find('select:not(.ms)').selectpicker(); 
    //     }

    //     // DATEPICKER
    //     $parentSelector.find('.datetimepicker').bootstrapMaterialDatePicker({
    //         format: 'dddd DD MMMM YYYY - HH:mm',
    //         clearButton: true,
    //         weekStart: 1
    //     });

    //     $parentSelector.find('.datepicker').bootstrapMaterialDatePicker({
    //         format: 'dddd DD MMMM YYYY',
    //         clearButton: true,
    //         weekStart: 1,
    //         time: false
    //     });

    //     $parentSelector.find('.timepicker').bootstrapMaterialDatePicker({
    //         format: 'HH:mm',
    //         clearButton: true,
    //         date: false
    //     });
    // }

    // public static exceptionResult( error : any ) {
    //     let objNotify = new Notify();
    //     switch( error.status ) {
    //         case 500:
    //           objNotify.error("Error de servidor", "No se puede establecer la conexión con el servidor.");
    //         break;
    
    //         case 401:
    //           objNotify.error("Error sessión", "La sesión ha finalizado por inactividad.");
    //         break;
    
    //         default:
    //           objNotify.error("Error de inesperado", "Ocurrio un error inesperado. Póngase en contacto con el administrador.");
    //         break;
    //       }
    // }

    public static uniqueId(): string {
        return Math.random().toString(36).substr(2, 14);
    }

    public static scroll : any = {
        scrollColor                         : 'rgba(0,0,0,0.5)',
        scrollWidth                         : '4px',
        scrollAlwaysVisible                 : false,
        scrollBorderRadius                  : '0',
        scrollRailBorderRadius              : '0',
        scrollActiveItemWhenPageLoad        : true
    }

    public static breakpointWidth : number = 3000;

    public static objectExist(argFunction : any) : Promise<void> {
        return new Promise((resolve, reject) => {
            let timer = setInterval(() => {
                if( argFunction() != undefined && argFunction() != null) {
                    resolve();
                    clearInterval(timer);
                }
            }, 10);
        });
    }

    public static objectIsTrue(argFunction : any) : Promise<void> {
        return new Promise((resolve, reject) => {
            let timer = setInterval(() => {
                if( argFunction != undefined && argFunction() != null && argFunction() ) {
                    resolve();
                    clearInterval(timer);
                }
            }, 10);
        });
    }


    public static currencyConvert(lstMonedas: Array<any>, value : number, idMonedaOrigen : number, idMonedaDestino : number) : number {
        let objReturn : number = 0;
        let dollar : number = lstMonedas[1].equivalencia;
        let euro : number = lstMonedas[2].equivalencia;
    
        switch(idMonedaOrigen) {
          
          // MXN -->
          case 1:
            switch(idMonedaDestino) {
              
              // MXN <--
              case 1:
                objReturn = value;
              break;
              // USD <--
              case 2:
                objReturn = value / dollar;
              break;
    
              // EUR <--
              case 3:
                objReturn = value / euro;
              break;
            }
          break;
            
          // USD -->
          case 2:
            switch(idMonedaDestino) {
              // MXN <--
              case 1:
                objReturn = value * dollar;
              break;
    
              // USD <--
              case 2:
                objReturn = value;
              break;
    
              // EURO <--
              case 3:
                objReturn = value * dollar / euro;
              break;
            }
          break;
    
          // EURO -->
          case 3:
            switch(idMonedaDestino) {
              // MXN <--
              case 1:
                objReturn = value * euro;
              break;
    
              // USD <--
              case 2:
                objReturn = value * euro / dollar;
              break;
    
              // EURO <--
              case 3:
                objReturn = value;
              break;
            }
          break;
        }
    
        return objReturn;
    }


    // public static numericFormat(amount, decimalCount : number = 2, decimal : string = ".", thousands : string = ",") {
    //   try {
    //     decimalCount = Math.abs(decimalCount);
    //     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
    //     const negativeSign = amount < 0 ? "-" : "";
    
    //     let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    //     let j = (i.length > 3) ? i.length % 3 : 0;
    
    //     return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }

    public static validValue(argObject : any) : boolean {
      let objReturn : boolean = false;
      if(argObject != undefined && argObject != null && argObject != "") {
        objReturn = true;
      }
      return objReturn;
    }

    public static validStringValue(argObject : string) : boolean {
      let objReturn : boolean = false;
      if( Globals.validValue(argObject) && argObject.trim() != "") {
        objReturn = true;
      }
      return objReturn;
    }

    public static validArrayValue(argObject : Array<any>) : boolean {
      let objReturn : boolean = false;
      if( Globals.validValue(argObject) && argObject.length > 0) {
        objReturn = true;
      }
      return objReturn;
    }

    public static validNumberValue(argObject : any) : boolean {
      let objReturn : boolean = false;

      if( Globals.validValue(argObject) && !isNaN(Number(argObject)) && Number(argObject).toString() == argObject.toString() ) {
        objReturn = true;
      }
      return objReturn;
    }

    public static fileToBase64(file : File) : Observable<string> {
      const result = new ReplaySubject<string>(1);
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event : any) => result.next(btoa(event.target.result as string));
      return result;
    }

    public static calculateTimeFromSeconds(totalSeconds: number): { hours: number, minutes: number, seconds: number } {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
  
      return { hours, minutes, seconds };
    }
}
