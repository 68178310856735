import { SelectService } from '../../services/components/select.service';
// import { OptionComponent } from './option/option.component';
import { Component, Input, ViewChild, ElementRef, AfterContentInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../libraries/globals';
import { ElementSchemaRegistry } from '@angular/compiler';
import { IFormComponent } from '../../libraries/FormValidation/IFormComponent';
import { FormValidator } from '../../libraries/FormValidation/FormValidator';

declare var $: any;

@Component({
  selector: 'comp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements AfterContentInit, IFormComponent {

  static readonly ENABLED : string = "enabled";
  static readonly DISABLED : string = "disabled";
  static readonly READONLY : string = "readonly"
  
  protected _unselectedLabel : string = "";
  protected _unselectedValue : any = "";

  protected _values : Array<string> = [];

  protected arrData : Array<any> = [];
  protected _dataPath : string = null;
  protected arrOptions : Array<ElementRef> = [];
  protected _index : number = -1;
  protected _startIndex : number = -1;
  protected _startData : boolean = false;

  protected _label : string = "";
  protected _value : string = "";

  protected _estate : string;

  protected _dataLiveSearch : string ="true";
  public inicialization : boolean = false;
  protected _firstFocus : boolean = false;
  protected _validator : FormValidator;

  @ViewChild('compSelect', {static: true}) compSelect : ElementRef;
  @ViewChild('optUnselected', {static: true}) optUnselected;
  @ViewChild('compOptionContainer', {static: true}) compOptionContainer;
  @ViewChild('compContainer', {static: true}) compContainer : ElementRef;

  @Input() title : string = "";
  @Input() required : boolean = false;
  @Input() field : string;
  // @Input() fieldValue : string;
  @Input()
    set validator(argValidator :FormValidator) {
      this._validator = argValidator;
      this.required = true;
      this._validator.addFormComponent(this);
    }
    get  validator() : FormValidator {
      return this._validator;
    }

  @Input() 
    set unselectedLabel(argUnselectedLabel : string ) {
      this._unselectedLabel = argUnselectedLabel;
      this.refresh();
    }
    get unselectedLabel(): string {
      return this._unselectedLabel;
    }

  @Input() 
    set unselectedValue(argUnselectedValue : any ) {
      this._unselectedValue = argUnselectedValue;
      this.refresh();
    }
    get unselectedValue(): any {
      return this._unselectedValue;
    }

  @Input() 
    set selectedObject(argSelectedObject : any) {
      if( Globals.validValue(argSelectedObject) ) {
        Globals.objectIsTrue(() => this.inicialization ).then( () => {
          $(this.compSelect.nativeElement).selectpicker('val', argSelectedObject[this.value]);
          this._firstFocus = true;
          this.showLineStatus();
        });
      }

      this._firstFocus = true;
      this.showLineStatus();
    }
    get selectedObject(): any {
    
      let objReturn : any;
      if(this._unselectedLabel.trim() != "") {
        objReturn = this.arrData[this.selectedIndex];
      } else {
        objReturn = this.arrData[this.selectedIndex];
      }
      // objReturn = this.arrData[this.selectedIndex];
      this._firstFocus = true;
      

      return objReturn;
    }

    setSelectedObject(argSelectedObject : any) {
      if( Globals.validValue(argSelectedObject) ) {
        $(this.compSelect.nativeElement).selectpicker('val', argSelectedObject[this.value]);
        this._firstFocus = true;
        this.showLineStatus();
      }
    }

    

  @Input()
    set selectedIndex(argSelectedIndex : number ) {
      if(this.arrData !== undefined && this.arrData != null && this.arrData.length > 0) {
        if(argSelectedIndex > -1 && argSelectedIndex < this.arrData.length ) {
            this._index = argSelectedIndex;
            this.compSelect.nativeElement.selectedIndex = this._index;
            this.refresh();
            setTimeout(() => { 
              this.onChange.emit(this); 
              this.showLineStatus();
            }, 500);
        }
        
      } else {
        this._index = -1;
        this.refresh();
      }
    }
    get selectedIndex(): number {
      let objReturn : number = -1;

      if(this.arrData !== undefined && this.arrData != null && this.arrData.length > 0) {
        for(let i: number = 0; i < this.arrData.length; i++) {
          if( this.arrData[i][this._value] == $(this.compSelect.nativeElement).selectpicker('val') ) {
            objReturn = i;
            this._index = i;
            break;
          }
        }
      }

      return objReturn;
    }

  @Input()
    set selectedValue(arrSelectedValue : any ) {

      if(this.arrData !== undefined && this.arrData != null && this.arrData.length > 0) {
        $(this.compSelect.nativeElement).selectpicker('val', arrSelectedValue);
      }
    }
    get selectedValue(): any {
      let objReturn : string = null;
      if(this.arrData !== undefined && this.arrData != null && this.arrData.length > 0) {
        if(this._index > -1 && this._index < this.arrData.length ) {
          objReturn = $(this.compSelect.nativeElement).selectpicker('val');
        }
      }
      return objReturn;
    }

  @Input() 
    set data(argData : Array<any> ) {
      if(argData != null && argData != undefined && Array.isArray(argData) ) {
        this.arrData = argData;
        this.generateOptions();
      } else {
        this.arrData = [];
      }
    }
    get data(): Array<any> {
      return this.arrData;
    }

  @Input() 
    set dataPath(argDataPath : string ) {
      if(argDataPath != null && argDataPath != undefined) {
        this._dataPath = argDataPath;
      }
    }
    get dataPath(): string {
      return this._dataPath;
    }

  @Input() 
    set label(argLabel : string ) {
      this._label = argLabel;
      this.generateOptions();
    }
    get label(): string {
      return this._label;
    }

  @Input() 
    get length(): number {
      let objReturn : number = 0;
      if(this.arrData != undefined && this.arrData != null ) {
        objReturn = this.arrData.length;
      }
      return objReturn;
    }

  @Input() 
    set value(argValue : string ) {
      this._value = argValue;
      if(this.value != null) {
        this._values = this._value.split(",");
        for(let index : number = 0; index < this._values.length; index++ ) {
          this._values[index] = this._values[index].trim();
        }
      }
      this.generateOptions();
    }
    get value(): string {
      return this._value;
    }

  @Input() 
    set estate(argEstate : string ) {
      this.estateUpdate(argEstate);
    }
    get estate(): string {
      return this._estate;
    }

  @Input() 
    set liveSearch(argSearchLive : boolean ) {
      
      if (argSearchLive ) {
        this._dataLiveSearch = 'true';
      } else {
        this._dataLiveSearch = 'false';
      }
      this.refresh();
    }
    get liveSearch(): boolean {
      let objReturn : boolean = false;
      if (this._dataLiveSearch == 'true' ) {
        objReturn = true;
      }
      return objReturn;
    }


  constructor(protected renderer : Renderer2, protected objSelectService : SelectService) { 

    this._unselectedLabel = "";
    this._unselectedValue = "";

    this.arrData = [];
    this._dataPath = null;
    this.arrOptions = [];
    this._index = -1;

    this._label = "";
    this._value = "";

    this._estate = SelectComponent.DISABLED;

    this.title = "";

  }


  // DISPACHERS

  @Output() onFocusIn: EventEmitter<any> = new EventEmitter(); // PENDIENT
  @Output() onFocusOut: EventEmitter<any> = new EventEmitter(); // PENDIENTE
  @Output() onChange: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onEstateChange: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onLoadCompleted: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onLoadError: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onLoaded: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onInicialization: EventEmitter<any> = new EventEmitter(); // LISTO

  

  ngAfterContentInit() {
    // let _this : any = this;
    $(this.compSelect.nativeElement).on('changed.bs.select', (event) => {
      this._index = this.selectedIndex;
      this.onChange.emit(this);
      this.showLineStatus();
      
    });

    $(this.compSelect.nativeElement).on('refreshed.bs.select', (event) => {
      this.inicialization = true;
      this.onInicialization.emit(this);
      
    });

    this.refresh();

  }

  // METHODS
  public load(varArgs : any = null, property : string = 'list') : void {

    if( Globals.validStringValue(this._dataPath) ) {

      let currentEstate : string = this.estate;
      this.disabled();
      
      this.objSelectService.list(this._dataPath, varArgs)
      .subscribe( objResponse => {
        if(objResponse.session) {
          if(objResponse.action) {
            this.data = objResponse.result[property];
            this.estate = currentEstate;
            this.onLoadCompleted.emit(this);
            this.onLoaded.emit(this);
            
          } else {
            this.data = [];
            this.estate = currentEstate;
            this.onLoadError.emit(this);
            this.onLoaded.emit(this);
          }
        } else {
          //cerrar sesion
          this.onLoadError.emit(this);
          this.onLoaded.emit(this);
        }  
      }, error => {
        this.data = [];
        this.estate = currentEstate;
        this.onLoadError.emit(this);
        this.onLoaded.emit(this);
      });
    }
  }

  public selectedProperty(argKey : string, argDefault : any = null): any {
    let objReturn : any = argDefault;
    
    let object : any = this.selectedObject;
    if(object != null && object[argKey] != undefined) {
      objReturn = object[argKey];
    }
    return objReturn;
  }

  public clear() : void {
    this.arrData = [];
    this.generateOptions();
  }

  public disabled() : void {
    this.estateUpdate('disabled');
  }

  public isDisabled() : boolean {
    return (this.estate == 'enabled');
  }

  public enabled() : void {
    this.estateUpdate('enabled');
  }

  public isEnabled() : boolean {
    return (this.estate == 'enabled');
  }

  public readonly() {
    this.estateUpdate('readonly');
  }

  public isReadonly() : boolean {
    return (this.estate == 'enabled');
  }
  

  public focus() : void {
    $(this.compSelect.nativeElement).focus();
  }

  // BEHAVIORS
  public refresh() : void {
    this.appearance();
    Globals.objectExist(() => this.compSelect ).then( () => {
      $(this.compSelect.nativeElement).selectpicker('refresh');
    });
  }

  // public async refresh(argValue : any) : Promise<void> {
  //   this.appearance();

  //   return new Promise<void>(resolve => {
  //     let timer = setInterval(() => {
  //       if(this.compSelect != undefined) {
  //         $(this.compSelect.nativeElement).selectpicker('refresh');
  //         clearInterval(timer);
  //         resolve();
  //       }
  //     }, 1);
  //   });
  // }

  private generateOptions() : void {

    this.compSelect.nativeElement.length = 0;

    if(this._unselectedLabel.trim() != "") {
      let objOption = new Option(this._unselectedLabel, this._unselectedValue);
      this.compSelect.nativeElement.appendChild(objOption);
    }

    if( this._value != undefined && this._value != null && this._value != ""
        && this._label != undefined && this._label != null && this._label != ""
        && this.arrData != undefined && this.arrData != null ) {
          for(let i : number = 0; i < this.arrData.length; i++) {
            let objOption = new Option(this.arrData[i][this._label], this.arrData[i][this._value]);
            this.compSelect.nativeElement.appendChild(objOption);
          }
    }

    this.refresh();

  }

  private estateUpdate( strChange : string ) : void {
    if(strChange != null) {
      switch(strChange.toLowerCase()) {
        case 'enabled':
        case 'disabled':
        case 'readonly':
          this._estate = strChange.toLowerCase();
          this.refresh();
          this.onEstateChange.emit(strChange);
          break;
      }
    }
  }

  private appearance() : void {
    let compSelect = this.compSelect.nativeElement; 

    switch(this._estate) {
      case SelectComponent.ENABLED:   
        compSelect.disabled = false;
      break;

      case SelectComponent.DISABLED:
        compSelect.disabled = true;
      break;

      case SelectComponent.READONLY:
        compSelect.disabled = true;
      break;
    }
  }

  public equals() {


    // if(this._unselectedLabel.trim() != "") {
    //   objReturn = this.arrData[this._index - 1];
    // } else {
    //   objReturn = this.arrData[this._index ];
    // }



    for(let index : number = 0; index < this._values.length; index++ ) {
      this._values[index]
    }
  }

  public showLineStatus() : void {

    if(this.compContainer != undefined) {
      if( this.selectedObject == undefined ) {
        $(this.compContainer.nativeElement).removeClass("filled");
        if( this.required ) {
          $(this.compContainer.nativeElement).addClass("required");
        } else {
          $(this.compContainer.nativeElement).removeClass("required");
        }
      } else {
        $(this.compContainer.nativeElement).removeClass("required");
        $(this.compContainer.nativeElement).addClass("filled");
      }
    }
  }




  // VALIDACIONES 
  public isValid() : boolean  {
    this._firstFocus = true;
    this.showLineStatus();  
    return this.selectedObject != undefined;
  }

  public isRequired() : boolean {
    return this.required;
  }

  public componentValue() : any {
    return this.selectedObject;
  }

  public getField() : string {
    return this.field;
  }

  public getFieldValue() : string {
    return this.value;
  }

  public getObject() : any {
    return this.selectedObject;
  }
  

}