import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ModulosCuestionariosService } from 'src/app/services/ModulosCuestionarios/modulosCuestionarios.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-capacitaciones-tipos-index-modulos-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class CapacitacionesTiposIndexModulosPreguntasComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  public boolGuardar : boolean = false;
  public objDialogs  : Dialogs = new Dialogs();

  public objData : any = null;
  public lstModulosCuestionarios : Array<any> = [];

  constructor(private objDialogRef : MatDialogRef<CapacitacionesTiposIndexModulosPreguntasComponent>, 
              private objModulosCuestionariosService : ModulosCuestionariosService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.index();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnAdd_clickEvent() {
    const objCuestionario : any =
    {
      idModulo          : this.objData.idModulo,
      name              : "",
      modulosRespuestas : [{name: "", isCorrecta: true},{name: "", isCorrecta: false},{name: "", isCorrecta: false}]
    }

    this.lstModulosCuestionarios.push(objCuestionario);
  }

  btnDelete_clickEvent(index : number) {
    this.lstModulosCuestionarios.splice(index, 1);
  }

  btnAddRespuesta_clickEvent(cuestionario : any) {
    var preguntas : Array<any> = cuestionario.modulosRespuestas;
    
    if(preguntas.some(x => x.isCorrecta)) {
      preguntas.push({name: "", isCorrecta: false});
    } else {
      preguntas.push({name: "", isCorrecta: true});
    }

    cuestionario.modulosCuestionarios = preguntas;
  }

  btnDeleteRespuesta_clickEvent(cuestionario : any, index : number) {
    var preguntas : Array<any> = cuestionario.modulosRespuestas;
    preguntas.splice(index, 1);
  }

  chkCorrecta_changeEvent(cuestionario : any, index : number) {
    var preguntas : Array<any> = cuestionario.modulosRespuestas;
    preguntas.forEach(x => {
      x.isCorrecta = false;
    });

    setTimeout(() => {
      preguntas[index].isCorrecta = true;
    }, 50);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.lstModulosCuestionarios, event.previousIndex, event.currentIndex);
  }

  dropRespuesta(event: CdkDragDrop<string[]>, cuestionario : any) {
    var preguntas : Array<any> = cuestionario.modulosRespuestas;
    moveItemInArray(preguntas, event.previousIndex, event.currentIndex);
  }

  btnGuardar_clickEvent()
  {
    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  public index() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    let objPost : any =
    {
      idModulo : this.objData.idModulo
    }

    this.objModulosCuestionariosService.index(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstModulosCuestionarios = objResponse.result;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  public store() : void {

    this.loading(true);

    var objPost : any =
    {
      idModulo              : this.objData.idModulo,
      modulosCuestionarios  : this.lstModulosCuestionarios
    };

    this.objModulosCuestionariosService.store(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogRef.close();
          this.onChange.emit(this);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
