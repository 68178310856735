import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { APP_BASE_HREF } from "@angular/common";
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient, @Inject(APP_BASE_HREF) private baseUrl: string) { }
  
  login(login: IUserLogin): Observable<any>{ 
    return this.http.post<any>(this.baseUrl + '/api/AspNetUser/Login', login);
  }

  refeshToken(objSesionUpdate : any): Observable<any> {
    return this.http.post<any>(this.baseUrl + "api/AspNetUser/Refresh", objSesionUpdate , Sessions.header());
  }

  logout() : Observable<ILogout> {
    return this.http.post<ILogout>(this.baseUrl + "api/AspNetUser/Logout", null);
  }

  passwordUpdate(objPasswordUpdate : any) : Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/AspNetUser/PasswordUpdate", objPasswordUpdate, Sessions.header());
  }
}

export interface ISession {
    id            : string,
    user          : any,
    token         : string;
    rol           : string,
    idRol         : number,
    privilegies   : any,
    menu          : any,
    expiration    : string,
    action        : boolean
}

export interface IUserLogin {
  email: string;
  password: string;
}
  
export interface ILogout {
  action          : boolean;
  title           : string;
  message         : string;
  errorCode       : string;
}