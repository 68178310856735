import { Sessions } from '../../libraries/sessions';
import { IReturn } from '../../libraries/ireturn';
import { Injectable, Inject, ElementRef } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_BASE_HREF } from '@angular/common';

@Injectable()
export class SelectService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  public estatuto : ElementRef;
  
  list(dataPath : string, varArgs : any = null): Observable<IReturn> {
    if(varArgs == null) {
      return this.http.post<IReturn>(this.baseUrl + "api/" + dataPath, {}, Sessions.header());
    } else {
      return this.http.post<IReturn>(this.baseUrl + "api/" + dataPath, varArgs, Sessions.header());
    }
  }

}
