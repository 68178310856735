import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { InstructoresService } from 'src/app/services/instructores/instructores.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-instructores-index-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class InstructoresIndexEditComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  public objData : any = null;

  constructor(private objDialogRef : MatDialogRef<InstructoresIndexEditComponent>, 
              private objInstructoresService : InstructoresService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'name'            : new FormControl(null, [Validators.required]),
      'apellidoPaterno' : new FormControl(null, [Validators.required]),
      'apellidoMaterno' : new FormControl(null),
      'registro1'       : new FormControl(null, [Validators.required]),
      'registro2'       : new FormControl(null, [Validators.required]),
      'registro3'       : new FormControl(null, [Validators.required])
    });

    this.formGroup.controls["name"].setValue(this.objData.name);
    this.formGroup.controls["apellidoPaterno"].setValue(this.objData.apellidoPaterno);
    this.formGroup.controls["apellidoMaterno"].setValue(this.objData.apellidoMaterno);
    this.formGroup.controls["registro1"].setValue(this.objData.registro1);
    this.formGroup.controls["registro2"].setValue(this.objData.registro2);
    this.formGroup.controls["registro3"].setValue(this.objData.registro3);
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private update() : void {

    this.loading(true);

    let objInstructorUpdate : any =
    {
      idInstructor    : this.objData.idInstructor,
      name            : this.formGroup.controls["name"].value,
      apellidoPaterno : this.formGroup.controls["apellidoPaterno"].value,
      apellidoMaterno : this.formGroup.controls["apellidoMaterno"].value,
      registro1       : this.formGroup.controls["registro1"].value,
      registro2       : this.formGroup.controls["registro2"].value,
      registro3       : this.formGroup.controls["registro3"].value
    };

    this.objInstructoresService.update(objInstructorUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
