import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatSelect, MatTableDataSource, MatSelectChange, MatBottomSheet, MatDialog } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormControl } from '@angular/forms';
import { Capacitaciones_ClientesParticipantesService } from 'src/app/services/capacitaciones_ClientesParticipantes/capacitaciones_ClientesParticipantes.service';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Globals } from 'src/app/libraries/globals';
import { Wait } from 'src/app/ui/wait/Wait';
import { CapacitacionesCertificadosFiltrosComponent } from './filtros/filtros.component';
import { Sessions } from 'src/app/libraries/sessions';
import { CapacitacionesCertificadosEditarComponent } from './editar/editar.component';

@Component({
  selector: 'comp-capacitaciones-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.css']
})
export class CapacitacionesCertificadosComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;
  @ViewChildren('sltFilter')                    sltFilter       : QueryList<MatSelect>;

  private lstCapacitacionesParticipantes  : Array<any> = [];
  private objDialogs                      : Dialogs = new Dialogs();
  private index                           : number;

  // SEARCH
  private txtSearch = new FormControl();

  // DATES FILTER
  private txtDateDesde  = new FormControl();
  private txtDateHasta  = new FormControl();

  // FILTERS
  private sltCapacitacionGrupo  = new FormControl();
  private sltCapacitacionTipo   = new FormControl();
  private sltInstructor         = new FormControl();
  private sltCoordinador        = new FormControl();
  private sltIsVigente          = new FormControl();
  private sltEstado             = new FormControl();
  private sltCreatedAspNetUser  = new FormControl();
  private sltUpdatedAspNetUser  = new FormControl();

  private lstCapacitacionesGrupos : Array<any> = [];
  private lstCapacitacionesTipos  : Array<any> = [];
  private lstInstructores         : Array<any> = [];
  private lstCoordinadores        : Array<any> = [];
  private lstIsVigente            : Array<any> = [];
  private lstEstados              : Array<any> = [];
  private lstCreatedAspNetUser    : Array<any> = [];
  private lstUpdatedAspNetUser    : Array<any> = [];

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'certificadoNumero', 'certificadoFecha', 'nombreCompleto', 'capacitacionGrupo', 'capacitacionTipo', 'periodo', 'vigencia', 'cliente', 'folioCapacitacion'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Folio'}, 
                                {field : 'CertificadoNumero'},
                                {field : 'ClienteParticipanteNombre'},
                                {field : 'ClienteParticipanteApellidoPaterno'},
                                {field : 'ClienteParticipanteApellidoMaterno'},
                                {field : 'ClienteParticipanteNombreCompleto'},
                                {field : 'ClienteParticipanteCURP'},
                                {field : 'ClienteParticipantePuesto'},
                                {field : 'Observaciones'},
                                {field : 'Capacitacion.CapacitacionGrupoName'},
                                {field : 'Capacitacion.CapacitacionTipoName'},
                                {field : 'Capacitacion.InstructorNombreCompleto'},
                                {field : 'Capactiacion.CoordinadorNombreCompleto'},
                                {field : 'Capacitacion.ClienteRazonSocial'},
                                {field : 'Capacitacion.ClienteRFC'},
                                {field : 'Capacitacion.RepresentanteLegalNombreCompleto'},
                                {field : 'Capacitacion.RepresentanteTrabajadorNombreCompleto'},
                                {field : 'Capacitacion.OrdenCompra'},
                                {field : 'Capacitacion.Ciudad'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objCapacitaciones_ClientesParticipantesService : Capacitaciones_ClientesParticipantesService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService,
              private objRouter : Router,
              private datePipe : DatePipe,
              private dialog : MatDialog,
              private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {

    this.lstIsVigente.push({ value: 'true', name: "Si" });
    this.lstIsVigente.push({ value: 'false', name: "No" });

    this.initialization();
    this.dataSource = new MatTableDataSource<any>();

    this.objPrivilegies = Sessions.getItem('privilegies');
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnFiltros_clickEvent() {
    
    var objData : any =
    {
      lstCapacitacionesGrupos : this.lstCapacitacionesGrupos,
      lstCapacitacionesTipos  : this.lstCapacitacionesTipos,
      lstInstructores         : this.lstInstructores,
      lstCoordinadores        : this.lstCoordinadores,
      lstIsVigente            : this.lstIsVigente,
      lstEstados              : this.lstEstados,
      lstCreatedAspNetUser    : this.lstCreatedAspNetUser,
      lstUpdatedAspNetUser    : this.lstUpdatedAspNetUser,

      // FORM CONTROL
      txtDateDesde          : this.txtDateDesde.value,
      txtDateHasta          : this.txtDateHasta.value,
      sltCapacitacionGrupo  : this.sltCapacitacionGrupo.value,
      sltCapacitacionTipo   : this.sltCapacitacionTipo.value,
      sltInstructor         : this.sltInstructor.value,
      sltCoordinador        : this.sltCoordinador.value,
      sltIsVigente          : this.sltIsVigente.value,
      sltEstado             : this.sltEstado.value,
      sltCreatedAspNetUser  : this.sltCreatedAspNetUser.value,
      sltUpdatedAspNetUser  : this.sltUpdatedAspNetUser.value,
    }

    var bottomSheet = this._bottomSheet.open(CapacitacionesCertificadosFiltrosComponent, {
      data : objData
    });

    bottomSheet.instance.onChange.subscribe((item: CapacitacionesCertificadosFiltrosComponent) => {

      this.txtDateDesde.setValue(item.txtDateDesde.value);
      this.txtDateHasta.setValue(item.txtDateHasta.value);

      this.sltCapacitacionGrupo = item.sltCapacitacionGrupo;
      this.sltCapacitacionTipo  = item.sltCapacitacionTipo;
      this.sltInstructor        = item.sltInstructor;
      this.sltCoordinador       = item.sltCoordinador;
      this.sltIsVigente         = item.sltIsVigente;
      this.sltEstado            = item.sltEstado;
      this.sltCreatedAspNetUser = item.sltCreatedAspNetUser;
      this.sltUpdatedAspNetUser = item.sltUpdatedAspNetUser;

      if(Globals.validValue(item.sltCapacitacionGrupo.value)) {
        this.getCapacitacionesTipos(item.sltCapacitacionGrupo.value);
      }

      this.sltFilter.toArray()[0].value = this.sltIsVigente.value;
      this.sltFilter.toArray()[1].value = this.sltCapacitacionGrupo.value;
      this.sltFilter.toArray()[2].value = this.sltCapacitacionTipo.value;
      this.sltFilter.toArray()[3].value = this.sltInstructor.value;
      this.sltFilter.toArray()[4].value = this.sltCoordinador.value;
      this.sltFilter.toArray()[5].value = this.sltEstado.value;
      this.sltFilter.toArray()[6].value = this.sltCreatedAspNetUser.value;
      this.sltFilter.toArray()[7].value = this.sltUpdatedAspNetUser.value;

      this.build();
      
    });
  }

  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {
    this.sltCapacitacionTipo.setValue("");
    this.lstCapacitacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);
    }
  }

  btnCertificado_clickEvent(element : any, boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/Certificado', element.idCapacitacion, element.idClienteParticipante, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnCredencial_clickEvent(element : any) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/Credencial', element.idCapacitacion, element.idClienteParticipante ]);
    window.open(url.toString(), '_blank');
  }

  btnDC3_clickEvent(element : any, boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3', element.idCapacitacion, element.idClienteParticipante, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnModificar_clickEvent(element : any) {
    const dialogRef = this.dialog.open(CapacitacionesCertificadosEditarComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '500px',
      maxHeight : '450px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
  }

  // METODOS
  private initialization() : void {

    this.objCapacitaciones_ClientesParticipantesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;
          this.lstInstructores          = objResponse.result.instructores;
          this.lstCoordinadores         = objResponse.result.instructores;
          this.lstEstados               = objResponse.result.estados;
          this.lstCreatedAspNetUser     = objResponse.result.usuarios;
          this.lstUpdatedAspNetUser     = objResponse.result.usuarios;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }
  
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : this.filters(),
      dateFrom            : Globals.validValue(this.txtDateDesde.value) ? this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd') : "",
      dateTo              : Globals.validValue(this.txtDateHasta.value) ? this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd') : "",
      dateOptions         : [{field : "CertificadoFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objCapacitaciones_ClientesParticipantesService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesParticipantes = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstCapacitacionesParticipantes);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private filters() {
    let lstFilters : Array<any> = [];

    if(Globals.validValue(this.sltFilter))
    {
      this.sltFilter.forEach(itemFilter => {

        if(Globals.validValue(itemFilter.value))
        {
          var filter : any = {
            field           : itemFilter.id,
            value           : itemFilter.value
          };

          lstFilters.push(filter);
        }

      })
    }

    return lstFilters;
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.txtDateDesde.setValue("");
    this.txtDateHasta.setValue("");
    this.sltCapacitacionGrupo.setValue("");
    this.sltCapacitacionTipo.setValue("");
    this.sltInstructor.setValue("");
    this.sltCoordinador.setValue("");
    this.sltIsVigente.setValue("");
    this.sltEstado.setValue("");
    this.sltCreatedAspNetUser.setValue("");
    this.sltUpdatedAspNetUser.setValue("");
    
    this.paginator.pageIndex  = 0;
    this.paginator.pageSize   = 15;
    this.lstCapacitacionesTipos = [];

    this.sltFilter.forEach(itemFilter => {
      itemFilter.value = "";
    });
    
    this.build();
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.sltCapacitacionGrupo.disable();
    this.sltCapacitacionTipo.disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

    }, error => {

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
