import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatButton, MatDialogRef } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesParticipantesService } from 'src/app/services/clientesParticipantes/clientesParticipantes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-clientes-index-participantes-upload-foto',
  templateUrl: './upload-foto.component.html',
  styleUrls: ['./upload-foto.component.css']
})
export class ClientesIndexParticipantesUploadFotoComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar       : boolean = false;
  private objDialogs        : Dialogs = new Dialogs();
  
  private selectedImage     : boolean = false;
  private imageChangedEvent : any = '';
  public  croppedImage      : any = '';
  private extensionImage    : string = '.jpg';
  
  public imageURL : string = '';
  public objData  : any;

  constructor(private objDialogRef : MatDialogRef<ClientesIndexParticipantesUploadFotoComponent>,
              private objClientesParticipantesService : ClientesParticipantesService,
              private toastr: ToastrService) { }

  ngOnInit() {

    if(Globals.validValue(this.imageURL)) {
      this.imageURL = "/Ficheros/ClientesParticipantes/150x150/" + this.imageURL;
    } else {
      this.imageURL = "/images/user.jpg";
    }
    
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }
  
  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        
        event.target.value = "";
        this.toastr.warning('Solo se admiten imagenes', 'Fotografía', { timeOut: 2000 });
        this.objDialogs.WarningToast("Sólo se admiten imágenes");
        return;

      }

      var type : string = mimeType;

      this.imageChangedEvent  = event;
      this.extensionImage     = "." + type.substring(6);
      this.selectedImage      = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  btnGuardar_clickEvent()
  {
    if(!this.selectedImage) {
      this.toastr.warning('No se ha cargado una fotografía', 'Fotografía', { timeOut: 2000 });
      return;
    }
    
    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }
  
  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  private store() : void {

    this.loading(true);

    var blobFoto = this.dataURItoBlob(this.croppedImage);

    let formClienteParticipante = new FormData();

    formClienteParticipante.append("idClienteParticipante", this.objData.idClienteParticipante);
    formClienteParticipante.append("foto",                  blobFoto);
    formClienteParticipante.append("extension",             this.extensionImage);

    this.objClientesParticipantesService.uploadFoto(formClienteParticipante)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.objDialogRef.close();
            this.onChange.emit(this);
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
