import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { SessionService } from 'src/app/services/sessions/session.service';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';
import { Router } from '@angular/router';
import { Sessions } from 'src/app/libraries/sessions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'comp-dashboard-layout-sesion',
  templateUrl: './sesion.component.html',
  styleUrls: ['./sesion.component.css']
})
export class DashboardLayoutSesionComponent implements OnInit {

  @ViewChild("mainContainer", {static: false})  mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static: false})     btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static: false})      btnCerrar     : MatButton;
  @ViewChild('txtPassword', {static: false})    txtPassword   : ElementRef;

  @Output('refreshHandler') refreshHandler : EventEmitter<any> = new EventEmitter<any>();

  private objDialogs    : Dialogs = new Dialogs();
  private boolGuardar   : boolean = false;
  public txtTitleDialog : string = "";
  public txtContent     : string = "";
  public expired        : Boolean = false;
  public user           : any = null;

  private lstMenuCategoria  : any;
  private objUser           : any;

  constructor(private objSessionService : SessionService, 
              private objDialogRef: MatDialogRef<DashboardLayoutSesionComponent>, 
              private router: Router,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  // EVENTOS
  close() {
    if(!this.boolGuardar) {

      if(this.expired) {
        this.objSessionService.logout().subscribe(
          objResponse => {
            this.objDialogRef.close();
            this.router.navigate(["/"]);
          }, error => {
            this.objDialogRef.close();
            this.router.navigate(["/"]);
          }
        )
      } else {
        this.objDialogRef.close();
      }
      
    }
  }

  btnGuardar_clickEvent() {

    if(!Globals.validStringValue(this.txtPassword.nativeElement.value)) {
      this.toastr.warning('Por favor ingrese la contraseña', 'Sesión', { timeOut: 2000 });
      return;
    }

    this.store();

  }

  btnCancelar_clickEvent() {
    if(this.expired) {

      this.objSessionService.logout().subscribe(
        objResponse => {
          this.objDialogRef.close();
          this.router.navigate(["/"]);
        }, error => {
          this.objDialogRef.close();
          this.router.navigate(["/"]);
        }
      )

    } else {
      this.objDialogRef.close();
    }
  }

  // METODOS
  private store() : void {

    let objSesionUpdate : any =
    {
      email     : this.user.email,
      password  : this.txtPassword.nativeElement.value
    }

    this.loading(true);

    this.objSessionService.refeshToken(objSesionUpdate).subscribe(
    objResponse => {
      
      if (Sessions.start(objResponse)) {

        this.lstMenuCategoria = Sessions.getItem('menu');
        this.objUser          = Sessions.getItem('user');

        this.objDialogRef.close();
        this.refreshHandler.emit(this);
        this.objDialogs.Success("Exito", "La sesión ha sido renovado correctamente");

      } else {

        this.toastr.warning('Contraseña incorrenta', 'Sesión', { timeOut: 2000 });

      }

      this.loading(false);

    }, objError => {

      this.loading(false);
      this.toastr.error("Ocurrió un error de conexión con el servidor. Error no." + objError.status + ".", "Error inesperado");

    });

  }

  private loading(value : boolean) {
    if(value) {
      Wait.show(this.mainContainer, "Renovando sesión...")
      this.btnGuardar.disabled  = true;
      this.btnCerrar.disabled   = true;
      this.boolGuardar          = true;
    } else {
      Wait.hide(this.mainContainer)
      this.btnGuardar.disabled  = false;
      this.btnCerrar.disabled   = false;
      this.boolGuardar          = false;
    }
  }

}