import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Wait } from 'src/app/ui/wait/Wait';
import { MatCheckboxChange } from '@angular/material';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-clientes-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ClientesCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  fileBase64 : string = "";

  get hasBase64() : boolean {
    return Globals.validStringValue(this.fileBase64);
  }

  constructor(private objClientesService : ClientesService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'name'                : new FormControl(null, [Validators.required]),
      'razonSocial'         : new FormControl(null, [Validators.required]),
      'rfc'                 : new FormControl(null, [Validators.required]),
      'telefono'            : new FormControl(null),
      'celular'             : new FormControl(null),
      'email'               : new FormControl(null),
      'direccion'           : new FormControl(null),
      'isParticular'        : new FormControl(false),
      'showFirmaLegal'      : new FormControl(false),
      'showFirmaTrabajador' : new FormControl(false)
    });

  }

  //EVENTOS
  chkParticular_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.form.controls["razonSocial"].disable();
      this.form.controls["rfc"].disable();
    } else {
      this.form.controls["razonSocial"].enable();
      this.form.controls["rfc"].enable();
    }
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var mimeType = file.type;

      event.target.value = "";
      
      if(mimeType.match(/image\/*/) == null){ 
        this.toastr.info("Solo se admiten imagenes", "Mensaje", { timeOut: 2000 }); 
        return; 
      };

      Globals.fileToBase64(file).subscribe((result => {
        this.fileBase64 = result;
      }));
    }
  }

  btnDeleteFile() {
    this.fileBase64 = "";
  }
  
  btnGuardar_clickEvent() {
    
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    this.store();

  }

  // METODOS
  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let objClienteStore : any =
    {
      name                : this.form.controls["name"].value,
      razonSocial         : this.form.controls["razonSocial"].value,
      rfc                 : this.form.controls["rfc"].value,
      telefono            : this.form.controls["telefono"].value,
      celular             : this.form.controls["celular"].value,
      email               : this.form.controls["email"].value,
      direccion           : this.form.controls["direccion"].value,
      isParticular        : this.form.controls["isParticular"].value,
      showFirmaLegal      : this.form.controls["showFirmaLegal"].value,
      showFirmaTrabajador : this.form.controls["showFirmaTrabajador"].value,
      fileBase64          : this.fileBase64
    };

    this.objClientesService.store(objClienteStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "clientes/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
