import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlinesUsuariosService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  index(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Usuarios/Index", data, Sessions.header());
  }

  restart(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Usuarios/Restart", data, Sessions.header());
  }

  restartAll(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Usuarios/RestartAll", data, Sessions.header());
  }

}