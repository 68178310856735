import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormControl } from '@angular/forms';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Sessions } from 'src/app/libraries/sessions';
import { Globals } from 'src/app/libraries/globals';
import { Wait } from 'src/app/ui/wait/Wait';
import { UsuariosIndexEditComponent } from './edit/edit.component';
import { UsuariosIndexPasswordComponent } from './password/password.component';
import { UsuariosIndexPrivilegiosComponent } from './privilegios/privilegios.component';

@Component({
  selector: 'comp-usuarios-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class UsuariosIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;

  private lstUsuarios : Array<any> = [];
  private objDialogs  : Dialogs = new Dialogs();
  private index       : number;

  // SEARCH
  private txtSearch = new FormControl();

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'nombre', 'apellidoPaterno', 'apellidoMaterno', 'email', 'activated'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'UserName'},
                                {field : 'NormalizedUserName'},
                                {field : 'Email'},
                                {field : 'NormalizedEmail'},
                                {field : 'Nombre'},
                                {field : 'ApellidoPaterno'},
                                {field : 'ApellidoMaterno'},
                                {field : 'NombreCompleto'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objUsuariosService : UsuariosService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe : DatePipe) { }

  ngOnInit() {
    this.objPrivilegies = Sessions.getItem('privilegies');
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(UsuariosIndexEditComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '500px',
      maxHeight : '400px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnPassword_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(UsuariosIndexPasswordComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '300px'
    });

    dialogRef.componentInstance.objData = element;

  }

  btnPrivilegios_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(UsuariosIndexPrivilegiosComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '800px',
      maxHeight : '600px'
    });

    dialogRef.componentInstance.objData = element;

  }

  btnActivated_clickEvent(element : any) {

    var boolActivated = !element.activated;
    var txtTitle = element.activated ? "Desactivar" : "Activar";
    var txtMessage = element.activated ? "¿Desactivar usuario " + element.nombreCompleto + "?" : "¿Activar usuario " + element.nombreCompleto + "?";

    this.objDialogs.Confirm(txtTitle, txtMessage, txtTitle, "Cancelar", ()=> {
      this.activated(element.id, boolActivated);
    });
  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar usuario "+ element.nombreCompleto +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.id);
    });

  }

  // METODOS
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : [],
      dateFrom            : "",
      dateTo              : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objUsuariosService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstUsuarios          = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstUsuarios);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.build();
  }

  private delete(id : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...")

    let objUsuarioDelete : any =
    {
      id : id
    };

    this.objUsuariosService.delete(objUsuarioDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private activated(id : any, boolActivated : boolean) : void {
    
    Wait.show(this.tableContainer, "Espere por favor...")

    let objUsuario : any =
    {
      id        : id,
      activated : boolActivated
    };

    this.objUsuariosService.activateUsuario(objUsuario)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
