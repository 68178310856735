import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Capacitaciones_ClientesParticipantesService } from 'src/app/services/capacitaciones_ClientesParticipantes/capacitaciones_ClientesParticipantes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-capacitaciones-certificados-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class CapacitacionesCertificadosEditarComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  public objData : any = null;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesCertificadosEditarComponent>, 
              private objCapacitaciones_ClientesParticipantesService : Capacitaciones_ClientesParticipantesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'nombre'          : new FormControl("", [Validators.required]),
      'apellidoPaterno' : new FormControl("", [Validators.required]),
      'apellidoMaterno' : new FormControl(""),
      'curp'            : new FormControl(""),
      'puesto'          : new FormControl(""),
    });

    this.formGroup.controls["nombre"].setValue(this.objData.nombre);
    this.formGroup.controls["apellidoPaterno"].setValue(this.objData.apellidoPaterno);
    this.formGroup.controls["apellidoMaterno"].setValue(this.objData.apellidoMaterno);
    this.formGroup.controls["curp"].setValue(this.objData.curp);
    this.formGroup.controls["puesto"].setValue(this.objData.puesto);
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private update() : void {

    this.loading(true);

    let objCapacitacion_ClienteParticipanteUpdate : any =
    {
      numeroCertificado : this.objData.certificadoNumero,
      nombre            : this.formGroup.controls["nombre"].value,
      apellidoPaterno   : this.formGroup.controls["apellidoPaterno"].value,
      apellidoMaterno   : this.formGroup.controls["apellidoMaterno"].value,
      curp              : this.formGroup.controls["curp"].value,
      puesto            : this.formGroup.controls["puesto"].value
    };

    this.objCapacitaciones_ClientesParticipantesService.update(objCapacitacion_ClienteParticipanteUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          // this.objDialogs.Success(objResponse.title, objResponse.message);

          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
