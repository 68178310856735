import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter, Inject } from '@angular/core';
import { MatSelect, MAT_BOTTOM_SHEET_DATA, MatSelectChange } from '@angular/material';
import { FormControl } from '@angular/forms';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class CapacitacionesCertificadosFiltrosComponent implements OnInit {

  @ViewChildren('sltFilter') sltFilter : QueryList<MatSelect>;

  @Output("onChange") onChange : EventEmitter<any> = new EventEmitter<any>();

  // DATES FILTER
  public txtDateDesde  = new FormControl();
  public txtDateHasta  = new FormControl();

  // FILTERS
  public sltCapacitacionGrupo    = new FormControl();
  public sltCapacitacionTipo     = new FormControl();
  public sltInstructor           = new FormControl();
  public sltCoordinador          = new FormControl();
  public sltIsVigente         = new FormControl();
  public sltEstado               = new FormControl();
  public sltCreatedAspNetUser    = new FormControl();
  public sltUpdatedAspNetUser    = new FormControl();

  public lstCapacitacionesGrupos  : Array<any> = [];
  public lstCapacitacionesTipos   : Array<any> = [];
  public lstInstructores          : Array<any> = [];
  public lstCoordinadores         : Array<any> = [];
  public lstIsVigente             : Array<any> = [];
  public lstEstados               : Array<any> = [];
  public lstCreatedAspNetUser     : Array<any> = [];
  public lstUpdatedAspNetUser     : Array<any> = [];

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.lstCapacitacionesGrupos  = this.data.lstCapacitacionesGrupos;
    this.lstCapacitacionesTipos   = this.data.lstCapacitacionesTipos;
    this.lstInstructores          = this.data.lstInstructores;
    this.lstCoordinadores         = this.data.lstCoordinadores;
    this.lstIsVigente             = this.data.lstIsVigente;
    this.lstEstados               = this.data.lstEstados;
    this.lstCreatedAspNetUser     = this.data.lstCreatedAspNetUser;
    this.lstUpdatedAspNetUser     = this.data.lstUpdatedAspNetUser;

    this.txtDateDesde.setValue(this.data.txtDateDesde);
    this.txtDateHasta.setValue(this.data.txtDateHasta);
    this.sltCapacitacionGrupo.setValue(this.data.sltCapacitacionGrupo);
    this.sltCapacitacionTipo.setValue(this.data.sltCapacitacionTipo);
    this.sltInstructor.setValue(this.data.sltInstructor);
    this.sltCoordinador.setValue(this.data.sltCoordinador);
    this.sltIsVigente.setValue(this.data.sltIsVigente);
    this.sltEstado.setValue(this.data.sltEstado);
    this.sltCreatedAspNetUser.setValue(this.data.sltCreatedAspNetUser);
    this.sltUpdatedAspNetUser.setValue(this.data.sltUpdatedAspNetUser);
  }

  // METODOS
  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {
    this.sltCapacitacionTipo.setValue("");
    this.lstCapacitacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);
    }
  }

  // METODOS
  private build() : void {
    this.onChange.emit(this);
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.sltCapacitacionGrupo.disable();
    this.sltCapacitacionTipo.disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

    }, error => {

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
