import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatDialog, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ModulosService } from 'src/app/services/Modulos/modulos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CapacitacionesTiposIndexModulosCreateComponent } from './create/create.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Wait } from 'src/app/ui/wait/Wait';
import { CapacitacionesTiposIndexModulosPreguntasComponent } from './preguntas/preguntas.component';

@Component({
  selector: 'app-capacitaciones-tipos-index-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.css']
})
export class CapacitacionesTiposIndexModulosComponent implements OnInit {

  @ViewChild("mainContainer", {static: false})  mainContainer : ElementRef;
  @ViewChild('btnCerrar', {static: false})      btnCerrar     : MatButton;

  private objDialogs  : Dialogs = new Dialogs();
  private boolGuardar : boolean = false;

  public objData : any = null;
  
  lstModulos : Array<any> = [];

  constructor(private objDialogRef: MatDialogRef<CapacitacionesTiposIndexModulosComponent>,
              private dialog : MatDialog,
              private objModulosService : ModulosService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.index();
  }

  // EVENTOS
  close() {
    if(!this.boolGuardar) {
      this.objDialogRef.close();
    }
  }

  btnActualizar_clickEvent() {
    this.index();
  }

  calculateTimeFromSeconds(totalSeconds: number) : string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return hours + "h:" + minutes + "m:" + seconds + "s";
  }

  btnNuevo_clickEvent() {

    const dialogRef = this.dialog.open(CapacitacionesTiposIndexModulosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '700px',
      maxHeight : '100%'
    });

    dialogRef.componentInstance.objData = this.objData;
    dialogRef.componentInstance.orden = this.lstModulos.length + 1;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.index();
    });

  }

  btnEdit_clickEvent(element : any, index : number) {
    
    const dialogRef = this.dialog.open(CapacitacionesTiposIndexModulosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '700px',
      maxHeight : '100%'
    });

    dialogRef.componentInstance.objData   = element;
    dialogRef.componentInstance.isEdit    = true;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.index();
    });

  }

  btnDelete_clickEvent(item : any, index : number) {

    this.objDialogs.Confirm("Eliminar", "¿Eliminar módulo " + item.name + "?", "Eliminar", "Cancelar", () => {
      this.delete(item, index);
    }, null);
    
  }

  btnAgregarPregunta_clickEvent(item : any) {

    const dialogRef = this.dialog.open(CapacitacionesTiposIndexModulosPreguntasComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '900px',
      maxHeight : '100%'
    });

    dialogRef.componentInstance.objData = item;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.index();
    });

  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex != event.currentIndex) {
      const previousModulos = [...this.lstModulos];

      moveItemInArray(this.lstModulos, event.previousIndex, event.currentIndex);
      this.updateOrden(previousModulos);
    }
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  public index() : void {
    
    Wait.show(this.mainContainer, "Espere por favor...");

    var objPost : any =
    {
      idCapacitacionTipo : this.objData.idCapacitacionTipo
    }

    this.objModulosService.index(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstModulos = objResponse.result;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private updateOrden(previousModulos) : void {

    this.loading(true);

    let objPost : any =
    {
      idCapacitacionTipo  : this.objData.idCapacitacionTipo,
      modulos             : this.lstModulos
    };

    this.objModulosService.updateOrden(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.lstModulos = previousModulos;
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        this.lstModulos = previousModulos;
      }

      this.loading(false);

    }, error => {
      this.lstModulos = previousModulos;

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private delete(item : any, index : number) : void {

    this.loading(true);

    let objPost : any =
    {
      idCapacitacionTipo  : this.objData.idCapacitacionTipo,
      idModulo            : item.idModulo
    };

    this.objModulosService.delete(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstModulos.splice(index, 1);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
