import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstructoresService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  index(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Instructores/Index", null, Sessions.header());
  }

  dataSource(varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Instructores/DataSource", varArgs, Sessions.header());
  }

  store(objInstructor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Instructores/Store", objInstructor, Sessions.headerFormData());
  }

  update(objInstructor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Instructores/Update", objInstructor, Sessions.header());
  }

  delete(objInstructor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Instructores/Delete", objInstructor, Sessions.header());
  }

  uploadFirma(objInstructor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Instructores/UploadFirma", objInstructor, Sessions.headerFormData());
  }

}