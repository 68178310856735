import { IFormComponent } from "./IFormComponent";

export class FormValidator {

    private lstFormComponents: Array<IFormComponent> = [];

    constructor() { }

    public validate() : boolean {

        let objReturn : boolean = true;

        for( let itemFormComponent of this.lstFormComponents)  {
            itemFormComponent.componentValue();
            if( !itemFormComponent.isValid() ) {
                objReturn = false;
                // break;
            }
        }

        return objReturn;
    }

    public addFormComponent( argFormComponent : IFormComponent) {
        if(argFormComponent != undefined) {
            this.lstFormComponents.push(argFormComponent);
        }
    }



}
