import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormGroupDirective } from '@angular/forms';
import { SessionService } from 'src/app/services/sessions/session.service';
import { Wait } from 'src/app/ui/wait/Wait';
import { MatButton, MatDialogRef } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Sessions } from 'src/app/libraries/sessions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'comp-dashboard-layout-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class DashboardLayoutPasswordComponent implements OnInit {

  @ViewChild("mainContainer", {static: false})  mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static: false})     btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static: false})      btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static: false})    ngFormGroup   : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private boolGuardar : boolean = false;

  FormPassword: FormGroup;

  constructor(private objSessionService : SessionService, 
              private objDialogRef: MatDialogRef<DashboardLayoutPasswordComponent>,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.FormPassword = new FormGroup({
      'passwordOld'     : new FormControl(null, [Validators.required]),
      'passwordNew'     : new FormControl(null, [Validators.required]),
      'passwordConfirm' : new FormControl(null, [Validators.required])
    }, { validators : this.passwordConfirming });
  }

  // EVENTOS
  close() {
    if(!this.boolGuardar) {
      this.objDialogRef.close();
    }
  }

  passwordConfirming(c : AbstractControl) : { invalid : boolean } {
    if(c.get('passwordNew').value !== c.get('passwordConfirm').value) {
      return { invalid : true };
    }
  }

  btnGuardar_clickEvent() {

    if(this.FormPassword.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    this.updatePassword();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {
      Wait.show(this.mainContainer, "Actualizando...")
      this.btnGuardar.disabled          = true;
      this.btnCerrar.disabled           = true;
      this.boolGuardar                  = true;
      this.objDialogRef.disableClose    = true;
    } else {
      Wait.hide(this.mainContainer)
      this.btnGuardar.disabled          = false;
      this.btnCerrar.disabled           = false;
      this.boolGuardar                  = false;
      this.objDialogRef.disableClose    = false;
    }
  }

  private updatePassword() : void {

    let objPasswordUpdate : any =
    {
      passwordOld : this.FormPassword.value.passwordOld,
      passwordNew : this.FormPassword.value.passwordNew
    }

    this.loading(true);

    this.objSessionService.passwordUpdate(objPasswordUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.objDialogs.Success("Actualizado", "El password ha sido actualizado correctamente");
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
