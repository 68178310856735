import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlinesCursosService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  index(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Index", null, Sessions.header());
  }

  dataSource(varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/DataSource", varArgs, Sessions.header());
  }

  create(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Create", null, Sessions.header());
  }

  store(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Store", data, Sessions.header());
  }

  edit(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Edit", data, Sessions.header());
  }

  update(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Update", data, Sessions.header());
  }

  cancelar(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Cancelar", data, Sessions.header());
  }

  undo(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Onlines/Cursos/Undo", data, Sessions.header());
  }

}