import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/libraries/globals';
import { ToastrService } from 'ngx-toastr';
import { startWith, map } from 'rxjs/operators';
import { ClientesParticipantesService } from 'src/app/services/clientesParticipantes/clientesParticipantes.service';
import { MatButton, MatDialog, MatDialogRef } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { Capacitaciones_ClientesParticipantesService } from 'src/app/services/capacitaciones_ClientesParticipantes/capacitaciones_ClientesParticipantes.service';
import { CapacitacionesCreateParticipanteComponent } from '../../../create/participante/participante.component';

@Component({
  selector: 'comp-capacitaciones-index-evaluacion-participante',
  templateUrl: './participante.component.html',
  styleUrls: ['./participante.component.css']
})
export class CapacitacionesIndexEvaluacionParticipanteComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar     : boolean = false;
  private objDialogs      : Dialogs = new Dialogs();

  public objData                            : any;
  public lstClientesParticipantesExistentes : Array<any> = [];

  // PARTICIPANTES
  private lstClientesParticipantes  : Array<any> = [];
  private lstParticipantes          : Array<any> = [];
  private txtParticipante           = new FormControl();
  private filteredOptions           : Observable<string[]>;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexEvaluacionParticipanteComponent>,
              private toastr: ToastrService,
              private objClientesParticipantesService : ClientesParticipantesService,
              private objCapacitaciones_ClientesParticipantes : Capacitaciones_ClientesParticipantesService,
              private dialog : MatDialog) { }

  ngOnInit() {
    this.getClientesParticipantes(this.objData.idCliente);
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  autoAgregarParticipante_selectedEvent(event : any) {

    let hasValue = this.lstParticipantes.find(item => item.idClienteParticipante == event.option.id.idClienteParticipante);

    if(Globals.validValue(hasValue)) {
      this.toastr.warning("El participante ya ha sido agregado", "Lo sentimos", { timeOut: 2000 });
    } else {
      let boolExiste = this.lstClientesParticipantesExistentes.find(item => item.idClienteParticipante == event.option.id.idClienteParticipante);

      if(Globals.validValue(boolExiste)) {
        this.toastr.warning("El participante ya pertence al curso", "Lo sentimos", { timeOut: 2000 });
      } else {
        this.lstParticipantes.push(event.option.id);
      }
      
    }

    this.txtParticipante.reset();
  }

  btnEliminarParticipante_clickEvent(index : number) {
    this.lstParticipantes.splice(index, 1);
  }

  btnNuevoClienteParticipante_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesCreateParticipanteComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '700px',
      maxHeight     : '400px',
    });

    dialogRef.componentInstance.objData = {idCliente : this.objData.idCliente};

    dialogRef.componentInstance.onChange.subscribe(item => {

      var objClienteParticipante : any =
      {
        idClienteParticipante : item.idClienteParticipante,
        nombreCompleto        : item.nombreCompleto,
        curp                  : item.curp,
        puesto                : item.puesto,
        img                   : item.img
      };

      this.lstParticipantes.push(objClienteParticipante);

      this.getClientesParticipantes(this.objData.idCliente);
    });
  }

  btnGuardar_clickEvent() {

    if(this.lstParticipantes.length == 0) {
      this.toastr.warning('No se ha agregado ningún participante', 'Participantes', { timeOut: 2000 });
      return;
    }

    this.store();
  }

  // METODOS
  private filter(value: string): string[] {
    const filterValue = Globals.validValue(value) ? value.toLowerCase() : "";

    return this.lstClientesParticipantes.filter(option => option.nombreCompleto.toLowerCase().includes(filterValue));
  }

  private updatedFilter() : void {
    this.filteredOptions = this.txtParticipante.valueChanges
    .pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  private getClientesParticipantes(idCliente : number) : void {
    
    this.txtParticipante.disable()

    let objClienteParticipante : any =
    {
      idCliente : idCliente
    };

    this.objClientesParticipantesService.listByIdCliente(objClienteParticipante)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.lstClientesParticipantes = objResponse.result.clientesParticipantes;
          this.updatedFilter();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.txtParticipante.enable()

    }, error => {

      this.txtParticipante.enable()

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let objCapacitacion_ClienteParticipanteStore : any =
    {
      idCapacitacion  : this.objData.idCapacitacion,
      participantes   : this.lstParticipantes
    };

    this.objCapacitaciones_ClientesParticipantes.store(objCapacitacion_ClienteParticipanteStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.onChange.emit(this);
          this.objDialogRef.close();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
