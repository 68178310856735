import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet, MatDialog, MatPaginator, MatSelect, MatSelectChange, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Sessions } from 'src/app/libraries/sessions';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { OnlineCursosIndexFiltrosComponent } from './filtros/filtros.component';
import { Globals } from 'src/app/libraries/globals';
import { Wait } from 'src/app/ui/wait/Wait';
import { OnlinesCursosService } from 'src/app/services/onlinesCursos/onlinesCursos.service';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { OnlineCursosEditComponent } from '../edit/edit.component';
import { OnlineCursosCancelarComponent } from '../cancelar/cancelar.component';
import { OnlineCursosUsuariosComponent } from '../usuarios/usuarios.component';

@Component({
  selector: 'app-online-cursos-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class OnlineCursosIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;
  @ViewChildren('sltFilter')                    sltFilter       : QueryList<MatSelect>;

  lstOnlinesCursos  : Array<any> = [];
  objDialogs        : Dialogs = new Dialogs();
  index             : number;

  // SEARCH
  txtSearch = new FormControl();

  // DATES FILTER
  txtDateDesde  = new FormControl();
  txtDateHasta  = new FormControl();

  // FILTERS
  sltInstructor         = new FormControl();
  sltCapacitacionGrupo  = new FormControl();
  sltCapacitacionTipo   = new FormControl();
  sltClosed             = new FormControl();
  sltExpiracion         = new FormControl();

  lstInstructores         : Array<any> = [];
  lstCapacitacionesGrupos : Array<any> = [];
  lstCapacitacionesTipos  : Array<any> = [];
  lstClosed               : Array<any> = [{ value: "true", name: "Cerrado" },{ value: "false", name: "Abierto" }, { value: "cancelado", name: "Cancelado" }];
  lstExpiracion           : Array<any> = [{ value: "true", name: "Vigente" },{ value: "false", name: "Vencido" }];

  // COLUMNS
  columns: string[] = ['index', 'folio', 'opciones', 'capacitacionTipoName', 'closed', 'fechaExpiracion', 'totalUsuario', 'link', 'clienteRazonSocial', 'capacitacionGrupoName', 'instructorNombreCompleto', 'fechaRegistro', 'periodoInicio', 'periodoFin', 'comentariosCancelacion'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Folio'},
                                {field : 'CapacitacionTipoName'},
                                {field : 'ClienteRazonSocial'},
                                {field : 'Link'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objOnlinesCursosService : OnlinesCursosService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe : DatePipe,
              private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.objPrivilegies = Sessions.getItem('privilegies');
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {
    this.sltCapacitacionTipo.setValue("");
    this.lstCapacitacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);
    }
  }
  
  btnFiltros_clickEvent() {
    
    var objData : any =
    {
      lstInstructores         : this.lstInstructores,
      lstCapacitacionesGrupos : this.lstCapacitacionesGrupos,
      lstCapacitacionesTipos  : this.lstCapacitacionesTipos,
      lstClosed               : this.lstClosed,
      lstExpiracion           : this.lstExpiracion,

      // FORM CONTROL
      txtDateDesde          : this.txtDateDesde.value,
      txtDateHasta          : this.txtDateHasta.value,
      sltInstructor         : this.sltInstructor.value,
      sltCapacitacionGrupo  : this.sltCapacitacionGrupo.value,
      sltCapacitacionTipo   : this.sltCapacitacionTipo.value,
      sltClosed             : this.sltClosed.value,
      sltExpiracion         : this.sltExpiracion.value,
    }

    var bottomSheet = this._bottomSheet.open(OnlineCursosIndexFiltrosComponent, {
      data : objData
    });

    bottomSheet.instance.onChange.subscribe((item: OnlineCursosIndexFiltrosComponent) => {

      this.txtDateDesde.setValue(item.txtDateDesde.value);
      this.txtDateHasta.setValue(item.txtDateHasta.value);

      this.sltInstructor        = item.sltInstructor;
      this.sltCapacitacionGrupo = item.sltCapacitacionGrupo;
      this.sltCapacitacionTipo  = item.sltCapacitacionTipo;
      this.sltClosed            = item.sltClosed;
      this.sltExpiracion        = item.sltExpiracion;

      this.sltFilter.toArray()[0].value = this.sltInstructor.value;
      this.sltFilter.toArray()[1].value = this.sltCapacitacionGrupo.value;
      this.sltFilter.toArray()[2].value = this.sltCapacitacionTipo.value;
      this.sltFilter.toArray()[3].value = this.sltClosed.value;
      this.sltFilter.toArray()[4].value = this.sltExpiracion.value;

      this.build();
      
    });
  }

  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(OnlineCursosEditComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '300px'
    });

    dialogRef.componentInstance.objData = element;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnUsuarios_clickEvent(element : any)
  {
    const dialogRef = this.dialog.open(OnlineCursosUsuariosComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1200px',
      maxHeight : '1200px'
    });

    dialogRef.componentInstance.objData = element;
  }

  btnCancel_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(OnlineCursosCancelarComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '300px'
    });

    dialogRef.componentInstance.objData = element;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnUndo_clickEvent(element : any) {

    var closed = !element.closed;
    var title = closed ? "Cerrar curso" : "Abrir curso";
    var message = closed ? "¿Cerrar curso <strong>" + element.capacitacionTipoName + "</strong>?" : "¿Abrir curso <strong>" + element.capacitacionTipoName + "</strong>?";
    
    this.objDialogs.Confirm(title, message, closed ? "Cerrar" : "Abrir", "Cancelar", ()=> {
      this.undo(element.idOnlineCurso, closed);
    }, null, true);

  }

  // METODOS
  private initialization() : void {

    this.objOnlinesCursosService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstInstructores          = objResponse.result.instructores;
          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  public build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : this.filters(),
      dateFrom            : Globals.validValue(this.txtDateDesde.value) ? this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd') : "",
      dateTo              : Globals.validValue(this.txtDateHasta.value) ? this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd') : "",
      dateOptions         : [{field : "FechaRegistro"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objOnlinesCursosService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstOnlinesCursos = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstOnlinesCursos);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private filters() {
    let lstFilters : Array<any> = [];

    if(Globals.validValue(this.sltFilter))
    {
      this.sltFilter.forEach(itemFilter => {

        if(Globals.validValue(itemFilter.value))
        {
          var filter : any = {
            field           : itemFilter.id,
            value           : itemFilter.value
          };

          lstFilters.push(filter);
        }

      })
    }

    return lstFilters;
  }

  public borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.txtDateDesde.setValue("");
    this.txtDateHasta.setValue("");
    this.sltInstructor.setValue("");
    this.sltCapacitacionGrupo.setValue("");
    this.sltCapacitacionTipo.setValue("");
    this.sltClosed.setValue("");
    this.sltExpiracion.setValue("");
    
    this.paginator.pageIndex  = 0;
    this.paginator.pageSize   = 15;
    this.lstCapacitacionesTipos = [];

    this.sltFilter.forEach(itemFilter => {
      itemFilter.value = "";
    });
    
    this.build();
  }

  private undo(idOnlineCurso : any, closed : boolean) : void {
    
    Wait.show(this.tableContainer, "Espere por favor...")

    let objPost : any =
    {
      idOnlineCurso : idOnlineCurso,
      closed        : closed
    };

    this.objOnlinesCursosService.undo(objPost)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.sltCapacitacionGrupo.disable();
    this.sltCapacitacionTipo.disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

    }, error => {

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
