import { AppComponent } from '../app.component';
import { LoginComponent } from '../login/login.component';
import { DashboardLayoutComponent } from '../dashboard/layout/dashboard-layout/dashboard-layout.component';
import { InicioComponent } from '../dashboard/contents/inicio/inicio.component';
// import { UsuariosCreateComponent } from '../dashboard/contents/usuarios/create/create.component';
// import { UsuariosIndexComponent } from '../dashboard/contents/usuarios/index/index.component';
import { RefreshComponent } from '../dashboard/contents/components/refresh/refresh.component';
import { SelectComponent } from '../components/select/select.component';
import { TextInputDateComponent } from '../components/text-input-date/text-input-date.component';
import { DashboardLayoutPasswordComponent } from '../dashboard/layout/dashboard-layout/components/password/password.component';
import { DashboardLayoutSesionComponent } from '../dashboard/layout/dashboard-layout/components/sesion/sesion.component';
import { CapacitacionesIndexComponent } from '../dashboard/contents/capacitaciones/index/index.component';
import { CapacitacionesCreateComponent } from '../dashboard/contents/capacitaciones/create/create.component';
import { CapacitacionesCreateClienteComponent } from '../dashboard/contents/capacitaciones/create/cliente/cliente.component';
import { CapacitacionesCreateParticipanteComponent } from '../dashboard/contents/capacitaciones/create/participante/participante.component';
import { CapacitacionesCreateContactoComponent } from '../dashboard/contents/capacitaciones/create/contacto/contacto.component';
import { CapacitacionesIndexCertificadosComponent } from '../dashboard/contents/capacitaciones/index/certificados/certificados.component';
import { CapacitacionesIndexEvaluacionComponent } from '../dashboard/contents/capacitaciones/index/evaluacion/evaluacion.component';
import { CapacitacionesIndexEvaluacionParticipanteComponent } from '../dashboard/contents/capacitaciones/index/evaluacion/participante/participante.component';
import { ClientesIndexParticipantesUploadFotoComponent } from '../dashboard/contents/clientes/index/participantes/upload-foto/upload-foto.component';
import { CapacitacionesTiposCreateComponent } from '../dashboard/contents/capacitacionesTipos/create/create.component';
import { CapacitacionesTiposIndexEditComponent } from '../dashboard/contents/capacitacionesTipos/index/edit/edit.component';
import { CapacitacionesTiposIndexComponent } from '../dashboard/contents/capacitacionesTipos/index/index.component';
import { InstructoresCreateComponent } from '../dashboard/contents/instructores/create/create.component';
import { InstructoresIndexComponent } from '../dashboard/contents/instructores/index/index.component';
import { InstructoresIndexEditComponent } from '../dashboard/contents/instructores/index/edit/edit.component';
import { InstructoresIndexUploadFirmaComponent } from '../dashboard/contents/instructores/index/upload-firma/upload-firma.component';
import { ClientesCreateComponent } from '../dashboard/contents/clientes/create/create.component';
import { ClientesIndexComponent } from '../dashboard/contents/clientes/index/index.component';
import { ClientesIndexContactosComponent } from '../dashboard/contents/clientes/index/contactos/contactos.component';
import { ClientesIndexContactosCreateComponent } from '../dashboard/contents/clientes/index/contactos/create/create.component';
import { ClientesIndexParticipantesComponent } from '../dashboard/contents/clientes/index/participantes/participantes.component';
import { ClientesIndexParticipantesCreateComponent } from '../dashboard/contents/clientes/index/participantes/create/create.component';
import { ClientesIndexEditComponent } from '../dashboard/contents/clientes/index/edit/edit.component';
import { CapacitacionesCertificadosComponent } from '../dashboard/contents/capacitaciones/certificados/certificados.component';
import { UsuariosCreateComponent } from '../dashboard/contents/usuarios/create/create.component';
import { UsuariosIndexComponent } from '../dashboard/contents/usuarios/index/index.component';
import { UsuariosIndexEditComponent } from '../dashboard/contents/usuarios/index/edit/edit.component';
import { UsuariosIndexPasswordComponent } from '../dashboard/contents/usuarios/index/password/password.component';
import { UsuariosIndexPrivilegiosComponent } from '../dashboard/contents/usuarios/index/privilegios/privilegios.component';
import { CapacitacionesIndexFiltrosComponent } from '../dashboard/contents/capacitaciones/index/filtros/filtros.component';
import { CapacitacionesCertificadosFiltrosComponent } from '../dashboard/contents/capacitaciones/certificados/filtros/filtros.component';
import { CapacitacionesTiposIndexFiltrosComponent } from '../dashboard/contents/capacitacionesTipos/index/filtros/filtros.component';
import { ClientesIndexFiltrosComponent } from '../dashboard/contents/clientes/index/filtros/filtros.component';
import { InstructoresIndexFiltrosComponent } from '../dashboard/contents/instructores/index/filtros/filtros.component';
import { CapacitacionesIndexReporteComponent } from '../dashboard/contents/capacitaciones/index/reporte/reporte.component';
import { CapacitacionesIndexEmailComponent } from '../dashboard/contents/capacitaciones/index/email/email.component';
import { ConfiguracionesGlobalComponent } from '../dashboard/contents/configuraciones/global/global.component';
import { CapacitacionesIndexFiltroParticipanteComponent } from '../dashboard/contents/capacitaciones/index/filtro-participante/filtro-participante.component';
import { CapacitacionesCertificadosEditarComponent } from '../dashboard/contents/capacitaciones/certificados/editar/editar.component';
import { CapacitacionesIndexEditComponent } from '../dashboard/contents/capacitaciones/index/edit/edit.component';
import { CapacitacionesTiposIndexModulosComponent } from '../dashboard/contents/capacitacionesTipos/index/modulos/modulos.component';
import { CapacitacionesTiposIndexModulosCreateComponent } from '../dashboard/contents/capacitacionesTipos/index/modulos/create/create.component';
import { CapacitacionesTiposIndexModulosEditComponent } from '../dashboard/contents/capacitacionesTipos/index/modulos/edit/edit.component';
import { CapacitacionesTiposIndexModulosPreguntasComponent } from '../dashboard/contents/capacitacionesTipos/index/modulos/preguntas/preguntas.component';
import { OnlineCursosIndexComponent } from '../dashboard/contents/onlineCursos/index/index.component';
import { OnlineCursosIndexFiltrosComponent } from '../dashboard/contents/onlineCursos/index/filtros/filtros.component';
import { OnlineCursosCreateComponent } from '../dashboard/contents/onlineCursos/create/create.component';
import { OnlineCursosCancelarComponent } from '../dashboard/contents/onlineCursos/cancelar/cancelar.component';
import { OnlineCursosEditComponent } from '../dashboard/contents/onlineCursos/edit/edit.component';
import { OnlineCursosUsuariosComponent } from '../dashboard/contents/onlineCursos/usuarios/usuarios.component';

export const ApplicationDeclarations: any[] = [
  AppComponent,
  LoginComponent,
  DashboardLayoutComponent,

  // COMPONENTS
  SelectComponent,
  TextInputDateComponent,

  // C
  CapacitacionesCertificadosComponent,
  CapacitacionesCertificadosEditarComponent,
  CapacitacionesCertificadosFiltrosComponent,
  CapacitacionesCreateComponent,
  CapacitacionesCreateClienteComponent,
  CapacitacionesCreateContactoComponent,
  CapacitacionesCreateParticipanteComponent,
  CapacitacionesIndexComponent,
  CapacitacionesIndexCertificadosComponent,
  CapacitacionesIndexEditComponent,
  CapacitacionesIndexEmailComponent,
  CapacitacionesIndexEvaluacionComponent,
  CapacitacionesIndexEvaluacionParticipanteComponent,
  CapacitacionesIndexFiltroParticipanteComponent,
  CapacitacionesIndexFiltrosComponent,
  CapacitacionesIndexReporteComponent,

  CapacitacionesTiposCreateComponent,
  CapacitacionesTiposIndexComponent,
  CapacitacionesTiposIndexEditComponent,
  CapacitacionesTiposIndexFiltrosComponent,
  CapacitacionesTiposIndexModulosComponent,
  CapacitacionesTiposIndexModulosCreateComponent,
  CapacitacionesTiposIndexModulosEditComponent,
  CapacitacionesTiposIndexModulosPreguntasComponent,
  
  ClientesCreateComponent,
  ClientesIndexComponent,
  ClientesIndexContactosComponent,
  ClientesIndexContactosCreateComponent,
  ClientesIndexEditComponent,
  ClientesIndexFiltrosComponent,
  ClientesIndexParticipantesComponent,
  ClientesIndexParticipantesCreateComponent,
  ClientesIndexParticipantesUploadFotoComponent,

  ConfiguracionesGlobalComponent,

  // D
  DashboardLayoutPasswordComponent,
  DashboardLayoutSesionComponent,
  
  // I
  InicioComponent,
  InstructoresCreateComponent,
  InstructoresIndexComponent,
  InstructoresIndexEditComponent,
  InstructoresIndexFiltrosComponent,
  InstructoresIndexUploadFirmaComponent,

  // O
  OnlineCursosCancelarComponent,
  OnlineCursosCreateComponent,
  OnlineCursosEditComponent,
  OnlineCursosIndexComponent,
  OnlineCursosIndexFiltrosComponent,
  OnlineCursosUsuariosComponent,

  // RELOAD
  RefreshComponent,

  // U
  UsuariosCreateComponent,
  UsuariosIndexComponent,
  UsuariosIndexEditComponent,
  UsuariosIndexPasswordComponent,
  UsuariosIndexPrivilegiosComponent
];