import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { MatTableDataSource, MatDialogRef } from '@angular/material';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Router } from '@angular/router';

@Component({
  selector: 'comp-capacitaciones-index-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.css']
})
export class CapacitacionesIndexCertificadosComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  
  private objDialogs : Dialogs = new Dialogs();

  public objData : any;

  columns: string[] = ['no', 'foto', 'documentos', 'participante', 'certificadoNumero', 'certificadoFecha', 'acreditado', 'evaluacionInicial', 'evaluacionFinal', 'asistencia', 'calificacion', 'observaciones' ];
  
  lstClientesParticipantes : Array<any> = [];
  dataSource : MatTableDataSource<any>;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexCertificadosComponent>, 
              private objCapacitacionesService : CapacitacionesService,
              private toastr: ToastrService,
              private objRouter : Router) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.showCertificados();
  }

  // EVENTOS
  close()
  {
    this.objDialogRef.close();
  }

  btnCertificado_clickEvent(element : any, boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/Certificado', element.idCapacitacion, element.idClienteParticipante, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnCredencial_clickEvent(element : any, isCarta : boolean) {
    var rootLink = isCarta ? "Credencial" : "CredencialF2";
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/' + rootLink, element.idCapacitacion, element.idClienteParticipante ]);
    window.open(url.toString(), '_blank');
  }

  btnDC3_clickEvent(element : any, boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3', element.idCapacitacion, element.idClienteParticipante, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnCertificadoZip_clickEvent(boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/CertificadoZip', this.objData.idCapacitacion, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnCertificadoPDF_clickEvent(boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/CertificadosOneDocument', this.objData.idCapacitacion, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnCredencialZip_clickEvent(isCarta : boolean) {
    var rootLink = isCarta ? "CredencialZip" : "CredencialOneDocumentF2";
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/' + rootLink, this.objData.idCapacitacion ]);
    window.open(url.toString(), '_blank');
  }

  btnDC3Zip_clickEvent(boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3Zip', this.objData.idCapacitacion, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  btnDC3PDF_clickEvent(boolFirma : boolean) {
    let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3OneDocument', this.objData.idCapacitacion, boolFirma ]);
    window.open(url.toString(), '_blank');
  }

  // METODOS
  private showCertificados() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    let objCapacitacion : any =
    {
      idCapacitacion : this.objData.idCapacitacion
    };

    this.objCapacitacionesService.showCertificados(objCapacitacion)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.lstClientesParticipantes = objResponse.result.clientesParticipantes;
          this.dataSource = new MatTableDataSource(this.lstClientesParticipantes);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
