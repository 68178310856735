import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatDialogRef, MatRadioChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { ModulosService } from 'src/app/services/Modulos/modulos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-capacitaciones-tipos-index-modulos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CapacitacionesTiposIndexModulosCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;
  @ViewChild('videoPlayer', {static: false})  videoPlayer!   : ElementRef;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  public boolGuardar : boolean = false;
  public objDialogs  : Dialogs = new Dialogs();
  public formGroup   : FormGroup;

  public titleDialog : string = "Nuevo módulo";

  public objData        : any = null;
  public isEdit         : boolean = false;
  public selectedVideo  : boolean = true;
  public orden          : number = 0;

  // VIDEO
  objVideo : CResource = new CResource();
  maxSizeInMB: number = 20; // Tamaño máximo en MB

  get hasLoadVideo() : boolean {
    return Globals.validStringValue(this.objVideo.base64);
  }

  // IMAGEN
  images : Array<CResource> = [];

  get hasLoadImage() : boolean {
    return this.images.length > 0 ? true : false;
  }

  constructor(private objDialogRef : MatDialogRef<CapacitacionesTiposIndexModulosCreateComponent>, 
              private objModulosService : ModulosService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'name'    : new FormControl("", [Validators.required]),
      'isVideo' : new FormControl(true),
    });

    if(this.isEdit) {
      this.titleDialog = "Editar módulo";
    }
  }

  ngAfterViewInit() {
    if(this.isEdit) {
      this.edit();
    }
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  sltVideo_changeEvent(event : MatRadioChange) {
    this.selectedVideo = event.value;
    this.images = [];
    this.objVideo = new CResource();
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    if(this.selectedVideo) {
      if(!this.hasLoadVideo) {
        this.toastr.warning('No ha cargado el video multimedia', 'Video', { timeOut: 2000 });
        return;
      }
    } else {
      if(this.images.length == 0) {
        this.toastr.warning('No ha cargado ninguna imágen multimedia', 'Imágen', { timeOut: 2000 });
        return;
      }
    }

    if(!this.isEdit) {
      this.store();
    } else {
      this.update();
    }
  }

  // PROCESO VIDEOS
  onFileVideoSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {

      event.target.value = "";

      if (file.size / 1024 / 1024 > this.maxSizeInMB) {
        this.toastr.warning(`El archivo ${file.name} es demasiado grande. El tamaño máximo permitido es ${this.maxSizeInMB}MB.`, 'Video', { timeOut: 2000 });
        return;
      }

      var mimeType = file.type;

      if (mimeType.match(/video\/mp4/) == null) {
        this.toastr.warning('Solo se admiten videos en formato MP4', 'Video', { timeOut: 2000 });
        return;
      }

      this.objVideo = new CResource();
      this.loadVideo(file);
      
    }
  }

  loadVideo(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      var base64 = reader.result as string;
      const mimeType = base64.split(';')[0].split(':')[1];
      const base64Data = base64.split(',')[1];

      this.objVideo.mimeType  = mimeType;
      this.objVideo.base64    = base64Data;
      this.objVideo.name      = file.name;
    };
    reader.readAsDataURL(file);
  }

  onMetadataLoaded(event: Event): void {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      const videoElement: HTMLVideoElement = this.videoPlayer.nativeElement;
      if (videoElement.readyState >= 2) {
        this.objVideo.duracion = Math.floor(videoElement.duration);
      }
    }
  }

  btnDeleteVideo_clickEvent() {
    this.objVideo = new CResource();
  }

  // PROCESO IMAGENES
  onFileImageSelected(event: Event): void {

    const input = event.target as HTMLInputElement;

    if(input.files) {
      
      for (let index = 0; index < input.files.length; index++) {
        const file = input.files[index];

        var mimeType = file.type;

        if (mimeType.match(/image\/*/) != null) {
          
          const reader = new FileReader();

          reader.onload = (e : any) => {
            const rs = e.target.result;
            const base64Data = rs.split(',')[1];
            this.images.push({ mimeType: mimeType, base64 : base64Data, name : file.name, duracion : 0, server: false, path: "" });
          }

          reader.readAsDataURL(file);  

        }

      }

      input.value = "";

    }

  }

  btnDeleteImage_clickEvent(index : number) {
    this.images.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {

    this.loading(true);

    var objPost : any =
    {
      name                  : this.formGroup.controls["name"].value,
      idCapacitacionTipo    : this.objData.idCapacitacionTipo,
      capacitacionTipoName  : this.objData.name,
      isVideo               : this.selectedVideo,
      resource              : this.selectedVideo ? this.objVideo : this.images,
      orden                 : this.orden
    };

    this.objModulosService.store(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogRef.close();
          this.onChange.emit(this);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private edit() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    let objPost : any =
    {
      idModulo : this.objData.idModulo
    }

    this.objModulosService.edit(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          var objResource   = objResponse.result.resource;
          var lstResources  : Array<any> = objResponse.result.resources;

          this.formGroup.controls["name"].setValue(objResponse.result.name);
          this.formGroup.controls["isVideo"].setValue(objResponse.result.isVideo);
          this.selectedVideo = objResponse.result.isVideo;
          
          
          if(this.selectedVideo) {
            this.objVideo.base64  = location.origin + objResource.path;
            this.objVideo.server  = objResource.server;
            this.objVideo.path    = objResource.path;
          } else {
            lstResources.forEach(item => {
              this.images.push({ mimeType: "", base64 : location.origin + item.path, name : "", duracion : 0, server: item.server, path: item.path });
            })
          }
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    var objPost : any =
    {
      idModulo  : this.objData.idModulo,
      name      : this.formGroup.controls["name"].value,
      isVideo   : this.selectedVideo,
      resource  : this.selectedVideo ? this.objVideo : this.images,
    };

    this.objModulosService.update(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.onChange.emit(this);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}

export class CResource
{
  public mimeType : string = "";
  public base64   : string = "";
  public name     : string = "";
  public duracion : number = 0;
  public server   : boolean = false;
  public path     : string = "";
}
