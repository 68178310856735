import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatButton, MatDialogRef, MatChipInputEvent, MatCheckboxChange } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'comp-capacitaciones-index-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class CapacitacionesIndexEmailComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  objCapacitacion : any;
  participante    : string = "";
  isFilter        : boolean = false;
  arrayFilter     : Array<any> = [];

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexEmailComponent>, 
              private objCapacitacionesService : CapacitacionesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'subject'             : new FormControl('', [Validators.required]),
      'body'                : new FormControl('', [Validators.required]),
      'hasCertificado'      : new FormControl(true),
      'hasCredencial'       : new FormControl(true),
      'hasDC3'              : new FormControl(true),
      'isOneDocument'       : new FormControl('1'),
      'isCredencialLetter'  : new FormControl('1')
    });

    
    if(this.isFilter) {
      this.formGroup.controls['subject'].setValue("Documentos Capacitación - " + this.participante);
      this.formGroup.controls['body'].setValue("Por medio del presente se le hace llegar los documentos de capacitación del participante: " + this.participante);
      this.formGroup.controls['hasCredencial'].setValue(false);
      this.formGroup.controls['hasCredencial'].disable();
      this.formGroup.controls['isCredencialLetter'].disable();
    } else {
      this.formGroup.controls['subject'].setValue("Documentos Capacitación - " + this.objCapacitacion.folio);
      this.formGroup.controls['body'].setValue("Por medio del presente se le hace llegar los documentos de capacitación del cliente: " + this.objCapacitacion.clienteRazonSocial);
    }

  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  chkCrendencial_changeEvent(event : MatCheckboxChange) {

    if(event.checked) {
      this.formGroup.controls['isCredencialLetter'].enable();
    } else {
      this.formGroup.controls['isCredencialLetter'].disable();
    }

  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.valid && this.lstEmails.length > 0)
    {
      this.store();
    } else {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
    }
  }

  // CHIPS EMAIL
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  lstEmails: any[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our email
    if ((value || '').trim()) {
      this.lstEmails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.lstEmails.indexOf(fruit);

    if (index >= 0) {
      this.lstEmails.splice(index, 1);
    }
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Enviando correo...");
      this.btnGuardar.disabled  = true;
      this.btnCerrar.disabled   = true;
      this.boolGuardar          = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled  = false;
      this.btnCerrar.disabled   = false;
      this.boolGuardar          = false;

    }
  }

  private store() : void {

    let objCapacitacionSend : any =
    {
      idCapacitacion      : this.isFilter ? 0 : this.objCapacitacion.idCapacitacion,
      subject             : this.formGroup.controls["subject"].value,
      body                : this.formGroup.controls["body"].value,
      lstEmails           : this.lstEmails,
      isOneDocument       : this.formGroup.controls["isOneDocument"].value == 1 ? true : false,
      hasCertificado      : this.formGroup.controls["hasCertificado"].value,
      hasCredencial       : this.formGroup.controls["hasCredencial"].value,
      hasDC3              : this.formGroup.controls["hasDC3"].value,
      isCredencialLetter  : this.formGroup.controls["isCredencialLetter"].value == 1 ? true : false,
      isFilter            : this.isFilter,
      arrayFilter         : this.arrayFilter
    };

    this.loading(true);

    this.objCapacitacionesService.sendEmail(objCapacitacionSend)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.objDialogRef.close();
            this.objDialogs.Success("Envío exitoso", "Correo enviado correctamente");
            
          } else {
            let txtSize = Globals.validStringValue(objResponse.result) ? objResponse.result : "";

            this.objDialogs.Alert(objResponse.title, objResponse.message + txtSize);
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
