import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from '../ui/dialogs/dialogs';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService, IUserLogin } from '../services/sessions/session.service';
import { Sessions } from '../libraries/sessions';
import { MatButton } from '@angular/material';
import { Wait } from '../ui/wait/Wait';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private frmLogin      : FormGroup;
  private objDialogs    : Dialogs = new Dialogs();
  private lblLogin      : string = "Iniciar sesión";
  private returnUrl     : string;
  private objExpiration : any;
  
  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('btnLogin', {static: false})       btnLogin      : MatButton;

  constructor(public objSessionService: SessionService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.objExpiration    = Sessions.getItem('expiration');

    this.frmLogin = new FormGroup({
      email       : new FormControl('', Validators.required),
      password    : new FormControl('', Validators.required)
    });

    this.frmLogin.setValue({
      email         : '',
      password      : '',
    });

    if(Sessions.validate())
    {
      if(this.hasExpiration()) {
        this.router.navigate(["/dashboard"])
      }else{
        Sessions.sessionDestroy();
      }
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  hasExpiration() : Boolean {
    
    let valid : Boolean = true;
    this.objExpiration    = Sessions.getItem('expiration');

    var date1 = new Date();
    var date2 = new Date(this.objExpiration);

    var diff = (date2.getTime() - date1.getTime()) / 1000;

    if(diff <= 0)
    {
      
      valid = false;

    } else {
      
      valid = true;
      
    }

    return valid;
  }

  btnIniciarSesion_onClick() {

    this.btnLogin.disabled = true;
    this.lblLogin = "Iniciando sesión...";
    let objUserLogin: IUserLogin = Object.assign({}, this.frmLogin.value);

    this.frmLogin.disable();
    
    this.objSessionService.login(objUserLogin).subscribe(
      objResponse => {
        
        if (Sessions.start(objResponse)) {

          this.router.navigate([this.returnUrl]);

        } else {

          this.toastr.warning(objResponse.result, "Sesión", { timeOut: 2000 });
          this.btnLogin.disabled = false;
          this.lblLogin = "Iniciar sesión";

        }

        this.frmLogin.enable();

      }, objError => {

        this.frmLogin.enable();

        this.toastr.error("Ocurrió un error de conexión con el servidor. Error no." + objError.status + ".", "Error de servidor", { timeOut: 200 });
        this.btnLogin.disabled = false;
        this.lblLogin = "Iniciar sesión";

      });
  }

}
