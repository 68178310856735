import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormGroup, FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-capacitaciones-create-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class CapacitacionesCreateClienteComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesCreateClienteComponent>, 
              private objClientesService : ClientesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'name'                : new FormControl(null, [Validators.required]),
      'isParticular'        : new FormControl(false),
      'razonSocial'         : new FormControl(null, [Validators.required]),
      'rfc'                 : new FormControl(null, [Validators.required, Validators.maxLength(13), Validators.minLength(12)]),
      'showFirmaLegal'      : new FormControl(false),
      'showFirmaTrabajador' : new FormControl(false)
    });
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  chkParticular_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls["razonSocial"].disable();
      this.formGroup.controls["rfc"].disable();
    } else {
      this.formGroup.controls["razonSocial"].enable();
      this.formGroup.controls["rfc"].enable();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {

    this.loading(true);

    let objClienteStore : any =
    {
      name                : this.formGroup.controls["name"].value,
      isParticular        : this.formGroup.controls["isParticular"].value,
      razonSocial         : this.formGroup.controls["razonSocial"].value,
      rfc                 : this.formGroup.controls["rfc"].value,
      showFirmaLegal      : this.formGroup.controls["showFirmaLegal"].value,
      showFirmaTrabajador : this.formGroup.controls["showFirmaTrabajador"].value
    };

    this.objClientesService.storeExpres(objClienteStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
