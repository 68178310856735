import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter, Inject } from '@angular/core';
import { MatSelect, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class ClientesIndexFiltrosComponent implements OnInit {

  @ViewChildren('sltFilter') sltFilter : QueryList<MatSelect>;

  @Output("onChange") onChange : EventEmitter<any> = new EventEmitter<any>();

  // DATES FILTER
  public txtDateDesde  = new FormControl();
  public txtDateHasta  = new FormControl();

  // FILTERS
  public sltIsParticular       = new FormControl();
  public sltCreatedAspNetUser  = new FormControl();
  public sltUpdatedAspNetUser  = new FormControl();

  public lstIsParticular       : Array<any> = [];
  public lstCreatedAspNetUser  : Array<any> = [];
  public lstUpdatedAspNetUser  : Array<any> = [];


  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.lstIsParticular      = this.data.lstIsParticular;
    this.lstCreatedAspNetUser = this.data.lstCreatedAspNetUser;
    this.lstUpdatedAspNetUser = this.data.lstUpdatedAspNetUser;

    this.txtDateDesde.setValue(this.data.txtDateDesde);
    this.txtDateHasta.setValue(this.data.txtDateHasta);
    this.sltIsParticular.setValue(this.data.sltIsParticular);
    this.sltCreatedAspNetUser.setValue(this.data.sltCreatedAspNetUser);
    this.sltUpdatedAspNetUser.setValue(this.data.sltUpdatedAspNetUser);
  }

  // METODOS
  private build() : void {
    this.onChange.emit(this);
  }

}
