import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatButton, MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';
import { InputPredictiveService } from 'src/app/services/components/inputPredictive.service';
import { saveAs } from 'file-saver';
import { CapacitacionesIndexEmailComponent } from '../email/email.component';

@Component({
  selector: 'comp-capacitaciones-index-filtro-participante',
  templateUrl: './filtro-participante.component.html',
  styleUrls: ['./filtro-participante.component.css']
})
export class CapacitacionesIndexFiltroParticipanteComponent implements OnInit {

  @ViewChild('tableContainer', {static:false})  tableContainer  : ElementRef;
  @ViewChild('btnCerrar', {static:false})       btnCerrar       : MatButton;

  private sltClienteParticipante  = new FormControl();
  private sltInstructor           = new FormControl();
  private sltCapacitacionGrupo    = new FormControl();
  private txtDateDesde            = new FormControl();
  private txtDateHasta            = new FormControl();

  private lstCapacitacionesGrupos   : Array<any> = [];
  private lstClientesParticipantes  : Array<any> = [];
  private lstInstructores           : Array<any> = [];
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();

  columns: string[] = ['index', 'opciones', 'capacitacionGrupo', 'capacitacionTipo', 'duracion', 'instructor', 'cliente', 'periodo', 'vigencia' ];
  
  lstDataSource : Array<any> = [];
  dataSource    : MatTableDataSource<any>;

  // PREDICTIVE CLIENTE
  private idClienteParticipante : number = 0;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexFiltroParticipanteComponent>, 
              private objCapacitacionesService : CapacitacionesService,
              private datePipe : DatePipe,
              private dialog : MatDialog,
              private objInputPredictiveService : InputPredictiveService,
              private toastr: ToastrService) 
              { }

  ngOnInit() {
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnEliminar_clickEvent(index : number) {
    this.lstDataSource.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.lstDataSource);

    console.log(this.lstDataSource);
  }

  btnSearch() {

    if(this.idClienteParticipante == 0) {
      this.toastr.warning('Seleccione un participante', 'Formulario', { timeOut: 2000 });
      this.sltClienteParticipante.setErrors({required: true});
      return;  
    }

    if(this.txtDateDesde.invalid) {
      this.toastr.warning('Ingrese la fecha de inicio', 'Formulario', { timeOut: 2000 });
      this.txtDateDesde.setErrors({required: true});
      return;  
    }

    if(this.txtDateHasta.invalid) {
      this.toastr.warning('Ingrese la fecha final', 'Formulario', { timeOut: 2000 });
      this.txtDateHasta.setErrors({required: true});
      return;  
    }

    this.idCapacitacionGrupo = Globals.validValue(this.sltCapacitacionGrupo.value) ? this.sltCapacitacionGrupo.value : 0;

    this.search();
  }

  btnEmail_clickEvent() {

    if(this.lstDataSource.length == 0)
    {
      this.toastr.warning("No hay registros en la lista", "Mensaje", { timeOut: 2000 });
      return;
    }

    const dialogRef = this.dialog.open(CapacitacionesIndexEmailComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '600px',
      maxHeight     : '700px',
      disableClose  : true
    });

    var arrayFilter = this.lstDataSource.map(x => x.certificadoNumero);

    dialogRef.componentInstance.isFilter      = true;
    dialogRef.componentInstance.participante  = this.sltClienteParticipante.value;
    dialogRef.componentInstance.arrayFilter   = arrayFilter;

  }

  // PREDICTIVE PARTICIPANTE
  txtParticipante_keyup(value : string, event : KeyboardEvent) {

    if(event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }

    var txtSearch     = value;
  
    this.lstClientesParticipantes = [];

    if(!Globals.validStringValue(txtSearch)) {
      return;
    }

    let lstSearchFilters : Array<any> = [];

    lstSearchFilters.push({field : 'Nombre' });
    lstSearchFilters.push({field : 'ApellidoPaterno' });
    lstSearchFilters.push({field : 'ApellidoMaterno' });
    lstSearchFilters.push({field : 'NombreCompleto' });
    lstSearchFilters.push({field : 'CURP' });
    lstSearchFilters.push({field : 'Puesto' });
    lstSearchFilters.push({field : 'Ocupacion.Name' });
    lstSearchFilters.push({field : 'OcupacionTipo.Name' });

    let varArgs  : any = {
      search            : txtSearch,
      searchFilters     : lstSearchFilters,
      filters           : {},
      columns           : {},
      dateFilters       : { dateStart : '2017-01-01', dateEnd : '2018-12-31'}
    };
    
    this.objInputPredictiveService.predictive("Clientes/Participantes/Predictive", varArgs)
    .subscribe( objResponse => {
      
      if(objResponse.session) {
      
        if(objResponse.action) {

          this.lstClientesParticipantes = [];

          var lstClientesParticipantes : Array<any> = objResponse.result.rows;

          if(lstClientesParticipantes.length > 0) {
            objResponse.result.rows.forEach(item => {
              this.lstClientesParticipantes.push({ idClienteParticipante : item.idClienteParticipante, nombreCompleto : item.nombreCompleto, foto : item.foto, curp : item.curp });
            });
          } else {
            this.lstClientesParticipantes.push({idClienteParticipante : 0, nombreCompleto : "No se encontraron resultados...", foto : ""});
          }

        } else {
          this.lstClientesParticipantes.push({idClienteParticipante : 0, nombreCompleto : objResponse.message, foto : ""});
        }
      } else {
        this.lstClientesParticipantes.push({idClienteParticipante : 0, nombreCompleto : objResponse.message, foto : ""});
      }
      
    }, error => {

      if( error.status == 401) {
        this.lstClientesParticipantes.push({idClienteParticipante : 0, nombreCompleto : "La sesión ha finalizado por inactividad.", foto : ""});
      } else {
        this.lstClientesParticipantes.push({idClienteParticipante : 0, nombreCompleto : "Ocurrio un error inesperado. Póngase en contacto con el administrador.", foto : ""});
      }

    });

  }

  selectedParticipante_selectedEvent(event : any) {

    this.lstClientesParticipantes = [];

    this.idClienteParticipante = event.option.id.idClienteParticipante;

  }

  txtParticipante_clickEvent() {
    this.lstClientesParticipantes = [];
    this.idClienteParticipante = 0;
    this.sltClienteParticipante.setValue("");
  }

  focusOutParticipante_function() {
    if(this.idClienteParticipante == 0)
    {
      this.sltClienteParticipante.setValue("");
    }
  }

  // ZIP
  btnCertificadoZip_clickEvent(boolFirma : boolean) {
    var url       = "Capacitaciones/ClientesParticipantes/File/CertificadoZip";
    var boolFirma = boolFirma;
    
    this.getFile(url, boolFirma, "Certificados.zip");
  }

  btnCredencialZip_clickEvent(isCarta : boolean) {
    var rootLink  = isCarta ? "CredencialZip" : "CredencialesOneDocument";
    var fileName  = isCarta ? "Credenciales.zip" : "Credenciales.pdf";
    var url       = "Capacitaciones/ClientesParticipantes/File/" + rootLink;
    var boolFirma = true;

    this.getFile(url, boolFirma, fileName);
  }

  btnDC3Zip_clickEvent(boolFirma : boolean) {
    var url       = "Capacitaciones/ClientesParticipantes/File/DC3Zip";
    var boolFirma = boolFirma;

    this.getFile(url, boolFirma, "DC3.zip");
  }

  // ONE DOCUMENT
  btnCertificadoPDF_clickEvent(boolFirma : boolean) {
    var url       = "Capacitaciones/ClientesParticipantes/File/CertificadosOneDocument";
    var boolFirma = boolFirma;

    this.getFile(url, boolFirma, "Certificados.pdf");
  }

  btnDC3PDF_clickEvent(boolFirma : boolean) {
    var url       = "Capacitaciones/ClientesParticipantes/File/DC3OneDocument";
    var boolFirma = boolFirma;

    this.getFile(url, boolFirma, "DC3.pdf");
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.tableContainer, "Espere por favor...");
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.tableContainer);
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private initialization() : void {

    this.objCapacitacionesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstInstructores          = objResponse.result.instructores;
          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private search() : void {

    this.loading(true);

    this.lstDataSource = [];

    let objCapacitacion : any =
    {
      idClienteParticipante : this.idClienteParticipante,
      idInstructor          : Globals.validValue(this.sltInstructor.value) ? this.sltInstructor.value : 0,
      idCapacitacionGrupo   : Globals.validValue(this.sltCapacitacionGrupo.value) ? this.sltCapacitacionGrupo.value : 0,
      dateFrom              : this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd'),
      dateTo                : this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd')
    };

    this.objCapacitacionesService.searchReporteParticipante(objCapacitacion)
    .subscribe( objResponse => {

      this.lstDataSource = [];
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstDataSource = objResponse.result;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.dataSource = new MatTableDataSource(this.lstDataSource);

      this.loading(false);

    }, error => {

      this.dataSource = new MatTableDataSource(this.lstDataSource);

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private idCapacitacionGrupo : number = 0;
  private getFile(url : string, boolFirma : boolean, fileName : string) : void {

    if(this.lstDataSource.length == 0)
    {
      this.toastr.warning("No hay registros en la lista", "Descarga", { timeOut: 2000 });
      return;
    }

    this.loading(true);

    let objCapacitacion_ClienteParticipante : any =
    {
      boolFirma             : boolFirma,
      idCapacitacionGrupo   : this.idCapacitacionGrupo,
      clientesParticipantes : this.lstDataSource
    };

    this.objCapacitacionesService.fileReporteFilterParticipante(objCapacitacion_ClienteParticipante, url)
    .subscribe( objResponse => {

      saveAs(objResponse, fileName);

      this.loading(false);

    }, error => {
      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Verifique los filtros de busqueda.", "Error en la descarga");
      }

    });
  }

}
