import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesContactosService } from 'src/app/services/clientesContactos/clientesContactos.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'comp-clientes-index-contactos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ClientesIndexContactosCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  private titleDialog : string = "Nuevo contacto";

  public selectedImage     : boolean = false;
  public imageChangedEvent : any = '';
  public croppedImage      : any = '';
  public extensionImage    : string = '.jpg';

  public idCliente  : number = 0;
  public objData    : any = null;
  public isEdit     : boolean = false;

  constructor(private objDialogRef : MatDialogRef<ClientesIndexContactosCreateComponent>, 
              private objClientesContactosService : ClientesContactosService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'nombre'          : new FormControl("", [Validators.required]),
      'apellidoPaterno' : new FormControl("", [Validators.required]),
      'apellidoMaterno' : new FormControl(""),
      'telefono'        : new FormControl(""),
      'celular'         : new FormControl(""),
      'email'           : new FormControl(""),
      'isRL'            : new FormControl(false),
      'isRT'            : new FormControl(false)
    });

    if(this.isEdit) {
      this.formGroup.controls["nombre"].setValue(this.objData.nombre);
      this.formGroup.controls["apellidoPaterno"].setValue(this.objData.apellidoPaterno);
      this.formGroup.controls["apellidoMaterno"].setValue(this.objData.apellidoMaterno);
      this.formGroup.controls["telefono"].setValue(this.objData.telefono);
      this.formGroup.controls["celular"].setValue(this.objData.celular);
      this.formGroup.controls["email"].setValue(this.objData.email);
      this.formGroup.controls["isRL"].setValue(this.objData.isRL);
      this.formGroup.controls["isRT"].setValue(this.objData.isRT);

      this.titleDialog = "Editar contacto";
    }
  }

  // EVENTOS
  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        
        event.target.value = "";
        this.toastr.warning('Solo se admiten imagenes', 'Firma', { timeOut: 2000 });
        this.objDialogs.WarningToast("Sólo se admiten imágenes");
        return;

      }

      var type : string = mimeType;

      this.imageChangedEvent  = event;
      this.extensionImage     = "." + type.substring(6)
      this.selectedImage      = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    if(!this.isEdit) {
      this.store();
    } else {
      this.update();
    }

  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  private store() : void {

    this.loading(true);

    // let objClienteContactoStore : any =
    // {
    //   idCliente         : this.idCliente,
    //   nombre            : this.formGroup.controls["nombre"].value,      
    //   apellidoPaterno   : this.formGroup.controls["apellidoPaterno"].value,
    //   apellidoMaterno   : this.formGroup.controls["apellidoMaterno"].value,
    //   telefono          : this.formGroup.controls["telefono"].value,
    //   celular           : this.formGroup.controls["celular"].value,
    //   email             : this.formGroup.controls["email"].value,
    //   isRL              : this.formGroup.controls["isRL"].value,
    //   isRT              : this.formGroup.controls["isRT"].value,
    // };

    var blobFoto = this.dataURItoBlob(this.croppedImage);

    let formContactoStore = new FormData();

    formContactoStore.append("idCliente",       this.idCliente.toString());
    formContactoStore.append("nombre",          this.formGroup.controls["nombre"].value);
    formContactoStore.append("apellidoPaterno", this.formGroup.controls["apellidoPaterno"].value);
    formContactoStore.append("apellidoMaterno", this.formGroup.controls["apellidoMaterno"].value);
    formContactoStore.append("telefono",        this.formGroup.controls["telefono"].value);
    formContactoStore.append("celular",         this.formGroup.controls["celular"].value);
    formContactoStore.append("email",           this.formGroup.controls["email"].value);
    formContactoStore.append("isRL",            this.formGroup.controls["isRL"].value);
    formContactoStore.append("isRT",            this.formGroup.controls["isRT"].value);
    formContactoStore.append("firma",           this.selectedImage ? blobFoto : "");
    formContactoStore.append("extension",       this.selectedImage ? this.extensionImage : "");

    this.objClientesContactosService.store(formContactoStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          // this.ngFormGroup.resetForm();
          // this.formGroup.controls["isRL"].setValue(false);
          // this.formGroup.controls["isRT"].setValue(false);

          this.objDialogRef.close();
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    // let objClienteContactoUpdate : any =
    // {
    //   idClienteContacto : this.objData.idClienteContacto,
    //   nombre            : this.formGroup.controls["nombre"].value,      
    //   apellidoPaterno   : this.formGroup.controls["apellidoPaterno"].value,
    //   apellidoMaterno   : this.formGroup.controls["apellidoMaterno"].value,
    //   telefono          : this.formGroup.controls["telefono"].value,
    //   celular           : this.formGroup.controls["celular"].value,
    //   email             : this.formGroup.controls["email"].value,
    //   isRL              : this.formGroup.controls["isRL"].value,
    //   isRT              : this.formGroup.controls["isRT"].value,
    // };

    var blobFoto = this.dataURItoBlob(this.croppedImage);

    let formContactoUpdate = new FormData();

    formContactoUpdate.append("idClienteContacto", this.objData.idClienteContacto);
    formContactoUpdate.append("nombre",            this.formGroup.controls["nombre"].value);
    formContactoUpdate.append("apellidoPaterno",   this.formGroup.controls["apellidoPaterno"].value);
    formContactoUpdate.append("apellidoMaterno",   this.formGroup.controls["apellidoMaterno"].value);
    formContactoUpdate.append("telefono",          this.formGroup.controls["telefono"].value);
    formContactoUpdate.append("celular",           this.formGroup.controls["celular"].value);
    formContactoUpdate.append("email",             this.formGroup.controls["email"].value);
    formContactoUpdate.append("isRL",              this.formGroup.controls["isRL"].value);
    formContactoUpdate.append("isRT",              this.formGroup.controls["isRT"].value);
    formContactoUpdate.append("firma",             this.selectedImage ? blobFoto : "");
    formContactoUpdate.append("extension",         this.selectedImage ? this.extensionImage : "");

    this.objClientesContactosService.update(formContactoUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
