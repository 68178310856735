import { Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatSelect, MatSelectChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';

@Component({
  selector: 'app-online-cursos-index-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class OnlineCursosIndexFiltrosComponent implements OnInit {

  @ViewChildren('sltFilter') sltFilter : QueryList<MatSelect>;

  @Output("onChange") onChange : EventEmitter<any> = new EventEmitter<any>();

  // DATES FILTER
  public txtDateDesde  = new FormControl();
  public txtDateHasta  = new FormControl();

  // FILTERS
  public sltInstructor        = new FormControl();
  public sltCapacitacionGrupo = new FormControl();
  public sltCapacitacionTipo  = new FormControl();
  public sltClosed            = new FormControl();
  public sltExpiracion        = new FormControl();

  public lstInstructores          : Array<any> = [];
  public lstCapacitacionesGrupos  : Array<any> = [];
  public lstCapacitacionesTipos   : Array<any> = [];
  public lstClosed                : Array<any> = [];
  public lstExpiracion            : Array<any> = [];


  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.lstInstructores          = this.data.lstInstructores;
    this.lstCapacitacionesGrupos  = this.data.lstCapacitacionesGrupos;
    this.lstCapacitacionesTipos   = this.data.lstCapacitacionesTipos;
    this.lstClosed                = this.data.lstClosed;
    this.lstExpiracion            = this.data.lstExpiracion;

    this.txtDateDesde.setValue(this.data.txtDateDesde);
    this.txtDateHasta.setValue(this.data.txtDateHasta);
    this.sltInstructor.setValue(this.data.sltInstructor);
    this.sltCapacitacionGrupo.setValue(this.data.sltCapacitacionGrupo);
    this.sltCapacitacionTipo.setValue(this.data.sltCapacitacionTipo);
    this.sltClosed.setValue(this.data.sltClosed);
    this.sltExpiracion.setValue(this.data.sltExpiracion);
  }

  // EVENTOS
  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {
    this.sltCapacitacionTipo.setValue("");
    this.lstCapacitacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);
    }
  }

  // METODOS
  public build() : void {
    this.onChange.emit(this);
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.sltCapacitacionGrupo.disable();
    this.sltCapacitacionTipo.disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

    }, error => {

      this.sltCapacitacionGrupo.enable();
      this.sltCapacitacionTipo.enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
