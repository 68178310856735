import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { MatVerticalStepper, MatSelectChange, MatHorizontalStepper, MatDialog } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { Globals } from 'src/app/libraries/globals';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { InputPredictiveService } from 'src/app/services/components/inputPredictive.service';
import { ClientesContactosService } from 'src/app/services/clientesContactos/clientesContactos.service';
import { CapacitacionesCreateClienteComponent } from './cliente/cliente.component';
import { CapacitacionesCreateContactoComponent } from './contacto/contacto.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ClientesParticipantesService } from 'src/app/services/clientesParticipantes/clientesParticipantes.service';
import { CapacitacionesCreateParticipanteComponent } from './participante/participante.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ClientesIndexParticipantesUploadFotoComponent } from '../../clientes/index/participantes/upload-foto/upload-foto.component';

@Component({
  selector: 'comp-capacitaciones-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CapacitacionesCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngFormGeneral', {static: false})  ngFormGeneral : FormGroupDirective;
  @ViewChild('stepper', {static: false})        stepper       : MatVerticalStepper;

  private objDialogs        : Dialogs = new Dialogs();
  private formGeneral       : FormGroup;

  private lstCapacitacionesGrupos       : Array<any> = [];
  private lstInstructores               : Array<any> = [];
  private lstClientes                   : Array<any> = [];
  private lstRepresentantesLegales      : Array<any> = [];
  private lstRepresentantesTrabajadores : Array<any> = [];
  private lstAreasTematicas             : Array<any> = [];
  private lstEstados                    : Array<any> = [];

  // PREDICTIVE CLIENTE
  private idCliente     : number = 0;

  // PARTICIPANTES
  private lstClientesParticipantes  : Array<any> = [];
  private lstParticipantes          : Array<any> = [];
  private txtParticipante           = new FormControl();
  private filteredOptions           : Observable<string[]>;

  // FILTERED CURSOS
  private idCapacitacionTipo          : number = 0;
  private lstCapacitacionesTipos      : Array<any> = [];
  private filteredCapacitacionesTipos : Observable<string[]>;

  showArea : boolean = false;
  isPerforacion : boolean = false;
  isProduccion : boolean = false;

  constructor(private objCapacitacionesService : CapacitacionesService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private objClientesContactosService : ClientesContactosService,
              private objClientesParticipantesService : ClientesParticipantesService,
              private objInputPredictiveService : InputPredictiveService,
              private toastr: ToastrService,
              private router: Router,
              private objDatePipe: DatePipe,
              private dialog : MatDialog) { }

  ngOnInit() {

    this.formGeneral = new FormGroup({
      'cliente'                 : new FormControl(null, [Validators.required]),
      'representanteLegal'      : new FormControl(null),
      'representanteTrabajador' : new FormControl(null),
      'instructor'              : new FormControl(null, [Validators.required]),
      'coordinador'             : new FormControl(null, [Validators.required]),
      'capacitacionGrupo'       : new FormControl(null, [Validators.required]),
      'capacitacionTipo'        : new FormControl(null, [Validators.required]),
      'periodoInicio'           : new FormControl(null, [Validators.required]),
      'periodoFin'              : new FormControl(null, [Validators.required]),
      'duracion'                : new FormControl(null, [Validators.required]),
      'vigencia'                : new FormControl(null, [Validators.required]),
      'ordenCompra'             : new FormControl(null, [Validators.required]),
      'areaTematica'            : new FormControl(null, [Validators.required]),
      'ciudad'                  : new FormControl(null, [Validators.required]),
      'estado'                  : new FormControl(null, [Validators.required]),
      'showLogoDC3'             : new FormControl(false)
    });

    this.formGeneral.controls["periodoInicio"].setValue(new Date());
    this.formGeneral.controls["ciudad"].setValue("Cárdenas");
    this.formGeneral.controls["estado"].setValue(27);

    this.updatedFilter();
    this.updatedFilterCapacitacionesTipos();

  }

  ngAfterViewInit() {
    this.create();
  }

  //EVENTOS
  btnCargarFotografia_clickEvent(element : any, event : any) {
    const dialogRef = this.dialog.open(ClientesIndexParticipantesUploadFotoComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '500px'
    });

    console.log(event);

    dialogRef.componentInstance.objData   = element;

    dialogRef.componentInstance.onChange.subscribe((item : ClientesIndexParticipantesUploadFotoComponent) => {
      event.target.src = item.croppedImage;
      this.getClientesParticipantes(this.idCliente);
    });
  }
  
  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {

    this.formGeneral.controls["duracion"].setValue("");
    this.formGeneral.controls["capacitacionTipo"].setValue("");
    this.lstCapacitacionesTipos = [];
    this.idCapacitacionTipo = 0;
    this.showArea = false;

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);

      if(event.value != 3)
      {
        this.showArea = true;
      }
    }

  }

  // sltCapacitacionTipo_changeEvent(event : MatSelectChange) {
    
  //   this.formGeneral.controls["duracion"].setValue("");

  //   if(Globals.validValue(event.value)) {
  //     var objCapacitacionTipo = this.lstCapacitacionesTipos.find(x => x.idCapacitacionTipo == event.value);
  //     this.formGeneral.controls["duracion"].setValue(objCapacitacionTipo.duracion);
  //   }

  // }

  autoAgregarParticipante_selectedEvent(event : any) {

    let hasValue = this.lstParticipantes.find(item => item.idClienteParticipante == event.option.id.idClienteParticipante);

    if(Globals.validValue(hasValue)) {
      this.toastr.warning("El participante ya ha sido agregado", "Lo sentimos", { timeOut: 2000 });
    } else {
      this.lstParticipantes.push(event.option.id);
    }

    this.txtParticipante.reset();
  }

  btnEliminarParticipante_clickEvent(index : number) {
    this.lstParticipantes.splice(index, 1);
  }

  btnNuevoCliente_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesCreateClienteComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '600px',
      maxHeight     : '400px',
    });

    dialogRef.componentInstance.onChange.subscribe(item => {
      this.idCliente = item.idCliente;
      this.formGeneral.controls["cliente"].setValue(item.name);
    });
  }

  btnNuevoClienteContacto_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesCreateContactoComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '600px',
      maxHeight     : '600px',
    });

    dialogRef.componentInstance.objData = {idCliente : this.idCliente};

    dialogRef.componentInstance.onChange.subscribe(item => {
      this.getClientesContactos(this.idCliente);
    });
  }

  btnNuevoClienteParticipante_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesCreateParticipanteComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '700px',
      maxHeight     : '400px',
    });

    dialogRef.componentInstance.objData = {idCliente : this.idCliente};

    dialogRef.componentInstance.onChange.subscribe(item => {

      var objClienteParticipante : any =
      {
        idClienteParticipante : item.idClienteParticipante,
        nombreCompleto        : item.nombreCompleto,
        curp                  : item.curp,
        puesto                : item.puesto,
        img                   : item.img
      };

      this.lstParticipantes.push(objClienteParticipante);

      this.getClientesParticipantes(this.idCliente);
    });
  }

  btnGuardar_clickEvent() {
    if(this.formGeneral.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGeneral.onSubmit(undefined);
      this.stepper.selectedIndex = 0;
      return;
    }

    if(this.lstParticipantes.length == 0) {
      this.toastr.warning('No se ha agregado ningún participante', 'Participantes', { timeOut: 2000 });
      this.stepper.selectedIndex = 1;
      return;
    }

    this.store();
  }

  // PREDICTIVE CLIENTE
  txtCliente_keyup(value : string, event : KeyboardEvent) {

    if(event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }

    var txtSearch     = value;
  
    this.lstClientes = [];

    if(!Globals.validStringValue(txtSearch)) {
      return;
    }

    let lstSearchFilters : Array<any> = [];

    lstSearchFilters.push({field : 'Name' });
    lstSearchFilters.push({field : 'RazonSocial' });
    lstSearchFilters.push({field : 'RFC' });
    lstSearchFilters.push({field : 'Telefono' });
    lstSearchFilters.push({field : 'Celular' });
    lstSearchFilters.push({field : 'Email' });
    lstSearchFilters.push({field : 'Direccion' });
    

    let varArgs  : any = {
      search            : txtSearch,
      searchFilters     : lstSearchFilters,
      filters           : {},
      columns           : {},
      dateFilters       : { dateStart : '2017-01-01', dateEnd : '2018-12-31'}
    };
    
    this.objInputPredictiveService.predictive("Clientes/Predictive", varArgs)
    .subscribe( objResponse => {
      
      if(objResponse.session) {
      
        if(objResponse.action) {

          this.lstClientes = [];

          var lstClientes : Array<any> = objResponse.result.rows;

          if(lstClientes.length > 0) {
            objResponse.result.rows.forEach(item => {
              this.lstClientes.push({ idCliente : item.idCliente, name : item.name });
            });
          } else {
            this.lstClientes.push({idCliente : 0, name : "No se encontraron resultados..."});
          }

        } else {
          this.lstClientes.push({idCliente : 0, name : objResponse.message});
        }
      } else {
        this.lstClientes.push({idCliente : 0, name : objResponse.message});
      }
      
    }, error => {

      if( error.status == 401) {
        this.lstClientes.push({idCliente : 0, name : "La sesión ha finalizado por inactividad."});
      } else {
        this.lstClientes.push({idCliente : 0, name : "Ocurrio un error inesperado. Póngase en contacto con el administrador."});
      }

    });

  }

  selectedCliente_selectedEvent(event : any) {

    this.formGeneral.controls["representanteLegal"].setValue("");
    this.formGeneral.controls["representanteTrabajador"].setValue("");
    this.txtParticipante.setValue("");

    this.lstRepresentantesLegales       = [];
    this.lstRepresentantesTrabajadores  = [];
    this.lstClientesParticipantes       = [];
    this.lstParticipantes               = [];

    this.idCliente = event.option.id.idCliente;

    this.getClientesContactos(this.idCliente);
    this.getClientesParticipantes(this.idCliente);

  }

  txtCliente_clickEvent() {
    this.lstClientes = [];
    this.idCliente = 0;
    this.formGeneral.controls["cliente"].setValue("");
    this.formGeneral.controls["representanteLegal"].setValue("");
    this.formGeneral.controls["representanteTrabajador"].setValue("");
    this.txtParticipante.setValue("");
    
    this.lstRepresentantesLegales       = [];
    this.lstRepresentantesTrabajadores  = [];
    this.lstClientesParticipantes       = [];
    this.lstParticipantes               = [];

    this.updatedFilter();
  }

  focusOutCliente_function() {
    if(this.idCliente == 0)
    {
      this.formGeneral.controls["cliente"].setValue("");
    }
  }

  // PREDICTIVE CAPACITACIONES TIPOS
  autoCapacitacionTipo_selectedEvent(event : any) {
    this.idCapacitacionTipo = event.option.id.idCapacitacionTipo;
    this.formGeneral.controls["duracion"].setValue(event.option.id.duracion);
  }

  txtCapacitacionTipo_clickEvent() {
    this.idCapacitacionTipo = 0;
    this.formGeneral.controls["capacitacionTipo"].setValue("");
    this.formGeneral.controls["duracion"].setValue("");

    this.updatedFilterCapacitacionesTipos();
  }

  focusOutCapacitacionTipo_function() {
    setTimeout(() => {
      if(this.idCapacitacionTipo == 0)
      {
        this.formGeneral.controls["capacitacionTipo"].setValue("");
        this.formGeneral.controls["duracion"].setValue("");
      }
    }, 200);
  }

  // METODOS
  private filter(value: string): string[] {
    const filterValue = Globals.validValue(value) ? value.toLowerCase() : "";

    return this.lstClientesParticipantes.filter(option => option.nombreCompleto.toLowerCase().includes(filterValue));
  }

  private filterCapacitacionesTipos(value: string): string[] {
    const filterValue = Globals.validValue(value) ? value.toLowerCase() : "";

    return this.lstCapacitacionesTipos.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private updatedFilter() : void {
    this.filteredOptions = this.txtParticipante.valueChanges
    .pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  private updatedFilterCapacitacionesTipos() : void {
    this.filteredCapacitacionesTipos = this.formGeneral.controls["capacitacionTipo"].valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterCapacitacionesTipos(value))
    );
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.formGeneral.controls["capacitacionGrupo"].disable();
    this.formGeneral.controls["capacitacionTipo"].disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.formGeneral.controls["capacitacionGrupo"].enable();
      this.formGeneral.controls["capacitacionTipo"].enable();

    }, error => {

      this.formGeneral.controls["capacitacionGrupo"].enable();
      this.formGeneral.controls["capacitacionTipo"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private getClientesContactos(idCliente : number) : void {
    
    this.formGeneral.controls["cliente"].disable();
    this.formGeneral.controls["representanteLegal"].disable();
    this.formGeneral.controls["representanteTrabajador"].disable();

    let objClienteContacto : any =
    {
      idCliente : idCliente
    };

    this.objClientesContactosService.listByIdCliente(objClienteContacto)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstRepresentantesLegales       = objResponse.result.representantesLegales;
          this.lstRepresentantesTrabajadores  = objResponse.result.representantesTrabajadores;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.formGeneral.controls["cliente"].enable();
      this.formGeneral.controls["representanteLegal"].enable();
      this.formGeneral.controls["representanteTrabajador"].enable();

    }, error => {

      this.formGeneral.controls["cliente"].enable();
      this.formGeneral.controls["representanteLegal"].enable();
      this.formGeneral.controls["representanteTrabajador"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private getClientesParticipantes(idCliente : number) : void {
    
    this.txtParticipante.disable()

    let objClienteParticipante : any =
    {
      idCliente : idCliente
    };

    this.objClientesParticipantesService.listByIdCliente(objClienteParticipante)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstClientesParticipantes = objResponse.result.clientesParticipantes;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.txtParticipante.enable()

    }, error => {

      this.txtParticipante.enable()

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private create() : void {
    
    Wait.show(this.mainContainer, "Espere por favor...");

    this.objCapacitacionesService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstAreasTematicas        = objResponse.result.areasTematicas;
          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;
          this.lstInstructores          = objResponse.result.instructores;
          this.lstEstados               = objResponse.result.estados;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    var arrAreas : Array<string> = [];

    if(this.formGeneral.controls["capacitacionGrupo"].value != 3)
    {
      if(this.isPerforacion) {
        arrAreas.push("Perforacion");
      }

      if(this.isProduccion) {
        arrAreas.push("Produccion");
      }
    }

    let objCapacitacionStore : any =
    {
      idCliente                 : this.idCliente,
      idRepresentanteLegal      : Globals.validValue(this.formGeneral.controls["representanteLegal"].value) ? this.formGeneral.controls["representanteLegal"].value : 0,
      idRepresentanteTrabajador : Globals.validValue(this.formGeneral.controls["representanteTrabajador"].value) ? this.formGeneral.controls["representanteTrabajador"].value : 0,
      idInstructor              : this.formGeneral.controls["instructor"].value,
      idCoordinador             : this.formGeneral.controls["coordinador"].value,
      idCapacitacionGrupo       : this.formGeneral.controls["capacitacionGrupo"].value,
      idCapacitacionTipo        : this.idCapacitacionTipo,
      periodoInicio             : this.objDatePipe.transform(this.formGeneral.controls["periodoInicio"].value, 'dd/MM/yyyy HH:mm'),
      periodoFin                : this.objDatePipe.transform(this.formGeneral.controls["periodoFin"].value, 'dd/MM/yyyy HH:mm'),
      vigencia                  : this.formGeneral.controls["vigencia"].value,
      duracion                  : this.formGeneral.controls["duracion"].value,
      ordenCompra               : this.formGeneral.controls["ordenCompra"].value,
      idAreaTematica            : this.formGeneral.controls["areaTematica"].value,
      ciudad                    : this.formGeneral.controls["ciudad"].value,
      idEstado                  : this.formGeneral.controls["estado"].value,
      participantes             : this.lstParticipantes,
      areas                     : arrAreas,
      showLogoDC3               : this.formGeneral.controls["showLogoDC3"].value
    };

    this.objCapacitacionesService.store(objCapacitacionStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "capacitaciones/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
