import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MatButton, MatDialogRef, MatTableDataSource, MatCheckboxChange, MatDialog, MatCheckbox } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';
import { Capacitaciones_ClientesParticipantesService } from 'src/app/services/capacitaciones_ClientesParticipantes/capacitaciones_ClientesParticipantes.service';
import { CapacitacionesIndexEvaluacionParticipanteComponent } from './participante/participante.component';
import { ClientesIndexParticipantesUploadFotoComponent } from '../../../clientes/index/participantes/upload-foto/upload-foto.component';

@Component({
  selector: 'comp-capacitaciones-index-evaluacion',
  templateUrl: './evaluacion.component.html',
  styleUrls: ['./evaluacion.component.css']
})
export class CapacitacionesIndexEvaluacionComponent implements OnInit {

  @ViewChild('mainContainer', {static:false})       mainContainer       : ElementRef;
  @ViewChild('btnGuardar', {static:false})          btnGuardar          : MatButton;
  @ViewChild('btnGuardarTemporal', {static:false})  btnGuardarTemporal  : MatButton;
  @ViewChild('btnCerrar', {static:false})           btnCerrar           : MatButton;
  @ViewChild('matChkAll', {static:false})           matChkAll           : MatCheckbox;
  @ViewChildren('trAcreditado')                     trAcreditado        : QueryList<MatCheckbox>;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar     : boolean = false;
  private objDialogs      : Dialogs = new Dialogs();
  private arrayAcreditado : Array<boolean> = [];
  private arrayValidate   : Array<any> = [];

  public objData : any;

  columns: string[] = ['no', 'foto', 'participante', 'acreditado', 'evaluacionInicial', 'evaluacionFinal', 'asistencia', 'calificacion', 'observaciones' ];
  
  lstClientesParticipantes : Array<any> = [];
  dataSource : MatTableDataSource<any>;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexEvaluacionComponent>, 
              private objCapacitacionesService : CapacitacionesService,
              private objCapacitaciones_ClientesParticipantes : Capacitaciones_ClientesParticipantesService,
              private toastr: ToastrService,
              private dialog : MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.showEvaluacion();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnNuevoParticipante_clickEvent() {
    const dialogRef = this.dialog.open(CapacitacionesIndexEvaluacionParticipanteComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '500px',
      maxHeight : '700px'
    });

    dialogRef.componentInstance.objData                             = this.objData;
    dialogRef.componentInstance.lstClientesParticipantesExistentes  = this.lstClientesParticipantes;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.showEvaluacion();
    });
  }

  chkAcreditado_changeEvent(event : MatCheckboxChange, index) {
    this.arrayAcreditado[index] = event.checked;
    this.isAllSelected();
  }

  chkSelectedAll_changeEvent(event : any) {
    let checkboxs = this.trAcreditado.toArray();

    checkboxs.forEach((item, index) => {
      item.checked = event.checked;
      this.arrayAcreditado[index] = item.checked;
      this.lstClientesParticipantes[index].isAcreditado = item.checked;
    });
  }

  btnEliminar_clickEvent(element : any) {
    this.objDialogs.Confirm("Eliminar", "¿Eliminar participante "+ element.nombreCompleto +"?", "Eliminar", "Cancelar", () => {
      this.delete(element.idClienteParticipante);
    });
  }

  btnCargarFotografia_clickEvent(element : any) {
    const dialogRef = this.dialog.open(ClientesIndexParticipantesUploadFotoComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '500px'
    });

    dialogRef.componentInstance.objData   = element;
    dialogRef.componentInstance.imageURL  = element.foto;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.showEvaluacion();
    });
  }

  btnGuardarTemporal_clickEvent()
  {
    this.storeEvaluacionTemporal();
  }

  btnGuardar_clickEvent()
  {
    if(!this.validateParticipante()) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      return;
    }

    this.objDialogs.Confirm("Guardar", "¿Finalizar evaluación de participantes?", "Finalizar", "Cancelar", () => {
      this.storeEvaluacion();
    });
    
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled          = true;
      this.btnGuardarTemporal.disabled  = true;
      this.btnCerrar.disabled           = true;
      this.boolGuardar                  = true;
      this.objDialogRef.disableClose    = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled          = false;
      this.btnGuardarTemporal.disabled  = false;
      this.btnCerrar.disabled           = false;
      this.boolGuardar                  = false;
      this.objDialogRef.disableClose    = false;

    }
  }

  private calcularCalificacion(index) : void {
    var evaluacionInicial : number = this.lstClientesParticipantes[index].evaluacionInicial;
    var evaluacionFinal   : number = this.lstClientesParticipantes[index].evaluacionFinal;
    var asistencia        : number = this.lstClientesParticipantes[index].asistencia;

    var total = evaluacionInicial + evaluacionFinal + asistencia;

    var value = total / 3;

    this.lstClientesParticipantes[index].calificacion = parseFloat(value.toString()).toFixed(2);
  }

  private isAllSelected() : void {

    let checkboxs = this.trAcreditado.toArray();

    let numSelected = 0;
    let numTotal    = checkboxs.filter(x => !x.disabled).length;

    checkboxs.forEach(item => {
      if(item.checked == true){
        numSelected++;
      }
    });

    if(numSelected > 0){
      if(numSelected == numTotal)
      {
        this.matChkAll.checked        = true;
        this.matChkAll.indeterminate  = false;
      }else{
        this.matChkAll.checked        = false;
        this.matChkAll.indeterminate  = true;
      }
    }else{
      this.matChkAll.checked        = false;
      this.matChkAll.indeterminate  = false;
    }

  }

  private removeValid(index, type : number) : void {

    var objValid = this.arrayValidate[index];

    switch (type) {
      case 1:
        objValid.valid1 = false;
        break;
      case 2:
        objValid.valid2 = false;
        break;
      case 3:
        objValid.valid3 = false;
        break;
      case 4:
        objValid.valid4 = false;
        break;
    }

  }

  private validateParticipante() : boolean {
    let valid : boolean = true;

    this.lstClientesParticipantes.forEach((item, index) => {
      if(item.isAcreditado) {

        var value1 = item.evaluacionInicial;
        var value2 = item.evaluacionFinal;
        var value3 = item.asistencia;
        var value4 = item.calificacion;

        if(value1 == null) {
          this.arrayValidate[index].valid1 = true;
          valid = false;
        } else {
          this.arrayValidate[index].valid1 = false;
        }

        if(value2 == null) {
          this.arrayValidate[index].valid2 = true;
          valid = false;
        } else {
          this.arrayValidate[index].valid2 = false;
        }

        if(value3 == null) {
          this.arrayValidate[index].valid3 = true;
          valid = false;
        } else {
          this.arrayValidate[index].valid3 = false;
        }

        if(value4 == null) {
          this.arrayValidate[index].valid4 = true;
          valid = false;
        } else {
          this.arrayValidate[index].valid4 = false;
        }

      }
    })

    return valid;
  }

  private delete(idClienteParticipante) : void {
    
    Wait.show(this.mainContainer, "Eliminando...")

    let objCapacitacion_ClienteParticipante : any =
    {
      idCapacitacion        : this.objData.idCapacitacion,
      idClienteParticipante : idClienteParticipante
    };

    this.objCapacitaciones_ClientesParticipantes.delete(objCapacitacion_ClienteParticipante)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.showEvaluacion();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private showEvaluacion() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    let objCapacitacion : any =
    {
      idCapacitacion : this.objData.idCapacitacion
    };

    this.objCapacitacionesService.showEvaluacion(objCapacitacion)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.lstClientesParticipantes = objResponse.result.clientesParticipantes;
          this.dataSource = new MatTableDataSource(this.lstClientesParticipantes);

          this.arrayAcreditado  = [];
          this.arrayValidate    = [];

          this.lstClientesParticipantes.forEach(item => {
            this.arrayAcreditado.push(item.isAcreditado);
            this.arrayValidate.push({valid1: false, valid2: false, valid3: false, valid4: false})
          });

          this.matChkAll.checked        = false;
          this.matChkAll.indeterminate  = false;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private storeEvaluacionTemporal() : void {

    this.loading(true);

    let objCapacitacion : any =
    {
      idCapacitacion  : this.objData.idCapacitacion,
      participantes   : this.lstClientesParticipantes
    };

    this.objCapacitacionesService.storeEvaluacionTemporal(objCapacitacion)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private storeEvaluacion() : void {

    this.loading(true);

    let objCapacitacion : any =
    {
      idCapacitacion  : this.objData.idCapacitacion,
      participantes   : this.lstClientesParticipantes
    };

    this.objCapacitacionesService.storeEvaluacion(objCapacitacion)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.onChange.emit(this);
            this.objDialogRef.close();
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
