import { Sessions } from '../../libraries/sessions';
import { IReturn } from '../../libraries/ireturn';
import { Injectable, Inject } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_BASE_HREF } from '@angular/common';

@Injectable()
export class InputPredictiveService {

  constructor(private http: HttpClient, @Inject(APP_BASE_HREF) private baseUrl: string) { }

  predictive(dataPath : string, varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/" + dataPath, varArgs, Sessions.header());
  }

}