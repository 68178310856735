import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  index(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/Index", null, Sessions.header());
  }

  dataSource(varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/DataSource", varArgs, Sessions.header());
  }

  create(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/Create", null, Sessions.header());
  }

  store(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/Store", objUsuario, Sessions.header());
  }

  update(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/Update", objUsuario, Sessions.header());
  }

  delete(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/Delete", objUsuario, Sessions.header());
  }

  updatePassword(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/UpdatePassword", objUsuario, Sessions.header());
  }

  createPrivilegio(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/CreatePrivilegio", objUsuario, Sessions.header());
  }

  updatePrivilegio(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/UpdatePrivilegio", objUsuario, Sessions.header());
  }

  activateUsuario(objUsuario : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Usuarios/ActivateUsuario", objUsuario, Sessions.header());
  }

}