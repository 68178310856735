import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { OnlinesCursosService } from 'src/app/services/onlinesCursos/onlinesCursos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-online-cursos-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class OnlineCursosEditComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  boolGuardar     : boolean = false;
  objDialogs      : Dialogs = new Dialogs();
  formGroup       : FormGroup;
  lstInstructores : Array<any> [];

  objData : any = null;

  constructor(private objDialogRef : MatDialogRef<OnlineCursosEditComponent>, 
              private objOnlinesCursosService : OnlinesCursosService,
              private objDatePipe: DatePipe,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'instructor'      : new FormControl("", [Validators.required]),
      'periodoInicio'   : new FormControl("", [Validators.required]),
      'periodoFin'      : new FormControl("", [Validators.required]),
      'fechaExpiracion' : new FormControl("", [Validators.required])
    });
  }

  ngAfterViewInit() {
    this.edit();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private edit() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    let objPost : any =
    {
      idOnlineCurso : this.objData.idOnlineCurso
    }

    this.objOnlinesCursosService.edit(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          var objOnlineCurso = objResponse.result.onlineCurso;
          
          this.formGroup.controls["instructor"].setValue(objOnlineCurso.idInstructor);
          this.formGroup.controls["periodoInicio"].setValue(objOnlineCurso.periodoInicio);
          this.formGroup.controls["periodoFin"].setValue(objOnlineCurso.periodoFin);
          this.formGroup.controls["fechaExpiracion"].setValue(objOnlineCurso.fechaExpiracion);

          this.lstInstructores = objResponse.result.instructores;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objPost : any =
    {
      idOnlineCurso   : this.objData.idOnlineCurso,
      idInstructor    : this.formGroup.controls["instructor"].value,
      periodoInicio   : this.objDatePipe.transform(this.formGroup.controls["periodoInicio"].value, 'dd/MM/yyyy HH:mm'),
      periodoFin      : this.objDatePipe.transform(this.formGroup.controls["periodoFin"].value, 'dd/MM/yyyy HH:mm'),
      fechaExpiracion : this.objDatePipe.transform(this.formGroup.controls["fechaExpiracion"].value, 'dd/MM/yyyy HH:mm')
    };

    this.objOnlinesCursosService.update(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          this.objDialogRef.close();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
