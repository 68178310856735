import { ElementRef } from '@angular/core';

declare var $ : any;

export class Wait {

  constructor() { }

  public static show( objElement : ElementRef, argText : string = "", bg : any = "rgba(255,255,255,0.7)", fnOnClose : any = null ) {
    
    $(objElement.nativeElement).waitMe({
      effect      : 'win8',
      text        : argText,
      bg          : bg,
      color       : ['#000','#0b609c'],
      maxSize     : '',
      waitTime    : -1,
      textPos     : 'vertical',
      fontSize    : '',
      source      : '',
      onClose     : fnOnClose
    });

  }


  public static hide( objElement : ElementRef ) {
    if( objElement.nativeElement != null ) {
      $(objElement.nativeElement).waitMe("hide");
    }
  }

}