import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Sessions } from 'src/app/libraries/sessions';
import { SessionService } from 'src/app/services/sessions/session.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DashboardLayoutPasswordComponent } from './components/password/password.component';
import { DashboardLayoutSesionComponent } from './components/sesion/sesion.component';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent implements OnInit {

  mobileQuery: MediaQueryList;
  initialLoad = true;
  isConnected = true;

  private _mobileQueryListener: () => void;

  private lstMenuCategoria  : any;
  private objUser           : any;
  private objExpiration     : any;
  private secondsExpiration : any;
  private styleCountDown    : any;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private objSessionService : SessionService, private router: Router, public dialog: MatDialog,
    private connectionService: ConnectionService) {
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.connectionHide();
      }
      else {
        this.initialLoad = false;
      }
    });
  }

  ngOnInit() {
    
    this.lstMenuCategoria = Sessions.getItem('menu');
    this.objUser          = Sessions.getItem('user');

    this.diff_minutes();

  }

  diff_minutes()
  {
    this.objExpiration    = Sessions.getItem('expiration');

    var date1 = new Date();
    var date2 = new Date(this.objExpiration);

    var diff = (date2.getTime() - date1.getTime()) / 1000;

    if(diff <= 0)
    {
      
      Sessions.sessionDestroy();
      this.secondsExpiration = 0;

    } else if(diff <= 600) {

      this.styleCountDown = "badge-danger flicker";
      this.secondsExpiration = diff;
      
      let txtContent = "La sesión está a punto de expirar, ingrese la contraseña para renovar o seleccione cancelar";
      this.popUpSession("Sesión próximo a vencer", txtContent, false);

    } else {
      
      this.secondsExpiration = diff;
      this.styleCountDown = "badge-danger";
      
    }
    // diff /= 60;
    // return Math.round(diff);
  }

  countdown_handleEvent(value : any)
  {
    if(value.action == "done") {

      Sessions.sessionDestroy();
      this.styleCountDown = "badge-danger flicker";

      this.dialog.closeAll();
      let txtContent = "La sesión ha expirado, para continuar navegando ingrese la contraseña para renovar o seleccione cancelar";
      this.popUpSession("Sesión expirada", txtContent, true);

    }
    
    if(value.action == "notify" && value.left == 600000) {

      this.styleCountDown = "badge-danger flicker";

      let txtContent = "La sesión está a punto de expirar, ingrese la contraseña para renovar o seleccione cancelar";
      this.popUpSession("Sesión próximo a vencer", txtContent, false);

    }

    // console.log(value)
  }

  // EVENTOS
  connectionHide() {
    setTimeout(() => {
      this.initialLoad = true;
    }, 8000);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  btnLogout_clickEvent() {
    this.objSessionService.logout().subscribe(
      objResponse => {
        Sessions.sessionDestroy();
        this.router.navigate(["/"])
      }, error => {
        Sessions.sessionDestroy();
        this.router.navigate(["/"])
      }
    )
  }

  btnModificarPassword_clickEvent() {
    this.dialog.open(DashboardLayoutPasswordComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '500px',
      maxHeight     : '320px'
    });
  }

  // METODOS
  private popUpSession(txtDialog : string, txtContent : string, expired : boolean, objUser : any = this.objUser) : void {
    var dialogRef = this.dialog.open(DashboardLayoutSesionComponent, {
      width         : '99%',
      height        : '99%',
      maxWidth      : '500px',
      maxHeight     : '300px',
      disableClose  : true
    });

    dialogRef.componentInstance.txtTitleDialog  = txtDialog;
    dialogRef.componentInstance.txtContent      = txtContent;
    dialogRef.componentInstance.expired         = expired;
    dialogRef.componentInstance.user            = objUser;

    dialogRef.componentInstance.refreshHandler.subscribe((data) => {
      this.diff_minutes();
    });
  }
  
}
