import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientesParticipantesService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  dataSource(varArgs : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/DataSource", varArgs, Sessions.header());
  }
  
  create(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/Create", null, Sessions.header());
  }

  store(objClienteParticipante : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/Store", objClienteParticipante, Sessions.headerFormData());
  }

  storeExpres(objClienteParticipante : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/StoreExpres", objClienteParticipante, Sessions.header());
  }

  update(objClienteParticipante : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/Update", objClienteParticipante, Sessions.header());
  }

  delete(objClienteParticipante : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/Delete", objClienteParticipante, Sessions.header());
  }

  listByIdCliente(objClienteParticipante : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/ListByIdCliente", objClienteParticipante, Sessions.header());
  }

  uploadFoto(objClienteParticipante : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Clientes/Participantes/UploadFoto", objClienteParticipante, Sessions.headerFormData());
  }

}