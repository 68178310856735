import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatButton, MatDialogRef, MatCheckboxChange, MatSelectChange } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesParticipantesService } from 'src/app/services/clientesParticipantes/clientesParticipantes.service';
import { OcupacionesTiposService } from 'src/app/services/ocupacionesTipos/ocupacionesTipos.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { Wait } from 'src/app/ui/wait/Wait';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'comp-clientes-index-participantes-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ClientesIndexParticipantesCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar         : boolean = false;
  private objDialogs          : Dialogs = new Dialogs();
  private formGroup           : FormGroup;
  private lstOcupaciones      : Array<any> = [];
  private lstOcupacionesTipos : Array<any> = [];

  private titleDialog : string = "Nuevo participante";

  public idCliente  : number = 0;
  public objData    : any = null;
  public isEdit     : boolean = false;

  private selectedImage     : boolean = false;
  private imageChangedEvent : any = '';
  private croppedImage      : any = '';
  private extensionImage    : string = '.jpg';
  private imageURL          : string = '/images/user.jpg';

  constructor(private objDialogRef : MatDialogRef<ClientesIndexParticipantesCreateComponent>, 
              private objClientesParticipantesService : ClientesParticipantesService,
              private objOcupacionesTiposService : OcupacionesTiposService,
              private toastr: ToastrService) { }

  ngOnInit() {

    this.formGroup = new FormGroup({
      'nombre'          : new FormControl(null, [Validators.required]),
      'apellidoPaterno' : new FormControl(null, [Validators.required]),
      'apellidoMaterno' : new FormControl(null),
      'curp'            : new FormControl(null, [Validators.required, Validators.maxLength(18), Validators.minLength(18)]),
      'hasCURP'         : new FormControl(false),
      'puesto'          : new FormControl(null, [Validators.required]),
      'hasPuesto'       : new FormControl(false),
      'ocupacion'       : new FormControl(null, [Validators.required]),
      'ocupacionTipo'   : new FormControl(null, [Validators.required])
    });

    if(this.isEdit) {
      this.formGroup.controls["nombre"].setValue(this.objData.nombre);
      this.formGroup.controls["apellidoPaterno"].setValue(this.objData.apellidoPaterno);
      this.formGroup.controls["apellidoMaterno"].setValue(this.objData.apellidoMaterno);
      this.formGroup.controls["curp"].setValue(this.objData.curp);
      this.formGroup.controls["hasCURP"].setValue(!this.objData.hasCURP);
      this.formGroup.controls["puesto"].setValue(this.objData.puesto);
      this.formGroup.controls["hasPuesto"].setValue(!this.objData.hasPuesto);
      this.formGroup.controls["ocupacion"].setValue(this.objData.idOcupacion);
      this.formGroup.controls["ocupacionTipo"].setValue(this.objData.idOcupacionTipo);

      if(!this.objData.hasCURP) {
        this.formGroup.controls["curp"].disable();
      } else {
        this.formGroup.controls["curp"].enable();
      }

      if(!this.objData.hasPuesto) {
        this.formGroup.controls["puesto"].disable();
      } else {
        this.formGroup.controls["puesto"].enable();
      }

      this.getOcupacionesTipos(this.objData.idOcupacion);

      this.titleDialog = "Editar participante";
    }

  }

  ngAfterViewInit() {
    this.create();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        
        event.target.value = "";
        this.toastr.warning('Solo se admiten imagenes', 'Fotografía', { timeOut: 2000 });
        this.objDialogs.WarningToast("Sólo se admiten imágenes");
        return;

      }

      var type : string = mimeType;

      this.imageChangedEvent  = event;
      this.extensionImage     = "." + type.substring(6);
      this.selectedImage      = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  chkHasCURP_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls["curp"].disable();
    } else {
      this.formGroup.controls["curp"].enable();
    }
  }

  chkHasPuesto_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls["puesto"].disable();
    } else {
      this.formGroup.controls["puesto"].enable();
    }
  }

  sltOcupacion_changeEvent(event : MatSelectChange) {
    this.formGroup.controls["ocupacionTipo"].setValue("");
    this.lstOcupacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getOcupacionesTipos(event.value);
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    if(!this.isEdit) {
      this.store();
    } else {
      this.update();
    }

  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private getOcupacionesTipos(idOcupacion : number) : void {
    
    this.formGroup.controls["ocupacion"].disable();
    this.formGroup.controls["ocupacionTipo"].disable();

    let objOcupacionTipo : any =
    {
      idOcupacion : idOcupacion
    };

    this.objOcupacionesTiposService.listByIdOcupacion(objOcupacionTipo)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstOcupacionesTipos = objResponse.result.ocupacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.formGroup.controls["ocupacion"].enable();
      this.formGroup.controls["ocupacionTipo"].enable();

    }, error => {

      this.formGroup.controls["ocupacion"].enable();
      this.formGroup.controls["ocupacionTipo"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  private create() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    this.objClientesParticipantesService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.lstOcupaciones = objResponse.result.ocupaciones;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private store() : void {

    this.loading(true);

    var blobFoto = this.dataURItoBlob(this.croppedImage);

    let formClienteParticipante = new FormData();

    var apellidoMaterno = Globals.validValue(this.formGroup.controls["apellidoMaterno"].value) ? this.formGroup.controls["apellidoMaterno"].value : "";

    formClienteParticipante.append("idCliente",       String(this.idCliente));
    formClienteParticipante.append("nombre",          this.formGroup.controls["nombre"].value);
    formClienteParticipante.append("apellidoPaterno", this.formGroup.controls["apellidoPaterno"].value);
    formClienteParticipante.append("apellidoMaterno", apellidoMaterno);
    formClienteParticipante.append("curp",            this.formGroup.controls["curp"].value);
    formClienteParticipante.append("hasCURP",         this.formGroup.controls["hasCURP"].value);
    formClienteParticipante.append("puesto",          this.formGroup.controls["puesto"].value);
    formClienteParticipante.append("hasPuesto",       this.formGroup.controls["hasPuesto"].value);
    formClienteParticipante.append("idOcupacion",     this.formGroup.controls["ocupacion"].value);
    formClienteParticipante.append("idOcupacionTipo", this.formGroup.controls["ocupacionTipo"].value);
    formClienteParticipante.append("foto",            this.selectedImage ? blobFoto : null);
    formClienteParticipante.append("extension",       this.extensionImage);

    this.objClientesParticipantesService.store(formClienteParticipante)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.objDialogRef.close();
            this.onChange.emit(objResponse.result);
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objClienteParticipanteUpdate : any =
    {
      idClienteParticipante : this.objData.idClienteParticipante,
      nombre                : this.formGroup.controls["nombre"].value,
      apellidoPaterno       : this.formGroup.controls["apellidoPaterno"].value,
      apellidoMaterno       : this.formGroup.controls["apellidoMaterno"].value,
      curp                  : this.formGroup.controls["curp"].value,
      hasCURP               : this.formGroup.controls["hasCURP"].value,
      puesto                : this.formGroup.controls["puesto"].value,
      hasPuesto             : this.formGroup.controls["hasPuesto"].value,
      idOcupacion           : this.formGroup.controls["ocupacion"].value,
      idOcupacionTipo       : this.formGroup.controls["ocupacionTipo"].value
    };

    this.objClientesParticipantesService.update(objClienteParticipanteUpdate)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.onChange.emit(objResponse.result);
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
