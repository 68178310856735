import { Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { AuthGuard } from '../auth/auth.guard';
import { DashboardLayoutComponent } from '../dashboard/layout/dashboard-layout/dashboard-layout.component';
import { InicioComponent } from '../dashboard/contents/inicio/inicio.component';
import { RefreshComponent } from '../dashboard/contents/components/refresh/refresh.component';
import { CapacitacionesIndexComponent } from '../dashboard/contents/capacitaciones/index/index.component';
import { CapacitacionesCreateComponent } from '../dashboard/contents/capacitaciones/create/create.component';
import { CapacitacionesTiposIndexComponent } from '../dashboard/contents/capacitacionesTipos/index/index.component';
import { CapacitacionesTiposCreateComponent } from '../dashboard/contents/capacitacionesTipos/create/create.component';
import { InstructoresIndexComponent } from '../dashboard/contents/instructores/index/index.component';
import { InstructoresCreateComponent } from '../dashboard/contents/instructores/create/create.component';
import { ClientesIndexComponent } from '../dashboard/contents/clientes/index/index.component';
import { ClientesCreateComponent } from '../dashboard/contents/clientes/create/create.component';
import { CapacitacionesCertificadosComponent } from '../dashboard/contents/capacitaciones/certificados/certificados.component';
import { UsuariosIndexComponent } from '../dashboard/contents/usuarios/index/index.component';
import { UsuariosCreateComponent } from '../dashboard/contents/usuarios/create/create.component';
import { ConfiguracionesGlobalComponent } from '../dashboard/contents/configuraciones/global/global.component';
import { OnlineCursosIndexComponent } from '../dashboard/contents/onlineCursos/index/index.component';
import { OnlineCursosCreateComponent } from '../dashboard/contents/onlineCursos/create/create.component';

export const ApplicationRoute: Routes = [

    { path: '',           component: LoginComponent },
    { path: 'login',      component: LoginComponent },

    { path: 'dashboard',  component: DashboardLayoutComponent,  canActivate: [AuthGuard], children: [
          { path: '',                                 component: InicioComponent, canActivate: [AuthGuard] },

          // CAPACITACIONES
          { path: 'capacitaciones',                   component: CapacitacionesIndexComponent,        canActivate: [AuthGuard] },
          { path: 'capacitaciones/create',            component: CapacitacionesCreateComponent,       canActivate: [AuthGuard] },
          { path: 'capacitaciones/certificados',      component: CapacitacionesCertificadosComponent, canActivate: [AuthGuard] },

          { path: 'capacitaciones/online',            component: OnlineCursosIndexComponent,          canActivate: [AuthGuard] },
          { path: 'capacitaciones/online/create',     component: OnlineCursosCreateComponent,         canActivate: [AuthGuard] },

          // CAPACITACIONES TIPOS
          { path: 'cursos',                           component: CapacitacionesTiposIndexComponent,   canActivate: [AuthGuard] },
          { path: 'cursos/create',                    component: CapacitacionesTiposCreateComponent,  canActivate: [AuthGuard] },

          // CLIENTES
          { path: 'clientes',                         component: ClientesIndexComponent,              canActivate: [AuthGuard] },
          { path: 'clientes/create',                  component: ClientesCreateComponent,             canActivate: [AuthGuard] },

          // CONFIGURACIONES
          { path: 'configuraciones/global',           component: ConfiguracionesGlobalComponent,      canActivate: [AuthGuard] },

          // INSTRUCTORES
          { path: 'instructores',                     component: InstructoresIndexComponent,          canActivate: [AuthGuard] },
          { path: 'instructores/create',              component: InstructoresCreateComponent,         canActivate: [AuthGuard] },
          
          // RELOAD
          { path: 'refresh/:url',                     component: RefreshComponent,                    canActivate: [AuthGuard]},

          // USUARIOS
          { path: 'usuarios',                         component: UsuariosIndexComponent,              canActivate: [AuthGuard] },
          { path: 'usuarios/create',                  component: UsuariosCreateComponent,             canActivate: [AuthGuard] },
          
          { path: '**',                               redirectTo: 'dashboard' },
    ]},

    { path: '**', redirectTo: '/', pathMatch : "full" }
  
  ];