import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatButton, MatTableDataSource, MatDialogRef, MatSelectChange } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CapacitacionesService } from 'src/app/services/capacitaciones/capacitaciones.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { saveAs } from 'file-saver';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Globals } from 'src/app/libraries/globals';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'comp-capacitaciones-index-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class CapacitacionesIndexReporteComponent implements OnInit {

  @ViewChild('tableContainer', {static:false})  tableContainer  : ElementRef;
  @ViewChild('btnGuardar', {static:false})      btnGuardar      : MatButton;
  @ViewChild('btnCerrar', {static:false})       btnCerrar       : MatButton;

  private sltCapacitacionGrupo  = new FormControl();
  private txtDateDesde          = new FormControl();
  private txtDateHasta          = new FormControl();

  private lstCapacitacionesGrupos : Array<any> = [];
  private lstCapacitacionesTipos  : Array<any> = [];
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();

  // FILTERED CURSOS
  private idCapacitacionTipo          : number = 0;
  private txtCapacitacionTipo         = new FormControl();
  private filteredCapacitacionesTipos : Observable<string[]>;

  columns: string[] = ['index', 'opciones', 'participante', 'puesto', 'curp', 'acreditado', 'certificadoNumero' ];
  
  lstClientesParticipantes  : Array<any> = [];
  dataSource                : MatTableDataSource<any>;

  // DATOS TEMPORALES
  private instructor        : string = "";
  private instructorSPPTR   : string = "";
  private coordinador       : string = "";
  private capacitacionTipo  : string = "";
  private duracion          : string = "";
  private dateDesde         : Date;
  private dateHasta         : Date;
  public area : string = "";
  public showArea : boolean = false;

  private lstCapacitacionesTiposSelected : Array<any> = [];

  constructor(private objDialogRef : MatDialogRef<CapacitacionesIndexReporteComponent>, 
              private objCapacitacionesService : CapacitacionesService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private datePipe : DatePipe,
              private toastr: ToastrService) 
              { }

  ngOnInit() {
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();

    this.updatedFilterCapacitacionesTipos();
  }

  ngAfterViewInit() {
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {

    this.txtCapacitacionTipo.setValue("");
    this.lstCapacitacionesTipos = [];
    this.idCapacitacionTipo = 0;
    this.showArea = false;
    this.area = "";

    this.lstCapacitacionesTiposSelected = [];

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);

      if(event.value != 3)
      {
        this.showArea = true;
      }
    }

  }

  btnEliminar_clickEvent(index : number) {
    this.lstClientesParticipantes.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.lstClientesParticipantes);
  }

  btnRemoveSelected_clickEvent(index : number) {
    this.lstCapacitacionesTiposSelected.splice(index, 1);
  }

  btnSearch() {
    if(this.sltCapacitacionGrupo.invalid) {
      this.toastr.warning('Seleccione el grupo de capacitación', 'Formulario', { timeOut: 2000 });
      this.sltCapacitacionGrupo.setErrors({required: true});
      return;  
    }

    if(this.txtCapacitacionTipo.invalid) {
      this.toastr.warning('Seleccione el curso', 'Formulario', { timeOut: 2000 });
      this.txtCapacitacionTipo.setErrors({required: true});
      return;  
    }

    if(this.txtDateDesde.invalid) {
      this.toastr.warning('Ingrese la fecha de inicio', 'Formulario', { timeOut: 2000 });
      this.txtDateDesde.setErrors({required: true});
      return;  
    }

    if(this.txtDateHasta.invalid) {
      this.toastr.warning('Ingrese la fecha final', 'Formulario', { timeOut: 2000 });
      this.txtDateHasta.setErrors({required: true});
      return;  
    }

    var duracion = this.lstCapacitacionesTipos.find(x => x.idCapacitacionTipo == this.idCapacitacionTipo).duracion;

    this.capacitacionTipo = this.txtCapacitacionTipo.value;
    this.duracion         = duracion;
    this.dateDesde        = this.txtDateDesde.value;
    this.dateHasta        = this.txtDateHasta.value;

    this.search();
  }

  btnGuardar_clickEvent()
  {

    if(this.lstClientesParticipantes.length == 0) {
      this.toastr.warning('No hay datos para generar reporte', 'Reporte', { timeOut: 2000 });
      return;
    }

    this.downloadReporte();
    
  }

  // PREDICTIVE CAPACITACIONES TIPOS
  autoCapacitacionTipo_selectedEvent(event : any) {
    this.idCapacitacionTipo = event.option.id.idCapacitacionTipo;

    var objExist = this.lstCapacitacionesTiposSelected.some(x => x.idCapacitacionTipo == this.idCapacitacionTipo);

    console.log(event.option.id)

    if(objExist)
      return;

    var obj = {
      idCapacitacionTipo  : this.idCapacitacionTipo,
      name                : event.option.value,
      hasTemario          : event.option.id.hasTemario
    }

    this.lstCapacitacionesTiposSelected.push(obj);
  }

  txtCapacitacionTipo_clickEvent() {
    this.idCapacitacionTipo = 0;
    this.txtCapacitacionTipo.setValue("");

    this.updatedFilterCapacitacionesTipos();
  }

  focusOutCapacitacionTipo_function() {
    setTimeout(() => {
      if(this.idCapacitacionTipo == 0)
      {
        this.txtCapacitacionTipo.setValue("");
      }
    }, 200);
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.tableContainer, "Espere por favor...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.tableContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private updatedFilterCapacitacionesTipos() : void {
    this.filteredCapacitacionesTipos = this.txtCapacitacionTipo.valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterCapacitacionesTipos(value))
    );
  }

  private filterCapacitacionesTipos(value: string): string[] {
    const filterValue = Globals.validValue(value) ? value.toLowerCase() : "";

    return this.lstCapacitacionesTipos.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private initialization() : void {

    this.objCapacitacionesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.sltCapacitacionGrupo.disable();
    this.txtCapacitacionTipo.disable();

    let objCapacitacionTipo : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objCapacitacionTipo)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.sltCapacitacionGrupo.enable();
      this.txtCapacitacionTipo.enable();

    }, error => {

      this.sltCapacitacionGrupo.enable();
      this.txtCapacitacionTipo.enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private downloadReporte() : void {

    this.loading(true);

    let objCapacitacion : any =
    {
      capacitacionTipo      : this.capacitacionTipo,
      duracion              : this.duracion,
      instructor            : this.instructor,
      instructorSPPTR       : this.instructorSPPTR,
      coordinador           : this.coordinador,
      dateFrom              : this.datePipe.transform(this.dateDesde, 'yyyy-MM-dd'),
      dateTo                : this.datePipe.transform(this.dateHasta, 'yyyy-MM-dd'),
      clientesParticipantes : this.lstClientesParticipantes
    };

    this.objCapacitacionesService.fileReporteFilter(objCapacitacion)
    .subscribe( objResponse => {

      saveAs(objResponse, "Reportes.zip");

      this.loading(false);

    }, error => {
      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private search() : void {

    this.loading(true);

    this.lstClientesParticipantes = [];

    let objCapacitacion : any =
    {
      idCapacitacionTipo  : this.idCapacitacionTipo,
      values              : this.lstCapacitacionesTiposSelected.map(x => { return x.idCapacitacionTipo; }),
      dateFrom            : this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd'),
      dateTo              : this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd'),
      area                : this.area
    };

    this.objCapacitacionesService.searchReporte(objCapacitacion)
    .subscribe( objResponse => {

      this.lstClientesParticipantes = [];
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.instructor               = objResponse.result.instructor;
          this.instructorSPPTR          = objResponse.result.instructorSPPTR;
          this.coordinador              = objResponse.result.coordinador;
          this.lstClientesParticipantes = objResponse.result.clientesParticipantes;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.dataSource = new MatTableDataSource(this.lstClientesParticipantes);

      this.loading(false);

    }, error => {

      this.dataSource = new MatTableDataSource(this.lstClientesParticipantes);

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
