import { Component, OnInit, ElementRef, ViewChild, Input, Renderer2, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormValidator } from '../../libraries/FormValidation/FormValidator';
import { IFormComponent } from '../../libraries/FormValidation/IFormComponent';

declare var $ : any;

@Component({
  selector: 'comp-text-input-date',
  templateUrl: './text-input-date.component.html',
  styleUrls: ['./text-input-date.component.css']
})
export class TextInputDateComponent implements OnInit, IFormComponent {

  static readonly ENABLED : string = "enabled";
  static readonly DISABLED : string = "disabled";
  static readonly READONLY : string = "readonly";

  static readonly EMPTY_HASFOCUS : string = "EMPTY_HASFOCUS";
  static readonly EMPTY_HASNOTFOCUS : string = "EMPTY_HASNOTFOCUS";
  static readonly FILLED_HASFOCUS : string = "FILLED_HASFOCUS";
  static readonly FILLED_HASNOTFOCUS : string = "FILLED_HASNOTFOCUS";

  private _estate : string;
  private _value : string;
  private _object : any;
  private _type : string;
  protected _firstFocus : boolean = false;
  protected _validator : FormValidator;

  private _cssStyle : any = { };

  @Input() label : string = "";
  @Input() required : boolean = false;
  @Input() field : string;
  @Input() fieldValue : string;
  @Input()
    set validator(argValidator :FormValidator) {
      this._validator = argValidator;
      this.required = true;
      this._validator.addFormComponent(this);
    }
    get  validator() : FormValidator {
      return this._validator;
    }

  @Input() 
    set value(argValue : string ) {
      this._firstFocus = true;
      this._value = argValue;
      this.setValue();
    }
    get value(): string {
      this.getValue();
      return this._value;
    }

  @Input() 
    set cssStyle(arrCssStyle : any ) {
      this._cssStyle = arrCssStyle;
    }
    get cssStyle(): any {
      return this._cssStyle;
    }

  @Input()
    set isoValue(argIsoValue : string ) {
      let strIsoValue = "";
      if( argIsoValue != null && argIsoValue.trim() != "" ) {
        let arrValue : Array<String> = argIsoValue.split('-');
        strIsoValue = arrValue[2] + "/" + arrValue[1] + "/" + arrValue[0];
      }

      this._value = strIsoValue;
      this.setValue();
    }
    get isoValue() : string {
      this.getValue();

      let strReturn : any = "";
      if( this._value.trim() != "" ) {
        let arrValue : Array<String> = this._value.split('/');
        strReturn = arrValue[2] + "-" + arrValue[1] + "-" + arrValue[0];
      }

      return strReturn;
    }

  @Input() 
    set estate(argEstate : string ) {
      this.estateUpdate(argEstate);
    }
    get estate(): string {
      return this._estate;
    }

  @Input() 
    set object(argObject : string ) {
      this._object = argObject;
    }
    get object(): string {
      return this._object;
    }
  
  @Input() 
    get length(): number {
      this.getValue();
      return this._value.length;
    }
    
  @Input() 
    set type(argType : string ) {
      this._type = argType;
      this.setType();
    }
    get type(): string {
      return this._type;
    }

  @ViewChild("compFormLine", {static: true}) compFormLine : ElementRef;
  @ViewChild("compFormControl", {static: true}) compFormControl : ElementRef;
  @ViewChild("compFormLabel", {static: true}) compFormLabel : ElementRef;


  constructor(protected renderer : Renderer2) { 
    this._estate = TextInputDateComponent.DISABLED;
    this._value = "";
    this.label = "";
    this._type = "text";
  }

  ngOnInit() {

    
    this.inicialization();
  }

  // DISPACHERS

  @Output() onFocusIn: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onFocusOut: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onChange: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onLazyChange: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onKeyUp: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onKeyDown: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onEnter: EventEmitter<any> = new EventEmitter(); // LISTO
  @Output() onEstateChange: EventEmitter<any> = new EventEmitter(); // LISTO
  


  // METHODS
  public disabled() : void {
    this.estateUpdate('disabled');
  }

  public isDisabled() : boolean {
    return (this.estate == 'enabled');
  }

  public enabled() : void {
    this.estateUpdate('enabled');
  }

  public isEnabled() : boolean {
    return (this.estate == 'enabled');
  }

  public isValid() : boolean {
    let objReturn : boolean = false;
    if( this._value.trim() != "" ) {
      let arrValue : Array<String> = this._value.split('/');

      objReturn = (this.validateInt(arrValue[0], 2) || this.validateInt(arrValue[0], 1))
                  && (this.validateInt(arrValue[1], 2) || this.validateInt(arrValue[1], 1))
                  && this.validateInt(arrValue[2], 4) ;
    }

    this.appearance();

    return objReturn;
  }

  private validateInt(argNumber : any, length : number) : boolean {

    let boolReturn : boolean = false;

    let testNumber : number = parseInt(argNumber);
    if( !isNaN(testNumber) && testNumber > 0 ) {
      let strNumber : string = testNumber.toString();
      boolReturn = strNumber.length == length;
    }

    return boolReturn;
  }

  public readonly() {
    this.estateUpdate('readonly');
  }

  public isReadonly() : boolean {
    return (this.estate == 'enabled');
  }

  public focus() : void {
    this.compFormControl.nativeElement.focus();
    this.appearance();
  }

  public clear() : void {
    this.compFormControl.nativeElement.value = "";
    this.appearance();
  }

  public isEmpty() :boolean {
    this.setValue();
    return ( this._value == "" );
  }

  public isEmptyTrim() : boolean {
    this.setValue();
    return ( this._value.trim() == "" );
  }

  


  // BEHAVIORS
  private styles() : string {
    let objReturn : string = "";
    return objReturn;
  }

  private getValue() : void {
    if(this._value != this.compFormControl.nativeElement.value) {      
      this._value = this.compFormControl.nativeElement.value;

      this.appearance();
      this.onChange.emit(this);
    }
  }

  private setValue() : void {
    if(this._value != this.compFormControl.nativeElement.value) {
      $(this.compFormControl.nativeElement).inputmask("setvalue", this._value); 

      this.appearance();
      this.onChange.emit(this);
    }
  }

  private setType() : void {
    this.compFormControl.nativeElement.type = this._type;
    this.appearance();
    this.onChange.emit(this);
  }

  private estateUpdate( strChange : string ) : void {
    if(strChange != null) {
      switch(strChange.toLowerCase()) {
        case 'enabled':
        case 'disabled':
        case 'readonly':
          this._estate = strChange.toLowerCase();
          this.appearance();
          this.onEstateChange.emit(this);
          break;
      }
    }
    
  }

  private focusedFilled() : string {

    let objReturn : string = "";
    let objFormControl = this.compFormControl.nativeElement;
    let hasFocus : boolean = (document.activeElement == objFormControl);

    if( objFormControl.value == "" && hasFocus ) {
      objReturn = TextInputDateComponent.EMPTY_HASFOCUS;
    } else if( objFormControl.value != "" && hasFocus ) {
      objReturn = TextInputDateComponent.FILLED_HASFOCUS;
    } else if( objFormControl.value == "" && !hasFocus ) {
      objReturn = TextInputDateComponent.EMPTY_HASNOTFOCUS;
    } else if( objFormControl.value != "" && !hasFocus ) {
      objReturn = TextInputDateComponent.FILLED_HASNOTFOCUS;
    }
    
    
    return objReturn;
  }

  private appearance() : void {
    let formLine = this.compFormLine.nativeElement;

    
    this.renderer.removeClass(formLine, 'focused');
    this.renderer.removeClass(formLine, 'readonly');
    this.renderer.removeClass(formLine, 'disabled');

    switch(this.focusedFilled()) {
      // [0, -]
      case TextInputDateComponent.EMPTY_HASFOCUS:
        this.renderer.addClass(formLine, 'focused');
      break;

      // [1, -]
      case TextInputDateComponent.FILLED_HASFOCUS:
        this.renderer.addClass(formLine, 'focused');
      break;

      // [2, -]
      case TextInputDateComponent.EMPTY_HASNOTFOCUS:
        if(this._firstFocus && this.required) {
          this.renderer.addClass(formLine, 'error');
          this.renderer.addClass(formLine, 'required');
        }
        
      break;

      // [3, -]
      case TextInputDateComponent.FILLED_HASNOTFOCUS:
        this.renderer.addClass(formLine, 'focused');
        this.renderer.removeClass(formLine, 'error');
        this.renderer.removeClass(formLine, 'required');
      break;
    }

    this.appearanceEstate();

    
  }

  private appearanceEstate() : void {
    let formControl = this.compFormControl.nativeElement;
    let formLine = this.compFormLine.nativeElement;

    switch(this._estate) {
      
      // [-, 0]
      case TextInputDateComponent.ENABLED:   
        formControl.disabled = false;
        formControl.readOnly = false;
      break;

      // [-, 1]
      case TextInputDateComponent.DISABLED:
        this.renderer.addClass(formLine, 'disabled');
        formControl.disabled = true;
        formControl.readOnly = false;
      break;

      // [-, 1]
      case TextInputDateComponent.READONLY:
        this.renderer.addClass(formLine, 'readonly');
        formControl.disabled = false;
        formControl.readOnly = true;
      break;
    }
  }


  inicialization() : void {
    
    $(this.compFormControl).inputmask('dd/mm/yyyy', { 
      placeholder     : '__/__/____',
      showMaskOnHover : false});
    
    this.appearance();
    
    //FOCUS IN
    this.renderer.listen(this.compFormControl.nativeElement, 'focusin', (evento) => {
      this._firstFocus = true;
      this.appearance();
      this.onFocusIn.emit(this);
    });

    //FOCUS OUT
    this.renderer.listen(this.compFormControl.nativeElement, 'focusout', (evento) => {
      this._firstFocus = true;
      this.appearance();
      this.onFocusOut.emit(this);
      this.onLazyChange.emit(this);
    });


    //CLICK
    this.renderer.listen(this.compFormLine.nativeElement, 'click', (evento) => {
      this.focus();
    });

    this.renderer.listen(this.compFormLabel.nativeElement, 'click', (evento) => {
      this.focus();
    });


    // CHANGE
    this.renderer.listen(this.compFormControl.nativeElement, 'keyup', (evento) => {
      this.onKeyDown.emit(evento);
    });

    this.renderer.listen(this.compFormControl.nativeElement, 'keyup', (evento) => {
      this.getValue();
      this.onKeyUp.emit(evento);

      if(evento.keyCode == 8 || evento.keyCode == 13 || evento.keyCode == 32) {
        this.onLazyChange.emit(evento);
      }

      if(evento.keyCode == 13) {
        this.onEnter.emit(evento);
      }

      this.appearance();
    });

  }







  // VALIDACIONES 

  public isRequired() : boolean {
    return this.required;
  }

  public componentValue() : any {
    return this.value;
  }

  public getField() : string {
    return this.field;
  }

  public getFieldValue() : string {
    return this.fieldValue;
  }

  public getObject() : any {
    return this.object;
  }

 

  
  

}