import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ConfiguracionesService } from 'src/app/services/configuraciones/configuraciones.service';
import { Wait } from 'src/app/ui/wait/Wait';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'content-configuraciones-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.css']
})
export class ConfiguracionesGlobalComponent implements OnInit {

  @ViewChild("mainContainer", {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  constructor(private objConfiguracionesService : ConfiguracionesService, private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'url'           : new FormControl('', [Validators.required]),
      'urlCliente'    : new FormControl('', [Validators.required]),
      'rootCliente'   : new FormControl('', [Validators.required]),
      'qr'            : new FormControl('', [Validators.required]),
      'correoActivo'  : new FormControl(false),
      'host'          : new FormControl('', [Validators.required]),
      'port'          : new FormControl('', [Validators.required]),
      'user'          : new FormControl('', [Validators.required]),
      'password'      : new FormControl('', [Validators.required]),
      'displayName'   : new FormControl('', [Validators.required])
    });
  }

  ngAfterViewInit() {
    this.show();
  }

  // METODOS
  btnGuardar_clickEvent() {

    if (this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  chkCorreoActivo_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls['host'].enable();
      this.formGroup.controls['port'].enable();
      this.formGroup.controls['user'].enable();
      this.formGroup.controls['password'].enable();
      this.formGroup.controls['displayName'].enable();
    } else {
      this.formGroup.controls['host'].disable();
      this.formGroup.controls['port'].disable();
      this.formGroup.controls['user'].disable();
      this.formGroup.controls['password'].disable();
      this.formGroup.controls['displayName'].disable();
    }
  }

  btnPredeterminado_clickEvent() {
    this.show();
  }

  // METODOS
  private show() : void {
    Wait.show(this.mainContainer, "Cargando información...", "rgba(255,255,255, 1)");

    this.objConfiguracionesService.showConfiguracionGlobal()
    .subscribe(objResponse => {

      if(objResponse.session){

        if(objResponse.action){

          Wait.hide(this.mainContainer);
          
          this.formGroup.controls['url'].setValue(objResponse.result.url);
          this.formGroup.controls['urlCliente'].setValue(objResponse.result.urlCliente);
          this.formGroup.controls['rootCliente'].setValue(objResponse.result.rootCliente);
          this.formGroup.controls['qr'].setValue(objResponse.result.qr);
          this.formGroup.controls['correoActivo'].setValue(objResponse.result.activo == "1" ? true : false);
          this.formGroup.controls['host'].setValue(objResponse.result.host);
          this.formGroup.controls['port'].setValue(objResponse.result.port);
          this.formGroup.controls['user'].setValue(objResponse.result.user);
          this.formGroup.controls['password'].setValue(objResponse.result.password);
          this.formGroup.controls['displayName'].setValue(objResponse.result.displayName);

          if(objResponse.result.activo == "1") {
            this.formGroup.controls['host'].enable();
            this.formGroup.controls['port'].enable();
            this.formGroup.controls['user'].enable();
            this.formGroup.controls['password'].enable();
            this.formGroup.controls['displayName'].enable();
          } else {
            this.formGroup.controls['host'].disable();
            this.formGroup.controls['port'].disable();
            this.formGroup.controls['user'].disable();
            this.formGroup.controls['password'].disable();
            this.formGroup.controls['displayName'].disable();
          }

        } else {
          Wait.hide(this.mainContainer);
          this.objDialogs.Alert(objResponse.title, objResponse.message);
        }

      }else{
        Wait.hide(this.mainContainer);
        this.objDialogs.Alert(objResponse.title, objResponse.message);
      }
      

    }, error => {
      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.objDialogs.Alert("Error sesión", "La sesión ha finalizado por inactividad.");
      } else {
        this.objDialogs.Alert("Error inesperado", "Ocurrio un error inesperado. Póngase en contacto con el administrador.");
      }

    });
  }

  private update() : void {
    Wait.show(this.mainContainer, "Guardando, espere por favor...");

    let formConfiguracion = new FormData();
    
    formConfiguracion.append("Url",         this.formGroup.value.url);
    formConfiguracion.append("UrlCliente",  this.formGroup.value.urlCliente);
    formConfiguracion.append("RootCliente", this.formGroup.value.rootCliente);
    formConfiguracion.append("Activo",      this.formGroup.value.correoActivo);
    formConfiguracion.append("Qr",          this.formGroup.value.qr);
    formConfiguracion.append("Host",        this.formGroup.value.host);
    formConfiguracion.append("Port",        this.formGroup.value.port);
    formConfiguracion.append("User",        this.formGroup.value.user);
    formConfiguracion.append("Password",    this.formGroup.value.password);
    formConfiguracion.append("DisplayName", this.formGroup.value.displayName);

    this.objConfiguracionesService.updateConfiguracionGlobal(formConfiguracion)
    .subscribe(objResponse => {

      if(objResponse.session){

        if(objResponse.action){

          Wait.hide(this.mainContainer);
          this.objDialogs.Success("Actualizado", "Configuración global actualizado")

        } else {
          Wait.hide(this.mainContainer);
          this.objDialogs.Alert(objResponse.title, objResponse.message);
        }

      }else{
        Wait.hide(this.mainContainer);
        this.objDialogs.Alert(objResponse.title, objResponse.message);
      }
      

    }, error => {
      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.objDialogs.Alert("Error sesión", "La sesión ha finalizado por inactividad.");
      } else {
        this.objDialogs.Alert("Error inesperado", "Ocurrio un error inesperado. Póngase en contacto con el administrador.");
      }

    });
  }

}
