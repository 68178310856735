import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Wait } from 'src/app/ui/wait/Wait';
import { MatCheckbox } from '@angular/material';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class UsuariosCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  private lstPrivilegiosCategorias : Array<any> = [];

  // PRIVILEGIOS
  @ViewChildren('chkPrivilegio') chkPrivilegio : QueryList<MatCheckbox>;

  constructor(private objUsuariosService : UsuariosService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'nombre'          : new FormControl(null, [Validators.required]),
      'apellidoPaterno' : new FormControl(null, [Validators.required]),
      'apellidoMaterno' : new FormControl(null),
      'usuario'         : new FormControl(null, [Validators.required]),
      'password'        : new FormControl(null, [Validators.required]),
      'passwordConfirm' : new FormControl(null, [Validators.required])
    }, { validators: this.passwordConfirming });

  }

  ngAfterViewInit() {
    this.create();
  }

  //EVENTOS
  btnGuardar_clickEvent() {
    
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    this.store();

  }

  // METODOS
  private passwordConfirming(c : AbstractControl) : { invalid : boolean } {
    if(c.get('password').value !== c.get('passwordConfirm').value) {
      return { invalid : true };
    }
  }

  private create() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    this.objUsuariosService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstPrivilegiosCategorias = objResponse.result.privilegiosCategorias;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }
  
  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let arrPrivilegios = this.chkPrivilegio.toArray().filter(x => x.checked).map(x => x.value);

    let objUsuarioStore : any =
    {
      nombre          : this.form.controls["nombre"].value,      
      apellidoPaterno : this.form.controls["apellidoPaterno"].value,
      apellidoMaterno : this.form.controls["apellidoMaterno"].value,
      usuario         : this.form.controls["usuario"].value,
      password        : this.form.controls["password"].value,
      privilegios     : arrPrivilegios
    };

    this.objUsuariosService.store(objUsuarioStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "usuarios/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
