import { SelectService } from "../services/components/select.service";
import { LoadingService } from "../services/loading/loading.service";
import { CapacitacionesService } from "../services/capacitaciones/capacitaciones.service";
import { InputPredictiveService } from "../services/components/inputPredictive.service";
import { CapacitacionesTiposService } from "../services/capacitacionesTipos/capacitacionesTipos.service";
import { ClientesService } from "../services/clientes/clientes.service";
import { ClientesContactosService } from "../services/clientesContactos/clientesContactos.service";
import { OcupacionesTiposService } from "../services/ocupacionesTipos/ocupacionesTipos.service";
import { ClientesParticipantesService } from "../services/clientesParticipantes/clientesParticipantes.service";
import { Capacitaciones_ClientesParticipantesService } from "../services/capacitaciones_ClientesParticipantes/capacitaciones_ClientesParticipantes.service";
import { InstructoresService } from "../services/instructores/instructores.service";
import { UsuariosService } from "../services/usuarios/usuarios.service";
import { ConfiguracionesService } from "../services/configuraciones/configuraciones.service";
import { ModulosService } from "../services/Modulos/modulos.service";
import { ModulosCuestionariosService } from "../services/ModulosCuestionarios/modulosCuestionarios.service";
import { OnlinesCursosService } from "../services/onlinesCursos/onlinesCursos.service";
import { OnlinesUsuariosService } from "../services/onlinesUsuarios/onlinesUsuarios.service";

export const ApplicationServices:any = [
    LoadingService,
    SelectService,

    // C
    CapacitacionesService,
    Capacitaciones_ClientesParticipantesService,
    CapacitacionesTiposService,
    ClientesService,
    ClientesContactosService,
    ClientesParticipantesService,
    ConfiguracionesService,

    // E

    // I
    InputPredictiveService,
    InstructoresService,

    // M
    ModulosService,
    ModulosCuestionariosService,

    // p

    // O
    OcupacionesTiposService,
    OnlinesCursosService,
    OnlinesUsuariosService,
    // S

    // U
    UsuariosService
    
]