import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { InstructoresService } from 'src/app/services/instructores/instructores.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Wait } from 'src/app/ui/wait/Wait';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-instructores-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class InstructoresCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  private selectedImage     : boolean = false;
  private imageChangedEvent : any = '';
  private croppedImage      : any = '';
  private extensionImage    : string = '.jpg';

  constructor(private objInstructoresService : InstructoresService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'name'            : new FormControl(null, [Validators.required]),
      'apellidoPaterno' : new FormControl(null, [Validators.required]),
      'apellidoMaterno' : new FormControl(null),
      'registro1'       : new FormControl(null, [Validators.required]),
      'registro2'       : new FormControl(null, [Validators.required]),
      'registro3'       : new FormControl(null, [Validators.required])
    });

  }

  //EVENTOS
  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        
        event.target.value = "";
        this.toastr.warning('Solo se admiten imagenes', 'Firma', { timeOut: 2000 });
        this.objDialogs.WarningToast("Sólo se admiten imágenes");
        return;

      }

      var type : string = mimeType;

      this.imageChangedEvent  = event;
      this.extensionImage     = "." + type.substring(6)
      this.selectedImage      = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  
  btnGuardar_clickEvent() {
    
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    if(!this.selectedImage) {
      this.toastr.warning('No se ha cargado la firma digital', 'Firma', { timeOut: 2000 });
      return;
    }

    this.store();

  }

  // METODOS
  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    var blobFoto = this.dataURItoBlob(this.croppedImage);

    let formInstructorStore = new FormData();

    var apellidoMaterno = Globals.validValue(this.form.controls["apellidoMaterno"].value) ? this.form.controls["apellidoMaterno"].value : "";

    formInstructorStore.append("name",            this.form.controls["name"].value);
    formInstructorStore.append("apellidoPaterno", this.form.controls["apellidoPaterno"].value);
    formInstructorStore.append("apellidoMaterno", apellidoMaterno);
    formInstructorStore.append("registro1",       this.form.controls["registro1"].value);
    formInstructorStore.append("registro2",       this.form.controls["registro2"].value);
    formInstructorStore.append("registro3",       this.form.controls["registro3"].value);
    formInstructorStore.append("firma",           blobFoto);
    formInstructorStore.append("extension",       this.extensionImage);

    this.objInstructoresService.store(formInstructorStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "instructores/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
