import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatButton, MatTableDataSource, MatDialogRef, MatDialog, MatPaginator } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesParticipantesService } from 'src/app/services/clientesParticipantes/clientesParticipantes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { ClientesIndexParticipantesCreateComponent } from './create/create.component';
import { ClientesIndexParticipantesUploadFotoComponent } from './upload-foto/upload-foto.component';
import { Globals } from 'src/app/libraries/globals';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'comp-clientes-index-participantes',
  templateUrl: './participantes.component.html',
  styleUrls: ['./participantes.component.css']
})
export class ClientesIndexParticipantesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild("tableContainer", {static: false}) tableContainer  : ElementRef;
  @ViewChild('btnCerrar', {static: false})      btnCerrar       : MatButton;

  private objDialogs  : Dialogs = new Dialogs();
  private boolGuardar : boolean = false;
  private index       : number;

  public objData : any = null;

  columns: string[] = ['index', 'opciones', 'nombreCompleto', 'foto', 'curp', 'puesto', 'ocupacionTipo', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];
  
  lstClientesParticipantes  : Array<any> = [];
  dataSource                : MatTableDataSource<any>;

  // SEARCH
  private txtSearch = new FormControl();

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Nombre'},
                                {field : 'ApellidoPaterno'},
                                {field : 'ApellidoMaterno'},
                                {field : 'NombreCompleto'},
                                {field : 'CURP'},
                                {field : 'Puesto'}];

  constructor(private objDialogRef: MatDialogRef<ClientesIndexParticipantesComponent>, 
              private dialog : MatDialog,
              private objClientesParticipantesService : ClientesParticipantesService, 
              private toastr: ToastrService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  close() {
    if(!this.boolGuardar) {
      this.objDialogRef.close();
    }
  }

  btnActualizar_clickEvent() {
    this.build();
  }

  btnNuevo_clickEvent() {

    const dialogRef = this.dialog.open(ClientesIndexParticipantesCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '700px',
      maxHeight : '650px'
    });

    dialogRef.componentInstance.idCliente = this.objData.idCliente;

    dialogRef.componentInstance.onChange.subscribe(() => {
      
      this.btnNuevo_clickEvent();

      this.build();
    });

  }

  btnEditar_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(ClientesIndexParticipantesCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '700px',
      maxHeight : '500px'
    });

    dialogRef.componentInstance.idCliente = this.objData.idCliente;
    dialogRef.componentInstance.objData   = element;
    dialogRef.componentInstance.isEdit    = true;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnCargarFotografia_clickEvent(element : any) {
    const dialogRef = this.dialog.open(ClientesIndexParticipantesUploadFotoComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '500px'
    });

    dialogRef.componentInstance.objData   = element;
    dialogRef.componentInstance.imageURL  = element.foto;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
  }

  btnDelete_clickEvent(element : any) {

    this.objDialogs.Confirm("Eliminar", "¿Eliminar al participante "+ element.nombreCompleto +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idClienteParticipante);
    });
    
  }

  // METODOS
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : [],
      dateFrom            : "",
      dateTo              : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 10 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1,
      idCliente           : this.objData.idCliente
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objClientesParticipantesService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstClientesParticipantes = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstClientesParticipantes);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private delete(idClienteParticipante : number) : void {
    
    Wait.show(this.tableContainer, "Eliminando...");
    this.objDialogRef.disableClose  = true;
    this.btnCerrar.disabled         = true;
    this.boolGuardar                = true;

    let objClienteParticipante : any =
    {
      idClienteParticipante : idClienteParticipante
    };

    this.objClientesParticipantesService.delete(objClienteParticipante)
    .subscribe( objResponse => {

      if(objResponse.session) {
        
        if(objResponse.action) {

          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);
      this.objDialogRef.disableClose  = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;

    }, error => {
      
      Wait.hide(this.tableContainer);
      this.objDialogRef.disableClose  = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
