import { Component, OnInit, ViewChildren, Output, QueryList, EventEmitter, Inject } from '@angular/core';
import { MatSelect, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class CapacitacionesTiposIndexFiltrosComponent implements OnInit {

  @ViewChildren('sltFilter') sltFilter : QueryList<MatSelect>;

  @Output("onChange") onChange : EventEmitter<any> = new EventEmitter<any>();

  // DATES FILTER
  public txtDateDesde  = new FormControl();
  public txtDateHasta  = new FormControl();

  // FILTERS
  public sltCapacitacionGrupo  = new FormControl();
  public sltHasTemario         = new FormControl();
  public sltCreatedAspNetUser  = new FormControl();
  public sltUpdatedAspNetUser  = new FormControl();

  public lstCapacitacionesGrupos : Array<any> = [];
  public lstHasTemarios          : Array<any> = [];
  public lstCreatedAspNetUser    : Array<any> = [];
  public lstUpdatedAspNetUser    : Array<any> = [];


  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.lstCapacitacionesGrupos  = this.data.lstCapacitacionesGrupos;
    this.lstHasTemarios           = this.data.lstHasTemarios;
    this.lstCreatedAspNetUser     = this.data.lstCreatedAspNetUser;
    this.lstUpdatedAspNetUser     = this.data.lstUpdatedAspNetUser;

    this.txtDateDesde.setValue(this.data.txtDateDesde);
    this.txtDateHasta.setValue(this.data.txtDateHasta);
    this.sltCapacitacionGrupo.setValue(this.data.sltCapacitacionGrupo);
    this.sltHasTemario.setValue(this.data.sltHasTemario);
    this.sltCreatedAspNetUser.setValue(this.data.sltCreatedAspNetUser);
    this.sltUpdatedAspNetUser.setValue(this.data.sltUpdatedAspNetUser);
  }

  // METODOS
  private build() : void {
    this.onChange.emit(this);
  }

}
