import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OnlinesUsuariosService } from 'src/app/services/onlinesUsuarios/onlinesUsuarios.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-online-cursos-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class OnlineCursosUsuariosComponent implements OnInit {

    @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  
    private objDialogs : Dialogs = new Dialogs();
  
    public objData : any;
  
    columns: string[] = ['no', 'acciones', 'estatus', 'porcentaje', 'nombreCompleto', 'curp', 'fechaInscripcion', 'fechaFinalizacion', 'calificacion' ];
    
    lstOnlinesUsuarios : Array<any> = [];
    dataSource : MatTableDataSource<any>;
  
    constructor(private objDialogRef : MatDialogRef<OnlineCursosUsuariosComponent>, 
                private objOnlinesUsuariosService : OnlinesUsuariosService,
                private objRouter : Router,
                private toastr: ToastrService) { }
  
    ngOnInit() {
      this.dataSource = new MatTableDataSource<any>();
    }
  
    ngAfterViewInit() {
      this.index();
    }
  
    // EVENTOS
    close()
    {
      this.objDialogRef.close();
    }

    btnRestart(element : any) {
        this.objDialogs.Confirm("Reiniciar", "¿Seguro que desea reinicar el progreso del usuario <strong>"+ element.nombreCompleto +"</strong>?", "Confirmar", "Cancelar", () => {
            this.restart(element.idOnlineUsuario);
        }, null, true);
    }

    btnRestartAll()
    {
        this.objDialogs.Confirm("Reiniciar", "¿Seguro que desea reinicar el progreso de todos los usuarios que han completado el curso?", "Confirmar", "Cancelar", () => {
            this.restartAll();
        }, null, true);
    }
  
    btnExamen(element : any) {
      let url = this.objRouter.createUrlTree(['/api/Onlines/Usuarios/Examen', element.idOnlineUsuario ]);
      window.open(url.toString(), '_blank');
    }
  
    // btnCredencial_clickEvent(element : any, isCarta : boolean) {
    //   var rootLink = isCarta ? "Credencial" : "CredencialF2";
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/' + rootLink, element.idCapacitacion, element.idClienteParticipante ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // btnDC3_clickEvent(element : any, boolFirma : boolean) {
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3', element.idCapacitacion, element.idClienteParticipante, boolFirma ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // btnCertificadoZip_clickEvent(boolFirma : boolean) {
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/CertificadoZip', this.objData.idCapacitacion, boolFirma ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // btnCertificadoPDF_clickEvent(boolFirma : boolean) {
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/CertificadosOneDocument', this.objData.idCapacitacion, boolFirma ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // btnCredencialZip_clickEvent(isCarta : boolean) {
    //   var rootLink = isCarta ? "CredencialZip" : "CredencialOneDocumentF2";
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/' + rootLink, this.objData.idCapacitacion ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // btnDC3Zip_clickEvent(boolFirma : boolean) {
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3Zip', this.objData.idCapacitacion, boolFirma ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // btnDC3PDF_clickEvent(boolFirma : boolean) {
    //   let url = this.objRouter.createUrlTree(['/api/Capacitaciones/ClientesParticipantes/File/DC3OneDocument', this.objData.idCapacitacion, boolFirma ]);
    //   window.open(url.toString(), '_blank');
    // }
  
    // METODOS
    public index() : void {
  
      Wait.show(this.mainContainer, "Espere por favor...");
  
      let objPost : any =
      {
        idOnlineCurso : this.objData.idOnlineCurso
      };
  
      this.objOnlinesUsuariosService.index(objPost)
      .subscribe( objResponse => {
        
        if(objResponse.session) {
  
          if(objResponse.action) {
            
            this.lstOnlinesUsuarios = objResponse.result;
            this.dataSource = new MatTableDataSource(this.lstOnlinesUsuarios);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
  
        Wait.hide(this.mainContainer);
  
      }, error => {
  
        Wait.hide(this.mainContainer);
  
        if( error.status == 401) {
          this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
        } else {
          this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
        }
  
      });
  
    }

    private restart(idOnlineUsuario : number) : void {
    
        Wait.show(this.mainContainer, "Reiniciando...")
    
        let objPost : any =
        {
          idOnlineUsuario : idOnlineUsuario
        };
    
        this.objOnlinesUsuariosService.restart(objPost)
        .subscribe( objResponse => {
          
          if(objResponse.session) {
    
            if(objResponse.action) {
              
              this.index();
              this.objDialogs.Success(objResponse.title, objResponse.message);
              
            } else {
              this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
            }
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
    
          Wait.hide(this.mainContainer);
    
        }, error => {
    
          Wait.hide(this.mainContainer);
    
          if( error.status == 401) {
            this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
          } else {
            this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
          }
    
        });
    }

    private restartAll() : void {
    
        Wait.show(this.mainContainer, "Reiniciando...")
    
        let objPost : any =
        {
          idOnlineCurso : this.objData.idOnlineCurso
        };
    
        this.objOnlinesUsuariosService.restartAll(objPost)
        .subscribe( objResponse => {
          
          if(objResponse.session) {
    
            if(objResponse.action) {
              
              this.index();
              this.objDialogs.Success(objResponse.title, objResponse.message);
              
            } else {
              this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
            }
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
    
          Wait.hide(this.mainContainer);
    
        }, error => {
    
          Wait.hide(this.mainContainer);
    
          if( error.status == 401) {
            this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
          } else {
            this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
          }
    
        });
    }
}
