import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Globals } from 'src/app/libraries/globals';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { InputPredictiveService } from 'src/app/services/components/inputPredictive.service';
import { OnlinesCursosService } from 'src/app/services/onlinesCursos/onlinesCursos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-online-cursos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class OnlineCursosCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  objDialogs  : Dialogs = new Dialogs();
  form        : FormGroup;

  lstCapacitacionesGrupos : Array<any> = [];
  lstClientes             : Array<any> = [];
  lstInstructores         : Array<any> = [];

  // PREDICTIVE CLIENTE
  idCliente : number = 0;

  // FILTERED CURSOS
  idCapacitacionTipo          : number = 0;
  lstCapacitacionesTipos      : Array<any> = [];
  filteredCapacitacionesTipos : Observable<string[]>;

  constructor(private objOnlinesCursosService : OnlinesCursosService,
              private objInputPredictiveService : InputPredictiveService,
              private objCapacitacionesTiposService : CapacitacionesTiposService,
              private toastr: ToastrService,
              private objDatePipe: DatePipe,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'cliente'           : new FormControl("", [Validators.required]),
      'instructor'        : new FormControl("", [Validators.required]),
      'capacitacionGrupo' : new FormControl("", [Validators.required]),
      'capacitacionTipo'  : new FormControl("", [Validators.required]),
      'periodoInicio'     : new FormControl("", [Validators.required]),
      'periodoFin'        : new FormControl("", [Validators.required]),
      'fechaExpiracion'   : new FormControl("", [Validators.required])
    });

    this.form.controls["periodoInicio"].setValue(new Date());

    this.updatedFilterCapacitacionesTipos();
  }

  ngAfterViewInit() {
    this.create();
  }

  //EVENTOS
  sltCapacitacionGrupo_changeEvent(event : MatSelectChange) {

    this.form.controls["capacitacionTipo"].setValue("");
    this.lstCapacitacionesTipos = [];
    this.idCapacitacionTipo = 0;

    if(Globals.validValue(event.value)) {
      this.getCapacitacionesTipos(event.value);
    }

  }
  
  btnGuardar_clickEvent() {
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    this.store();
  }

  // PREDICTIVE CLIENTE
  txtCliente_keyup(value : string, event : KeyboardEvent) {

    if(event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }

    var txtSearch     = value;
  
    this.lstClientes = [];

    if(!Globals.validStringValue(txtSearch)) {
      return;
    }

    let lstSearchFilters : Array<any> = [];

    lstSearchFilters.push({field : 'Name' });
    lstSearchFilters.push({field : 'RazonSocial' });
    lstSearchFilters.push({field : 'RFC' });
    lstSearchFilters.push({field : 'Telefono' });
    lstSearchFilters.push({field : 'Celular' });
    lstSearchFilters.push({field : 'Email' });
    lstSearchFilters.push({field : 'Direccion' });
    

    let varArgs  : any = {
      search            : txtSearch,
      searchFilters     : lstSearchFilters,
      filters           : {},
      columns           : {},
      dateFilters       : { dateStart : '2017-01-01', dateEnd : '2018-12-31'}
    };
    
    this.objInputPredictiveService.predictive("Clientes/Predictive", varArgs)
    .subscribe( objResponse => {
      
      if(objResponse.session) {
      
        if(objResponse.action) {

          this.lstClientes = [];

          var lstClientes : Array<any> = objResponse.result.rows;

          if(lstClientes.length > 0) {
            objResponse.result.rows.forEach(item => {
              this.lstClientes.push({ idCliente : item.idCliente, name : item.name });
            });
          } else {
            this.lstClientes.push({idCliente : 0, name : "No se encontraron resultados..."});
          }

        } else {
          this.lstClientes.push({idCliente : 0, name : objResponse.message});
        }
      } else {
        this.lstClientes.push({idCliente : 0, name : objResponse.message});
      }
      
    }, error => {

      if( error.status == 401) {
        this.lstClientes.push({idCliente : 0, name : "La sesión ha finalizado por inactividad."});
      } else {
        this.lstClientes.push({idCliente : 0, name : "Ocurrio un error inesperado. Póngase en contacto con el administrador."});
      }

    });

  }

  selectedCliente_selectedEvent(event : any) {
    this.idCliente = event.option.id.idCliente;
  }

  txtCliente_clickEvent() {
    this.lstClientes = [];
    this.idCliente = 0;
    this.form.controls["cliente"].setValue("");
  }

  focusOutCliente_function() {
    if(this.idCliente == 0)
    {
      this.form.controls["cliente"].setValue("");
    }
  }

  // PREDICTIVE CAPACITACIONES TIPOS
  autoCapacitacionTipo_selectedEvent(event : any) {
    this.idCapacitacionTipo = event.option.id.idCapacitacionTipo;
  }

  txtCapacitacionTipo_clickEvent() {
    this.idCapacitacionTipo = 0;
    this.form.controls["capacitacionTipo"].setValue("");

    this.updatedFilterCapacitacionesTipos();
  }

  focusOutCapacitacionTipo_function() {
    setTimeout(() => {
      if(this.idCapacitacionTipo == 0)
      {
        this.form.controls["capacitacionTipo"].setValue("");
      }
    }, 200);
  }

  updatedFilterCapacitacionesTipos() : void {
    this.filteredCapacitacionesTipos = this.form.controls["capacitacionTipo"].valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterCapacitacionesTipos(value))
    );
  }

  filterCapacitacionesTipos(value: string): string[] {
    const filterValue = Globals.validValue(value) ? value.toLowerCase() : "";

    return this.lstCapacitacionesTipos.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  // METODOS
  private getCapacitacionesTipos(idCapacitacionGrupo : number) : void {
    
    this.form.controls["capacitacionGrupo"].disable();
    this.form.controls["capacitacionTipo"].disable();

    let objPost : any =
    {
      idCapacitacionGrupo : idCapacitacionGrupo
    };

    this.objCapacitacionesTiposService.listByIdCapacitacionGrupo(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.capacitacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.form.controls["capacitacionGrupo"].enable();
      this.form.controls["capacitacionTipo"].enable();

    }, error => {

      this.form.controls["capacitacionGrupo"].enable();
      this.form.controls["capacitacionTipo"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private create() : void {
    
    Wait.show(this.mainContainer, "Espere por favor...");

    this.objOnlinesCursosService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;
          this.lstInstructores          = objResponse.result.instructores;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let objPost : any =
    {
      idCliente           : this.idCliente,
      idInstructor        : this.form.controls["instructor"].value,
      idCapacitacionGrupo : this.form.controls["capacitacionGrupo"].value,
      idCapacitacionTipo  : this.idCapacitacionTipo,
      periodoInicio       : this.objDatePipe.transform(this.form.controls["periodoInicio"].value, 'dd/MM/yyyy HH:mm'),
      periodoFin          : this.objDatePipe.transform(this.form.controls["periodoFin"].value, 'dd/MM/yyyy HH:mm'),
      fechaExpiracion     : this.objDatePipe.transform(this.form.controls["fechaExpiracion"].value, 'dd/MM/yyyy HH:mm')
    };

    this.objOnlinesCursosService.store(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "capacitaciones/online/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
