import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatSelect, MatTableDataSource, MatDialog, MatBottomSheet } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Sessions } from 'src/app/libraries/sessions';
import { Globals } from 'src/app/libraries/globals';
import { DatePipe } from '@angular/common';
import { Wait } from 'src/app/ui/wait/Wait';
import { CapacitacionesTiposService } from 'src/app/services/capacitacionesTipos/capacitacionesTipos.service';
import { CapacitacionesTiposIndexEditComponent } from './edit/edit.component';
import { CapacitacionesTiposIndexFiltrosComponent } from './filtros/filtros.component';
import { CapacitacionesTiposIndexModulosComponent } from './modulos/modulos.component';

@Component({
  selector: 'comp-cursos-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class CapacitacionesTiposIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;
  @ViewChildren('sltFilter')                    sltFilter       : QueryList<MatSelect>;

  private lstCapacitacionesTipos  : Array<any> = [];
  private objDialogs              : Dialogs = new Dialogs();
  private index                   : number;

  // SEARCH
  private txtSearch = new FormControl();

  // DATES FILTER
  private txtDateDesde  = new FormControl();
  private txtDateHasta  = new FormControl();

  // FILTERS
  private sltCapacitacionGrupo  = new FormControl();
  private sltHasTemario         = new FormControl();
  private sltCreatedAspNetUser  = new FormControl();
  private sltUpdatedAspNetUser  = new FormControl();

  private lstCapacitacionesGrupos : Array<any> = [];
  private lstHasTemarios          : Array<any> = [];
  private lstCreatedAspNetUser    : Array<any> = [];
  private lstUpdatedAspNetUser    : Array<any> = [];

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'capacitacionGrupo', 'name', 'duracion', 'hasTemario', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Name'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objCapacitacionesTiposService : CapacitacionesTiposService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe : DatePipe,
              private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {

    this.lstHasTemarios.push({ value: true, name: "Si" });
    this.lstHasTemarios.push({ value: false, name: "No" });

    this.objPrivilegies = Sessions.getItem('privilegies');
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnFiltros_clickEvent() {
    
    var objData : any =
    {
      lstCapacitacionesGrupos : this.lstCapacitacionesGrupos,
      lstHasTemarios          : this.lstHasTemarios,
      lstCreatedAspNetUser    : this.lstCreatedAspNetUser,
      lstUpdatedAspNetUser    : this.lstUpdatedAspNetUser,

      // FORM CONTROL
      txtDateDesde          : this.txtDateDesde.value,
      txtDateHasta          : this.txtDateHasta.value,
      sltCapacitacionGrupo  : this.sltCapacitacionGrupo.value,
      sltHasTemario         : this.sltHasTemario.value,
      sltCreatedAspNetUser  : this.sltCreatedAspNetUser.value,
      sltUpdatedAspNetUser  : this.sltUpdatedAspNetUser.value,
    }

    var bottomSheet = this._bottomSheet.open(CapacitacionesTiposIndexFiltrosComponent, {
      data : objData
    });

    bottomSheet.instance.onChange.subscribe((item: CapacitacionesTiposIndexFiltrosComponent) => {

      this.txtDateDesde.setValue(item.txtDateDesde.value);
      this.txtDateHasta.setValue(item.txtDateHasta.value);

      this.sltCapacitacionGrupo = item.sltCapacitacionGrupo;
      this.sltHasTemario        = item.sltHasTemario;
      this.sltCreatedAspNetUser = item.sltCreatedAspNetUser;
      this.sltUpdatedAspNetUser = item.sltUpdatedAspNetUser;

      this.sltFilter.toArray()[0].value = this.sltCapacitacionGrupo.value;
      this.sltFilter.toArray()[1].value = this.sltHasTemario.value;
      this.sltFilter.toArray()[2].value = this.sltCreatedAspNetUser.value;
      this.sltFilter.toArray()[3].value = this.sltUpdatedAspNetUser.value;

      this.build();
      
    });
  }

  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(CapacitacionesTiposIndexEditComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '800px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnModulos_clickEvent(element : any) {
    const dialogRef = this.dialog.open(CapacitacionesTiposIndexModulosComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '900px',
      maxHeight : '100%'
    });

    dialogRef.componentInstance.objData = element;
  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar curso "+ element.name +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idCapacitacionTipo);
    });

  }

  // METODOS
  private initialization() : void {

    this.objCapacitacionesTiposService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesGrupos  = objResponse.result.capacitacionesGrupos;
          this.lstCreatedAspNetUser     = objResponse.result.usuarios;
          this.lstUpdatedAspNetUser     = objResponse.result.usuarios;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }
  
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : this.filters(),
      dateFrom            : Globals.validValue(this.txtDateDesde.value) ? this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd') : "",
      dateTo              : Globals.validValue(this.txtDateHasta.value) ? this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd') : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objCapacitacionesTiposService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCapacitacionesTipos = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstCapacitacionesTipos);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private filters() {
    let lstFilters : Array<any> = [];

    if(Globals.validValue(this.sltFilter))
    {
      this.sltFilter.forEach(itemFilter => {

        if(Globals.validValue(itemFilter.value))
        {
          var filter : any = {
            field           : itemFilter.id,
            value           : itemFilter.value
          };

          lstFilters.push(filter);
        }

      })
    }

    return lstFilters;
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.txtDateDesde.setValue("");
    this.txtDateHasta.setValue("");
    this.sltCapacitacionGrupo.setValue("");
    this.sltHasTemario.setValue("");
    this.sltCreatedAspNetUser.setValue("");
    this.sltUpdatedAspNetUser.setValue("");
    
    this.paginator.pageIndex  = 0;
    this.paginator.pageSize   = 15;

    this.sltFilter.forEach(itemFilter => {
      itemFilter.value = "";
    });
    
    this.build();
  }

  private delete(idCapacitacionTipo : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...")

    let objCapacitacionTipoDelete : any =
    {
      idCapacitacionTipo : idCapacitacionTipo
    };

    this.objCapacitacionesTiposService.delete(objCapacitacionTipoDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
