import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatButton, MatDialogRef, MatSelectChange, MatCheckboxChange } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesParticipantesService } from 'src/app/services/clientesParticipantes/clientesParticipantes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';
import { OcupacionesTiposService } from 'src/app/services/ocupacionesTipos/ocupacionesTipos.service';

@Component({
  selector: 'comp-capacitaciones-create-participante',
  templateUrl: './participante.component.html',
  styleUrls: ['./participante.component.css']
})
export class CapacitacionesCreateParticipanteComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar         : boolean = false;
  private objDialogs          : Dialogs = new Dialogs();
  private formGroup           : FormGroup;
  private lstOcupaciones      : Array<any> = [];
  private lstOcupacionesTipos : Array<any> = [];

  public objData : any;

  constructor(private objDialogRef : MatDialogRef<CapacitacionesCreateParticipanteComponent>, 
              private objClientesParticipantesService : ClientesParticipantesService,
              private objOcupacionesTiposService : OcupacionesTiposService,
              private toastr: ToastrService) { }

  ngOnInit() {

    this.formGroup = new FormGroup({
      'nombre'          : new FormControl(null, [Validators.required]),
      'apellidoPaterno' : new FormControl(null, [Validators.required]),
      'apellidoMaterno' : new FormControl(null),
      'curp'            : new FormControl(null, [Validators.required, Validators.maxLength(18), Validators.minLength(18)]),
      'hasCURP'         : new FormControl(false),
      'puesto'          : new FormControl(null, [Validators.required]),
      'hasPuesto'       : new FormControl(false),
      'ocupacion'       : new FormControl(null, [Validators.required]),
      'ocupacionTipo'   : new FormControl(null, [Validators.required])
    });

  }

  ngAfterViewInit() {
    this.create();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  chkHasCURP_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls["curp"].disable();
    } else {
      this.formGroup.controls["curp"].enable();
    }
  }

  chkHasPuesto_changeEvent(event : MatCheckboxChange) {
    if(event.checked) {
      this.formGroup.controls["puesto"].disable();
    } else {
      this.formGroup.controls["puesto"].enable();
    }
  }

  sltOcupacion_changeEvent(event : MatSelectChange) {
    this.formGroup.controls["ocupacionTipo"].setValue("");
    this.lstOcupacionesTipos = [];

    if(Globals.validValue(event.value)) {
      this.getOcupacionesTipos(event.value);
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private getOcupacionesTipos(idOcupacion : number) : void {
    
    this.formGroup.controls["ocupacion"].disable();
    this.formGroup.controls["ocupacionTipo"].disable();

    let objOcupacionTipo : any =
    {
      idOcupacion : idOcupacion
    };

    this.objOcupacionesTiposService.listByIdOcupacion(objOcupacionTipo)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstOcupacionesTipos = objResponse.result.ocupacionesTipos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.formGroup.controls["ocupacion"].enable();
      this.formGroup.controls["ocupacionTipo"].enable();

    }, error => {

      this.formGroup.controls["ocupacion"].enable();
      this.formGroup.controls["ocupacionTipo"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private create() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    this.objClientesParticipantesService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.lstOcupaciones = objResponse.result.ocupaciones;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private store() : void {

    this.loading(true);

    let objClienteParticipanteStore : any =
    {
      idCliente       : this.objData.idCliente,
      nombre          : this.formGroup.controls["nombre"].value,
      apellidoPaterno : this.formGroup.controls["apellidoPaterno"].value,
      apellidoMaterno : this.formGroup.controls["apellidoMaterno"].value,
      curp            : this.formGroup.controls["curp"].value,
      hasCURP         : this.formGroup.controls["hasCURP"].value,
      puesto          : this.formGroup.controls["puesto"].value,
      hasPuesto       : this.formGroup.controls["hasPuesto"].value,
      idOcupacion     : this.formGroup.controls["ocupacion"].value,
      idOcupacionTipo : this.formGroup.controls["ocupacionTipo"].value
    };

    this.objClientesParticipantesService.storeExpres(objClienteParticipanteStore)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.ngFormGroup.resetForm();
            this.formGroup.controls["hasCURP"].setValue(false);
            this.formGroup.controls["hasPuesto"].setValue(false);
            this.formGroup.controls["curp"].enable();
            this.formGroup.controls["puesto"].enable();
            this.onChange.emit(objResponse.result);
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
