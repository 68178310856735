import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { MatButton, MatDialogRef, MatCheckbox } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-usuarios-index-privilegios',
  templateUrl: './privilegios.component.html',
  styleUrls: ['./privilegios.component.css']
})
export class UsuariosIndexPrivilegiosComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();

  public objData : any = null;

  // PRIVILEGIOS
  private lstPrivilegiosCategorias : Array<any> = [];
  @ViewChildren('chkPrivilegio') chkPrivilegio : QueryList<MatCheckbox>;

  constructor(private objDialogRef : MatDialogRef<UsuariosIndexPrivilegiosComponent>, 
              private objUsuariosService : UsuariosService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.create();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private create() : void {

    // Wait.show(this.mainContainer, "Espere por favor...");
    // this.objDialogRef.disableClose  = true;
    // this.btnGuardar.disabled        = true;
    // this.btnCerrar.disabled         = true;
    // this.boolGuardar                = true;
    
    let objUsuario : any =
    {
      id : this.objData.id
    };

    this.objUsuariosService.createPrivilegio(objUsuario)
    .subscribe(objResponse => {
      if(objResponse.session){
        if(objResponse.action){

          this.lstPrivilegiosCategorias = objResponse.result.privilegiosCategorias;

        }else{
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      }else{
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      // Wait.hide(this.mainContainer);
      // this.objDialogRef.disableClose  = false;
      // this.btnGuardar.disabled        = false;
      // this.btnCerrar.disabled         = false;
      // this.boolGuardar                = false;

    }, error => {

      // Wait.hide(this.mainContainer);
      // this.objDialogRef.disableClose  = false;
      // this.btnGuardar.disabled        = false;
      // this.btnCerrar.disabled         = false;
      // this.boolGuardar                = false;

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let lstPrivilegios : Array<any> = [];
    
    let arrPrivilegios = this.chkPrivilegio.toArray().filter(x => x.checked).map(x => x.value);
    arrPrivilegios.forEach(item => {
      lstPrivilegios.push(item);
    });

    let objUsuarioUpdate : any =
    {
      id          : this.objData.id,
      privilegios : lstPrivilegios
    };

    this.objUsuariosService.updatePrivilegio(objUsuarioUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
